import { DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditLoadComponent } from '../edit-load.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { GeometryService } from '../../../../../../../../../../services/geometry/geometry.service';
import { PhoneNumberFormat } from 'google-libphonenumber';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input-gg';
import { SharedService } from '../../../../../../../../../../services/sharedServices/shared.service';

@Component({
  selector: 'app-view-delivery-info',
  templateUrl: './view-delivery-info.component.html',
  styleUrls: ['./view-delivery-info.component.scss']
})
export class ViewDeliveryInfoComponent implements OnInit {
  @Input() selectedLoad: any;
  @Input() clickOnView: boolean = false;

  @Output() addDeliveryInMainForm = new EventEmitter<string>();

  selectedLoadDelivery!: any;

  dataSource: any;

  dIndex: any;

  form!: FormGroup;

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditLoadComponent,
    public dialogRef: MatDialogRef<EditLoadComponent>,
    public fb: FormBuilder,
    public sharedService: SharedService,
    public geometryService: GeometryService
  ) { }

  ngOnInit() {
    this.dataSource = this.data;
    this.selectedLoadDelivery = this.dataSource.value
    this.form = this.fb.group({
      delivery: this.fb.array(this.selectedLoadDelivery.map((data: any) => this.createDelivery(data)))
    })

    this.changeSelectedTab(0)
    // setTimeout(() => this.createAddress());
  }

  ngAfterViewInit(): void {
    if (this.clickOnView) {
      document.querySelectorAll('.event-tonu.mbsc-schedule-event.mbsc-ltr').forEach(el => {
        (el as HTMLElement).style.padding = 'padding: 24px 24px 0px 24px';
      });
    }
  }

  get delivery(): FormArray {
    return this.form.get('delivery') as FormArray;
  }

  createDelivery(deliveryData?: any): FormGroup {
    return this.fb.group({
      businessName: [deliveryData?.businessName || ''],
      fullAddress: [deliveryData?.fullAddress || '', Validators.required],
      street: [deliveryData?.street || ''],
      city: [deliveryData?.city || ''],
      stateCode: [deliveryData?.stateCode || ''],
      zipCode: [deliveryData?.zipCode || ''],
      countryCode: [deliveryData?.countryCode || ''],
      dateScheduledFrom: [deliveryData?.dateScheduledFrom ? new Date(deliveryData.dateScheduledFrom) : null],
      deliveryTime: [deliveryData ? this.getTimeFormDate(deliveryData.dateScheduledFrom) : ''],
      dateScheduledTo: [deliveryData ? this.getTimeFormDate(deliveryData.dateScheduledTo) : ''],
      fullName: [deliveryData?.fullName || ''],
      phone: [deliveryData?.phone || ''],
      notes: [deliveryData?.notes || ''],
      latitude: [deliveryData?.latitude || ''],
      longitude: [deliveryData?.longitude || ''],
      email: [deliveryData?.email || ''],
      checkIn: [deliveryData?.checkIn || ''],
      checkOut: [deliveryData?.checkOut || ''],
    });
  }

  addDelivery() {
    this.delivery.push(this.createDelivery());
  }

  removeDelivery(index: number) {
    this.delivery.removeAt(index);
    this.changeSelectedTab(index - 1,);
  }

  changeSelectedTab(index: any) {
    this.dIndex = index;
    setTimeout(() => {
      this.geometryService.findAddress(
        'google-search' + 'dv' + this.dIndex,
        this.delivery.controls[this.dIndex]
      );
    });
  }

  drop(event: CdkDragDrop<any[]>, type: string) {
    let controls: FormArray;
    let timeField: string;

    controls = this.delivery;
    timeField = 'deliveryTime';


    const prevIndex = controls.controls.findIndex((item) => item === event.item.data);
    const newIndex = event.currentIndex;

    const previousItem = controls.at(prevIndex).value;
    const currentItem = controls.at(newIndex).value;

    if (previousItem[timeField] > currentItem[timeField] && prevIndex < newIndex ||
      previousItem[timeField] < currentItem[timeField] && prevIndex > newIndex) {

      const copyPreviousItem = JSON.parse(JSON.stringify(previousItem));
      const copyCurrentItem = JSON.parse(JSON.stringify(currentItem));

      previousItem[timeField] = copyCurrentItem[timeField];
      previousItem.dateScheduledFrom = copyCurrentItem.dateScheduledFrom;
      previousItem.dateScheduledTo = copyCurrentItem.dateScheduledTo;

      currentItem[timeField] = copyPreviousItem[timeField];
      currentItem.dateScheduledFrom = copyPreviousItem.dateScheduledFrom;
      currentItem.dateScheduledTo = copyPreviousItem.dateScheduledTo;

      controls.setControl(prevIndex, this.fb.group(currentItem));
      controls.setControl(newIndex, this.fb.group(previousItem));
    } else {
      const movedItem = controls.at(prevIndex);
      controls.removeAt(prevIndex);
      controls.insert(newIndex, movedItem);
    }
  }

  getTimeFormDate(date: any) {
    const newDate = new Date(date);
    const hours = newDate.getHours().toString().padStart(2, '0');
    const minutes = newDate.getMinutes().toString().padStart(2, '0');
    const timeString = `${hours}:${minutes}`;
    return timeString;
  }

  cancel() {
    this.dialogRef.close()
  }

  confirm() {
    let updatedDelivery = this.form.get('delivery')?.value
    this.dialogRef.close(updatedDelivery)
  }

  openTimePicker(id: string) {
    let time = document.getElementById(id) as HTMLInputElement;
    time?.showPicker();
  }

}
