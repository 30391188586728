<div class="driver-note-popup-wrapper">
    <div class="popup-header-wrapper">
        <div class="popup-header-container">
            <div class="header-icon">
                <img src="../../../../.././../../assets/svg/info-img.svg" alt="info-img">
            </div>
            <div class="popup-header-title">
                <div class="header-title">{{data.driverFullName}}</div>
                <div class="header-descr">{{data.carrierName}}</div>
            </div>
        </div>
    </div>

    <textarea [value]="textAreaVal" (input)="onValueChange($event)"></textarea>

    <div class="popup-footer-btns">
        <div class="actions-btns">
            <button  class="global-btn btn-confirm" [ngClass]="{ 'disabled-btn': textAreaVal.length === 0}" [disabled]="textAreaVal.length === 0" (click)="okBtnClick()">
                <span class="btn-text">Confirm</span>
            </button>
        </div>
    </div>
</div>