import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetPhoneNumberAndDateInFormArrayService {

  constructor() { }

  private toUtcDate(dateString: string): Date {
    const localDate = new Date(dateString);

    return new Date(Date.UTC(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate(),
      localDate.getUTCHours(), localDate.getUTCMinutes(), localDate.getUTCSeconds()));
  }

  get(formArray: any) {
    let phoneString = '';

    formArray.controls.forEach((element: any) => {
      const phoneObj = element.get('phone')?.value;
      if (phoneObj) {
        phoneString = phoneObj.number;
      }

      const dateFrom = this.toUtcDate(element.get('dateScheduledFrom').value);
      const dateScheduledTo = element.get('dateScheduledTo').value;
      let dateTo = element.get('pickupTime') ? element.get('pickupTime').value : element.get('deliveryTime').value;

      if (dateTo && typeof dateTo === 'string' && !dateTo.includes('T')) {
        const [hours, minutes] = dateTo.split(':');
        const utcDateScheduledFrom = this.toUtcISOString(dateFrom, hours, minutes);
        element.patchValue({ dateScheduledFrom: utcDateScheduledFrom });
      }

      if (dateScheduledTo) {
        this.setDateScheduledTo(dateFrom, dateScheduledTo, element);
      }

      element.patchValue({ phone: phoneString });
    });

    return formArray.value;
  }

  private toUtcISOString(dateFrom: Date, hours: string, minutes: string): string {
    const year = dateFrom.getUTCFullYear();
    const month = (dateFrom.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = dateFrom.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:00Z`;
  }

  private setDateScheduledTo(dateFrom: Date, dateScheduledTo: string, element: any) {
    const [hours, minutes] = dateScheduledTo.split(':');
    const utcDateScheduledFrom = this.toUtcISOString(dateFrom, hours, minutes);
    element.patchValue({ dateScheduledTo: utcDateScheduledFrom });
  }
}


