<div class="time-line-container">
    <div class="nav-header">
        <app-time-line-header (settingsConfirmed)="receiveSettings($event)"></app-time-line-header>
    </div>
    <hr>
    <div class="filter">
        <app-time-line-filter (selectedTab)="selectedTab($event)" (refreshResource)="refreshResource()">
        </app-time-line-filter>
    </div>
    <div class="calendar">
        <app-time-line-calendar [settingsFormHeader]="settingsFormHeader"></app-time-line-calendar>
    </div>
</div>