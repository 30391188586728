import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss',
})
export class DialogComponent implements OnInit{
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public iconPath: string = '';
  public dsa: boolean = false;

  ngOnInit(): void {
    this.iconPath = 'assets/svg/' + this.data.props.icon + '.svg';
  }

  onButton1Click(): void {
    this.dialogRef.close({ result: 'button1' });
  }

  onButton2Click(): void {
    this.dialogRef.close({ result: 'button2'});
  }

  dsaChange(event: any){
    localStorage.setItem('dsa', JSON.stringify(this.dsa));
  }
}