import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";

@Injectable()
export abstract class PopUpSubscriptBase implements OnDestroy {

    set subs(subscription: Subscription) {
      this.subscriptioions.add(subscription);
    }
  
    protected enabledCloseResponse: boolean = true;
    protected popupData: any = {};
    public popupTitle: string = "";
    public popupVisible: boolean = false;
    private subscriptioions: Subscription = new Subscription;
    private refreshDataObserve: Observable<any>;
    protected _result : Subject<any>;
  
    constructor(
      public result: Subject<any>, 
    ) {
        this.refreshDataObserve = result.asObservable();
        this._result = result;
    };
  
    protected abstract showAPopUp(data: any): void;

    subscribe(data: any, successFn: (value: any) => void, errorHandler?: (value: any) => void) {
        this.subscriptioions = new Subscription();
        this.subs = this.refreshDataObserve.subscribe(successFn, errorHandler || this.errorHandler);
        this.popupTitle = data.title;
        this.popupData = data;
        this.showAPopUp(data);
    }
  
    ngOnDestroy() {
      if (!this.subscriptioions) return;
      this.subscriptioions.unsubscribe();
    }
  
    private errorHandler(error: any) {
      // TODO: generic error handling
    }
  }