import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AUTH_API_URL } from '../../../../services/app-injection-tokens';

@Injectable({
  providedIn: 'root'
})

export class UsersPageService {

  constructor(
    private http: HttpClient,
    @Inject(AUTH_API_URL) private apiUrl: string
  ) { }

  getUsers(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/api/users/all');
  }

  getSupervisors(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/api/users/supervisors/all');
  }

  getUserByID(userID: number): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/api/users/' + userID);
  }
  
  inviteUser(data: Array<any>): Observable<any> {
    const payload = data.map(m=> ({
      supervisorId :m.supervisorId === null ? m.supervisorId = 0 : m.supervisorId,
      roles: m.roles.toUpperCase(),
      email: m.email
    }));
    return this.http.post(this.apiUrl+'/api/users/invite', payload);
  }
  
  addUser(data: any): Observable<any> {
    const payload = {
      id: 0,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      email: data.email,
      phone: data.phone.number.trim(),
      roles: data.roles,
      language: "en",
      supervisorId: data.supervisorId || 0,
    };

    return this.http.post(this.apiUrl+'/api/users/add', payload);
  }
  
  editUser(data: any, userID: number): Observable<any> {
    const payload = {
      id: userID,
      firstName: data.firstName || "",
      lastName: data.lastName || "",
      fullName: data.fullName || "",
      password: data.password,
      email: data.email,
      phone: data.phone.number.trim(),
      roles: data.roles,
      language: "en",
      supervisorId: data.supervisorId || 0,
      files: data.files
    };

    return this.http.put(this.apiUrl+'/api/users/'+userID, payload);
  }
  
  deleteUser(userID: number): Observable<any> {
    return this.http.delete(this.apiUrl+'/api/users/'+ userID);
  }

}