import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'activeDrivers'
})
export class ActiveDriversPipe implements PipeTransform {

  transform(drivers: any[]): any [] {
    return drivers.filter(driver => driver.Status);
  }

}
