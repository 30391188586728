import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetCarrierIdByNameService {

  constructor() { }
  getId(carriers: any, carrierName: string) {
    let carrierId = '';
    carriers.forEach((value: any) => {
      if (value.name === carrierName) {
        carrierId = value.id;
      }
    });
    return carrierId;
  }

  getName(carriers: any, carrierId: string) {
    let carrierName = '';
    carriers.forEach((value: any) => {
      if (value.id === carrierId) {
        carrierName = value.name;
      }
    });
    return carrierName;
  }

}
