import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetDispatcherIdByNameService {

  constructor() { }
  get(dispathers: any, dispatcherName: string) {
    let dispatcherId = '';
    dispathers.forEach((value: any) => {
      if (value.fullName === dispatcherName) {
        dispatcherId = value.id;
      }
    });
    return dispatcherId;
  }
}
