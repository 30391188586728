import { Pipe, PipeTransform } from '@angular/core';
import { Resource } from '../../modules/main/pages/timeline/types/resource';

@Pipe({
  name: 'sortDriversAlphabeticallyPipe'
})
export class SortDriversAlphabeticallyPipe implements PipeTransform {

  transform(drivers: Resource[]): Resource[] {
    if (!drivers) throw Error('Drivers is not an array')
    if (!drivers.length) return drivers

    drivers.sort((a, b) => {
      const nameA = a.driverFullName.toLowerCase()
      const nameB = b.driverFullName.toLowerCase()

      if (nameA < nameB) return -1
      if (nameA > nameB) return 1

      return 0;
    });

    drivers.sort((a, b) => {
      if (!a.status && b.status) return 1
      if (a.status && !b.status) return -1

      return 0
    })

    return drivers;
  }

}
