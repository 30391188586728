import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TimeLineCalendarComponent } from '../../time-line-calendar/time-line-calendar.component';
import { DialogRef } from '@angular/cdk/dialog';
import { CreateModalLoadComponent } from './create-modal-load/create-modal-load.component';
import { CreateModalNoteComponent } from './create-modal-note/create-modal-note.component';
import { CreateModalTonuComponent } from './create-modal-tonu/create-modal-tonu.component';
import { TimeLineFilterComponent } from '../../time-line-filter/time-line-filter.component';
import { LoadsService } from '../../../../../../services/loads/loads.service';

@Component({
  selector: 'app-create-modal-all',
  templateUrl: './create-modal-all.component.html',
  styleUrls: ['./create-modal-all.component.css'],
})
export class CreateModalAllComponent implements OnInit {
  @ViewChild(CreateModalLoadComponent)
  createLoadComponent!: CreateModalLoadComponent;
  @ViewChild(CreateModalTonuComponent)
  createTonuComponent!: CreateModalTonuComponent;
  @ViewChild(CreateModalNoteComponent)
  createNoteComponent!: CreateModalNoteComponent;
  index: any;
  result: any;
  carriers: any;
  drivers: any;
  dispathers: any;
  loadInfo: any;
  start: any;
  end: any;
  selectedTab: any;
  partial: any;

  nameTab: string = '';

  public wiatingForResponce: boolean = false;
  public isNoteFormValid: boolean = false;
  public isLoadFormValid: boolean = false;
  public isTonuFormValid: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TimeLineCalendarComponent,
    @Inject(MAT_DIALOG_DATA) public tab: TimeLineFilterComponent,
    public dialogRef: DialogRef<string>,
    public loadService: LoadsService,
  ) { }

  ngOnInit() {
    this.selectedTab = this.tab;
    this.result = this.data;
    this.carriers = this.result.carriers;
    this.drivers = this.result.drivers;
    this.dispathers = this.result.dispatcers;
    this.loadInfo = this.result.load;
    this.partial = this.result.partial;
    this.changeTab(this.selectedTab.index);
    if (this.result.load && this.result.load?.loadsPage) {
      if (!!this.result.load.startDate && this.result.load.endDate) {
        this.start = new Date(this.result.load.startDate);
        this.end = new Date(this.result.load.endDate);
      }
    } else {
      if (this.result.startDate && this.result.endDate) {
        this.start = new Date(this.result.startDate);
        this.end = new Date(this.result.endDate);
      } else {
        this.start = null; //when create load from btn clear date and time
        this.end = null; //when create load from btn clear date and time
      }
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  isFormValidNote(event: any) {
    this.isNoteFormValid = event;
  }

  isFormValidLoad(event: any) {
    this.isLoadFormValid = event;
  }

  isFormValidTonu(event: any) {
    this.isTonuFormValid = event;
  }

  changeTab(event: any) {
    if (event.index != null) {
      this.index = event.index;
    } else {
      this.index = this.selectedTab.index;
    }
    this.changeNameOfButton(this.index);
  }

  async saveValue(index: number) {
    this.wiatingForResponce = true;
    try {
      switch (index) {
        case 0:
          await this.createLoadComponent.saveLoad();
          break;
        case 1:
          await this.createTonuComponent.saveTonu();
          break;
        case 2:
          await this.saveNote();
          break;

        default:
          console.log('No tab selected');
      }

    } catch (err) {
      console.error(err);

    } finally {
      this.wiatingForResponce = false;
    }

  }

  saveNote(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (resolve) {
        this.loadService.createNote(this.createNoteComponent.form.value).subscribe(() => {
          this.closeModal();

        });
        resolve();
      } else {
        reject(new Error('Ошибка сохранения'));
      }
    });
  }


  changeNameOfButton(index: number) {
    switch (index) {
      case 0:
        this.nameTab = 'Load';
        break;
      case 1:
        this.nameTab = 'Tonu';
        break;
      case 2:
        this.nameTab = 'Note';
        break;
      default:
        console.log('No tab selected');
    }
  }

  disableMethod(): boolean {
    return ((!this.isNoteFormValid && this.nameTab == 'Note') ||
      (!this.isLoadFormValid && this.nameTab == 'Load') ||
      (!this.isTonuFormValid && this.nameTab == 'Tonu')) ||
      this.wiatingForResponce
  }
}
