import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  signal,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  CountryISO,
  SearchCountryField,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input-gg';
import { DialogRef } from '@angular/cdk/dialog';
import { Load } from '../../../types/load';
import { File } from '../../../types/file';
import { LoadsService } from '../../../../../../../services/loads/loads.service';
import { GeometryService } from '../../../../../../../services/geometry/geometry.service';
import { Status } from '../../../../../../../enum/status.enum';
import { GetDriverNameByIdService } from '../../../../../../../services/serviceForTransformFields/getDriverNameById.service';
import { GetCarrierIdByNameService } from '../../../../../../../services/serviceForTransformFields/getCarrierIdByName.service';
import { GetDispatcherIdByNameService } from '../../../../../../../services/serviceForTransformFields/getDispatcherIdByName.service';
import { GetPhoneNumberAndDateInFormArrayService } from '../../../../../../../services/serviceForTransformFields/getPhoneNumberAndDateInFormArray.service';
import * as L from 'leaflet';
import { convert } from 'geo-coordinates-parser';
import { EmptyMilesRequest } from '../../../../../../../interfaces/loads/emptyMilesRequest';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BrokerService } from '../../../../../../../services/brokers/broker.service';
import { Broker } from '../../../../../../../interfaces/loads/broker';
import { map, Observable, startWith, Subscription } from 'rxjs';
import { SharedService } from '../../../../../../../services/sharedServices/shared.service';
import { ActionsFee } from '../../../../../../../enum/actionsFee';
import { MatSelect } from '@angular/material/select';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { EditLoadComponent } from '../../edit/view-edit-load/load/edit-load/edit-load.component';
import { NotificationService } from '../../../../../../../services/notification.service';

@Component({
  selector: 'app-create-modal-load',
  templateUrl: './create-modal-load.component.html',
  styleUrls: ['./create-modal-load.component.scss'],
})
export class CreateModalLoadComponent implements OnInit, OnDestroy {
  @Input() start!: any;
  @Input() end!: any;
  @Input() dispathers: any;
  @Input() carriers: any[] = [];
  @Input() drivers: any[] = [];
  @Input() loadInfo: any;
  @Input() partial: any;

  @Output() save = new EventEmitter<void>();
  @Output() addNewItem = new EventEmitter<string>();

  @Output() isFormValid = new EventEmitter<boolean>();

  @ViewChildren("formItem", { emitDistinctChangesOnly: true }) formItem!: QueryList<MatSelect | ElementRef | MatAutocompleteTrigger>;
  @ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger;

  // @ViewChild('selectElement', { static: false })
  // selectElement!: ElementRef<HTMLInputElement>;
  // @ViewChild('dropdownContainer') dropdownContainer!: ElementRef;

  readonly emptyMilesCoors = signal('');

  status: any[] = [];

  loadEdit: (keyof Load)[] = [];
  longitudeAndLatitude: string = '';
  form!: FormGroup;
  pIndex: any;
  dIndex: any;
  miles: any;
  phone: any;
  visibleIndex: number | null = null;
  openMap: boolean = false;
  map: any;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  emptyMilesRequest = {} as EmptyMilesRequest;
  setTime: any;
  openType: boolean = false;
  load!: Load;
  loadForCreate!: Load;
  brokers!: Broker[];
  fileLabels: any;
  files: File[] = [];
  result: any;
  setCoordinatesPickUp: boolean = false;
  setCoordinatesDelivery: boolean = false;
  toWhomAddCoordinates: string = '';
  selectedLoad: any;
  milesInputDisable: boolean = false;
  openLocationModal: boolean = false;
  filteredBroker: Observable<Broker[]> | undefined;
  typeOfEmptyMiles: string = '';
  filteredDrivers: any;

  actionsFee: any;
  actionFeeKeys: any;
  feeType: any[] = [];

  emptyMilesOptions = ['Last Delivery', 'Location', 'Manually', 'Driver Home'];
  dropdownOpen: boolean = false;
  addressSelectedFlags: boolean[] = [];

  itemsSubscription: Subscription = new Subscription();
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditLoadComponent>,
    private fb: FormBuilder,
    private loadService: LoadsService,
    private brokerService: BrokerService,
    private geometryService: GeometryService,
    private getDriverNameById: GetDriverNameByIdService,
    private getCarrierIdByName: GetCarrierIdByNameService,
    private getDispatcherIdByName: GetDispatcherIdByNameService,
    private getPhoneNumberAndDateInFormArray: GetPhoneNumberAndDateInFormArrayService,
    private notificationService: NotificationService,
  ) { }

  async ngOnInit() {
    this.actionsFee = ActionsFee;
    this.actionFeeKeys = Object.keys(ActionsFee);
    this.filteredDrivers = this.drivers;
    this.initForm();
    if (this.partial) {
      this.form.get('parentId')?.setValue(this.loadInfo.loadId);
      this.form.get('carrier')?.disable();
      this.form.get('driver')?.disable();
      this.form.get('dispatcher')?.disable();

    }
    this.form.markAllAsTouched();
    this.getReference();
    this.changeSelectedTab(0, 'pickup');
    this.changeSelectedTab(0, 'delivery');
    this.removeFee(0);
    setTimeout(() => this.createAddress());
    await this.getAllBrokers();

    this.filteredBroker = this.form.get('company')?.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.businessName;
        return name ? this._filterBroker(name as string) : this.brokers.slice();
      })
    );
    this.sortArrByDateSheduledAndGetLatLong();

    if (!this.partial) {
      this.itemsSubscription = this.geometryService.emptyMilesItem
        .subscribe((item) => {
          if (item) {
            setTimeout(() => {
              this.selectLastDelivery('lastDelivery');
            });
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.form.reset();
    this.itemsSubscription.unsubscribe()
  }

  displayFn(broker: Broker): string {
    return broker && broker.businessName ? broker.businessName : '';
  }

  private _filterBroker(name: string): Broker[] {
    if (!name) {
      return this.brokers;
    }
    const filterValue = name.toLowerCase();
    return this.brokers.filter((broker) => {
      const businessName = broker.businessName || '';
      return businessName.toLowerCase().includes(filterValue);
    });
  }

  initForm() {
    let selectedDriver = this.drivers.find(
      (driver) => driver.id === this.loadInfo?.driverId
    );

    this.form = this.fb.group({
      loadId: new FormControl('', Validators.required),
      parentId: new FormControl(''),
      carrier: new FormControl(
        selectedDriver?.carrierId ? selectedDriver?.carrierId : '',
        Validators.required
      ),
      driver: new FormControl(
        selectedDriver?.id ? selectedDriver?.id : '',
        Validators.required
      ),
      driverId: new FormControl(
        selectedDriver?.id ? selectedDriver?.id : '',
        Validators.required
      ),
      dispatcher: new FormControl(
        selectedDriver?.dispatcherFullName
          ? selectedDriver?.dispatcherFullName
          : '',
        Validators.required
      ),
      phone: new FormControl(''),
      company: new FormControl(''),
      brokerId: new FormControl(''),
      fullAddress: new FormControl(''),
      street: new FormControl(''),
      city: new FormControl(''),
      status: new FormControl('NEW'),
      stateCode: new FormControl(''),
      zipCode: new FormControl(''),
      countryCode: new FormControl(''),
      name: new FormControl(''),
      miles: new FormControl(''),
      loadMiles: new FormControl(''),
      longitude: new FormControl(''),
      latitude: new FormControl(),
      price: new FormControl('', Validators.required),
      email: new FormControl(''),
      pickups: this.fb.array([this.createPickup()]),
      deliverys: this.fb.array([this.createDelivery()]),
      fee: this.fb.array([this.createFee()]),
    });
    // when create from loads-page
    if (this.loadInfo?.loadsPage) {
      if (this.loadInfo.carrierId) this.form.get('carrier')?.setValue(this.loadInfo.carrierId);
      if (this.loadInfo.dispatcher) this.form.get('dispatcher')?.setValue(this.loadInfo.dispatcher);
      if (this.loadInfo.driverId) this.form.get('driver')?.setValue(this.loadInfo.driverId);
      if (this.loadInfo.status) this.form.get('status')?.setValue(this.loadInfo.status);
      if (this.loadInfo.broker) {
        if (this.brokers && this.brokers.length > 0) {
          this.addInformationAboutBrokerByName({ id: this.loadInfo.broker });
        } else {
          setTimeout(() => {
            this.addInformationAboutBrokerByName({ id: this.loadInfo.broker });
          }, 400);
        }
      }
    }
    this.form.valueChanges.subscribe((change) => {
      this.isFormValid.emit(this.form.valid);
    });
  }

  getReference() {
    this.loadService.getReference().subscribe((value) => {
      value.forEach((el: any) => {
        if (el.type === 'FeeType') {
          this.feeType.push(el);
        } else if (el.type === 'LoadStatusLoad') {
          this.status.push(el)
        }
      });
    });
  }

  get fee(): FormArray {
    return this.form.get('fee') as FormArray;
  }

  get pickups(): FormArray {
    return this.form.get('pickups') as FormArray;
  }

  get deliverys(): FormArray {
    return this.form.get('deliverys') as FormArray;
  }

  clearInput(evt: any): void {
    evt.stopPropagation();
    this.form.get('company')?.setValue('');
  }

  createPickup(): FormGroup {
    this.changeSelectedTab(this.pIndex + 1, 'pickup')

    const hours = this.start ? this.start.getHours().toString().padStart(2, '0') : null;
    const minutes = this.start ? this.start.getMinutes().toString().padStart(2, '0') : null;
    const time = (hours && minutes) ? `${hours}:${minutes}` : null;

    return this.fb.group({
      businessName: [''],
      fullAddress: ['', Validators.required],
      street: [''],
      city: [''],
      stateCode: [''],
      zipCode: [''],
      countryCode: [''],
      dateScheduledFrom: [this.start ? this.start : null, Validators.required],
      pickupTime: [time, Validators.required],
      dateScheduledTo: [''],
      fullName: [],
      infoOrder: [],
      phone: [''],
      notes: [''],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      email: [''],
    });
  }

  createDelivery(index?: number): FormGroup {
    this.changeSelectedTab(this.dIndex + 1, 'delivery')

    const hours = this.end ? this.end.getHours().toString().padStart(2, '0') : null;
    const minutes = this.end ? this.end.getMinutes().toString().padStart(2, '0') : null;
    const time = (hours && minutes) ? `${hours}:${minutes}` : null;

    return this.fb.group({
      businessName: [''],
      fullAddress: ['', Validators.required],
      street: [''],
      city: [''],
      stateCode: [''],
      zipCode: [''],
      countryCode: [''],
      dateScheduledFrom: [this.end ? this.end : null, Validators.required],
      deliveryTime: [time, Validators.required],
      dateScheduledTo: [''],
      fullName: [''],
      infoOrder: [],
      phone: [''],
      notes: [''],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      email: [''],
    });
  }

  createFee(): FormGroup {
    return this.fb.group({
      id: [0],
      feeName: [''],
      feePrice: [''],
      addToGross: [''],
    });
  }

  addPickup() {
    this.pickups.push(this.createPickup());
  }

  addDelivery(infoOrder: number) {
    this.deliverys.push(this.createDelivery(infoOrder));
  }

  addFee() {
    this.openType = true;
    this.fee.push(this.createFee());
  }

  removePickup(index: number) {
    this.pickups.removeAt(index);
    this.changeSelectedTab(index - 1, 'pickup');
  }
  removeDelivery(index: number) {
    this.deliverys.removeAt(index);
    this.changeSelectedTab(index - 1, 'delivery');
  }
  removeFee(index: number) {
    this.fee.removeAt(index);
  }

  handleFileDelete(idx: number) {
    this.files.splice(idx, 1);
  }

  changeSelectedTab(index: any, key: string) {
    if (key === 'pickup') {
      this.pIndex = index;
      this.addressSelectedFlags[this.pIndex] = false;

      setTimeout(() => {
        this.geometryService.findAddress(
          'google-search' + 'p' + this.pIndex,
          this.pickups.controls[this.pIndex]
        ).then(() => {
          this.addressSelectedFlags[this.pIndex] = true;
        })
      });
    } else {
      this.dIndex = index;
      this.addressSelectedFlags[this.dIndex] = false;

      setTimeout(() => {
        this.geometryService.findAddress(
          'google-search' + 'd' + this.dIndex,
          this.deliverys.controls[this.dIndex]
        ).then(() => {
          this.addressSelectedFlags[this.dIndex] = true;
        })
      });
    }
  }

  selectOptionInFee(event: any) {
    if (event === 'Plus to price') {
      this.fee.get('addToGross')?.setValue("PLUS_TO_PRICE");
    } else {
      this.fee.get('addToGross')?.setValue(false);
    }
    this.form.updateValueAndValidity();
  }

  selectOption(selectedEmptyMilesOption: any) {
    let selectedOption = this.changedSelectedEmptyMilesOptionToCamelCase(
      selectedEmptyMilesOption
    );
    this.dropdownOpen = false;
    switch (selectedOption) {
      case 'lastDelivery':
        this.selectLastDelivery(selectedOption);
        break;
      case 'location':
        this.openDialog(selectedOption);
        break;
      case 'manually':
        this.setManualyEmptyMIles(selectedOption);
        break;
      case 'driverHome':
        this.calcEmptyMilesByDriversHome(selectedOption);
        break;
    }
  }

  changedSelectedEmptyMilesOptionToCamelCase(selectedEmptyMilesOption: string) {
    return selectedEmptyMilesOption
      .split(' ')
      .reduce(
        (acc, word, index) =>
          acc +
          (index === 0
            ? word.toLowerCase()
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()),
        ''
      );
  }

  async addInformationAboutBrokerByName(broker: any) {
    for (let i = 0; i < this.brokers.length; i++) {
      if (this.brokers[i].id === broker.id) {
        this.form.get('company')?.setValue(this.brokers[i].businessName);
        this.form.get('brokerId')?.setValue(this.brokers[i].id);
        this.form.get('email')?.setValue(this.brokers[i].email);
        this.form.get('name')?.setValue(this.brokers[i].fullName);
        this.form.get('phone')?.setValue(this.brokers[i].phone);
        this.form
          .get('fullAddress')
          ?.setValue(this.makeFullAddress(this.brokers[i]));
        break;
      } else this.clearBroker();
    }
  }

  clearBroker() {
    this.form.get('company')?.setValue(null);
    this.form.get('brokerId')?.setValue(null);
    this.form.get('email')?.setValue(null);
    this.form.get('name')?.setValue(null);
    this.form.get('phone')?.setValue(null);
    this.form.get('fullAddress')?.setValue(null);
  }

  makeFullAddress(broker: Broker) {
    let fullAddress = broker.street
      ? broker.street
      : '' + ',' + broker.city
        ? broker.city
        : '' + ',' + broker.stateCode
          ? broker.stateCode
          : '' + ',' + broker.zipCode
            ? broker.zipCode
            : '' + ',' + broker.countryCode
              ? broker.countryCode
              : '';
    return fullAddress;
  }

  getDriverByCarrierId(carrierId: any) {
    this.filteredDrivers = this.drivers.filter(
      (driver) => driver.carrierId === carrierId
    );
  }

  async openDialog(type: string) {
    this.typeOfEmptyMiles = type;
    this.openLocationModal = true;
    this.milesInputDisable = false;
    this.emptyMilesRequest.type = type;
  }

  async calcEmptyMilesByDriversHome(type: string) {
    this.typeOfEmptyMiles = type;
    this.milesInputDisable = false;
    let pickup = this.pickups.controls;
    this.emptyMilesRequest.type = type;
    this.emptyMilesRequest.firstPickupLatitude =
      pickup[0].get('latitude')?.value;
    this.emptyMilesRequest.firstPickupLongitude =
      pickup[0].get('longitude')?.value;
    this.emptyMilesRequest.driverId = this.form.get('driverId')?.value;
    this.emptyMilesRequest.firstPickupDatetime = this.setDateScheduledFrom(this.pickups);
    let emptyMilesDriversHome = await this.loadService.setAndGetEmptyMiles(
      this.emptyMilesRequest
    );
    this.form.get('miles')?.setValue(this.convertNumber(emptyMilesDriversHome.distance));
    this.form.get('longitude')?.setValue(emptyMilesDriversHome.longitude);
    this.form.get('latitude')?.setValue(emptyMilesDriversHome.latitude);
  }

  async selectLastDelivery(type: string) {
    const pickUpDate = this.pickups.controls[0].value.dateScheduledFrom || ''
    this.typeOfEmptyMiles = type;
    this.milesInputDisable = false;
    let pickup = this.pickups.controls;
    this.emptyMilesRequest.type = type;
    this.emptyMilesRequest.firstPickupLatitude =
      pickup[0].get('latitude')?.value;
    this.emptyMilesRequest.firstPickupLongitude =
      pickup[0].get('longitude')?.value;
    this.emptyMilesRequest.driverId = this.form.get('driverId')?.value;
    this.emptyMilesRequest.firstPickupDatetime = this.setDateScheduledFrom(this.pickups);
    let emptyMilesLastDelivery = await this.loadService.setAndGetEmptyMiles(
      this.emptyMilesRequest
    );

    const startDate: any = new Date(emptyMilesLastDelivery.lastDeliveryDate);
    const endDate: any = new Date(pickUpDate);
    const differenceInMilliseconds = endDate - startDate;
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
    if (differenceInDays >= 7) {
      this.notificationService.warning('Warning', 'Last delivery of the current driver was more then 7 days ago. Deadhead data may be inaccurate. Please choose another option of deadhead calculation or proceed with this one.', 5000)
    }

    this.form
      .get('miles')
      ?.setValue(this.convertNumber(emptyMilesLastDelivery.distance));
    this.form.get('longitude')?.setValue(emptyMilesLastDelivery.longitude);
    this.form.get('latitude')?.setValue(emptyMilesLastDelivery.latitude);
  }

  async getEmitCoordsFromMap(event: any) {
    let [latitude, longitude] = event.split(',');
    let pickup = this.pickups.controls;
    this.emptyMilesRequest.firstPickupLatitude =
      pickup[0].get('latitude')?.value;
    this.emptyMilesRequest.firstPickupLongitude =
      pickup[0].get('longitude')?.value;
    this.emptyMilesRequest.targetLatitude = Number(latitude);
    this.emptyMilesRequest.targetLongitude = Number(longitude);
    this.emptyMilesRequest.firstPickupDatetime = this.setDateScheduledFrom(this.pickups);
    this.openLocationModal = false;
    let emptyMilesLastDelivery = await this.loadService.setAndGetEmptyMiles(
      this.emptyMilesRequest
    );
    this.form
      .get('miles')
      ?.setValue(this.convertNumber(emptyMilesLastDelivery.distance));
    this.form.get('longitude')?.setValue(emptyMilesLastDelivery.longitude);
    this.form.get('latitude')?.setValue(emptyMilesLastDelivery.latitude);
  }

  setDateScheduledFrom(formArray: any) {
    const firstControl = formArray.at(0) as FormGroup;
    const dateScheduledFrom = firstControl.get('dateScheduledFrom')?.value;
    return dateScheduledFrom;
  }

  closeModalWithCoors(event: any) {
    this.openLocationModal = event.value;
  }

  setManualyEmptyMIles(type: string) {
    this.typeOfEmptyMiles = type;
    this.milesInputDisable = true;
  }
  openDropDownEmptyMiles() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  setSelectedDriverIdInForm(selectedDriverId: number) {
    this.form.get('driverId')?.setValue(selectedDriverId);
  }

  drop(event: CdkDragDrop<any[]>, type: string) {
    let controls: FormArray;
    let timeField: string;

    if (type === 'pickups') {
      controls = this.pickups;
      timeField = 'pickupTime';
    } else if (type === 'delivery') {
      controls = this.deliverys;
      timeField = 'deliveryTime';
    } else {
      return;
    }

    const prevIndex = controls.controls.findIndex(
      (item) => item === event.item.data
    );
    const newIndex = event.currentIndex;

    const previousItem = controls.at(prevIndex).value;
    const currentItem = controls.at(newIndex).value;

    if (
      (previousItem[timeField] > currentItem[timeField] &&
        prevIndex < newIndex) ||
      (previousItem[timeField] < currentItem[timeField] && prevIndex > newIndex)
    ) {
      const copyPreviousItem = JSON.parse(JSON.stringify(previousItem));
      const copyCurrentItem = JSON.parse(JSON.stringify(currentItem));

      previousItem[timeField] = copyCurrentItem[timeField];
      previousItem.dateScheduledFrom = copyCurrentItem.dateScheduledFrom;
      previousItem.dateScheduledTo = copyCurrentItem.dateScheduledTo;

      currentItem[timeField] = copyPreviousItem[timeField];
      currentItem.dateScheduledFrom = copyPreviousItem.dateScheduledFrom;
      currentItem.dateScheduledTo = copyPreviousItem.dateScheduledTo;

      controls.setControl(prevIndex, this.fb.group(currentItem));
      controls.setControl(newIndex, this.fb.group(previousItem));
    } else {
      const movedItem = controls.at(prevIndex);
      controls.removeAt(prevIndex);
      controls.insert(newIndex, movedItem);
    }

    controls.controls.forEach((control, index) => {
      control.patchValue({ infoOrder: index });
    });

    this.form.updateValueAndValidity();
  }

  moveItem(list: any[], prevIndex: number, newIndex: number) {
    const [movedItem] = list.splice(prevIndex, 1);
    list.splice(newIndex, 0, movedItem);
  }

  trackByFn(index: number, item: any): number {
    return index;
  }

  aditionalCheckLoadBroker() {
    // @ts-ignore
    // return Object.keys(this.load.broker).some((key: keyof typeof Broker) => this.load.broker[key]);
    const broker = this.load.broker;

    if (
      (this.form.get('company')?.value === '' ||
        this.form.get('company')?.value === null) &&
      (this.form.get('brokerId')?.value === '' ||
        this.form.get('brokerId')?.value === null) &&
      (this.form.get('email')?.value === '' ||
        this.form.get('email')?.value === null) &&
      (this.form.get('name')?.value === '' ||
        this.form.get('name')?.value === null) &&
      (this.form.get('phone')?.value === '' ||
        this.form.get('phone')?.value === null) &&
      (this.form.get('fullAddress')?.value === '' ||
        this.form.get('fullAddress')?.value === null)
    ) {
      this.load.broker = null;
    }
  }

  brokerInput(event: any) {
    const val = event.target.value;

    if (!Array.isArray(this.brokers)) {
      return;
    }

    let obj = this.brokers.filter(
      (f) => f.businessName && f.businessName.includes(val)
    );

    if (obj.length === 0) {
      this.form.get('brokerId')?.setValue(0);
      this.form.get('email')?.setValue(null);
      this.form.get('name')?.setValue(null);
      this.form.get('phone')?.setValue(null);
      this.form.get('fullAddress')?.setValue(null);
    }
  }

  onBlurMethodPickup() {
    // setTimeout(() => {
    //   const pickups = this.pickups.value;
    //   for (let i = 0; i < pickups.length; i++) {
    //     if (!this.addressSelectedFlags[i]) {
    //       this.notificationService.warning(
    //         "Warning",
    //         "Select an address from the drop-down list or paste the coordinates directly for pickup location"
    //       );
    //       break;
    //     }
    //   }
    // }, 500);
  }

  onBlurMethodDelivery() {
    // setTimeout(() => {
    //   const deliverys = this.deliverys.value;
    //   for (let i = 0; i < deliverys.length; i++) {
    //     const index = this.pickups.controls.length + i;
    //     if (!this.addressSelectedFlags[index]) {
    //       this.notificationService.warning(
    //         "Warning",
    //         "Select an address from the drop-down list or paste the coordinates directly for delivery location"
    //       );
    //       break;
    //     }
    //   }
    // }, 500);
  }

  saveLoad(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (resolve) {
        this.load = this.formToLoad();
        try {
          if (!this.partial) {
            this.aditionalCheckLoadBroker();
            this.loadService.createLoad(this.load).subscribe(() => {
              this.dialogRef.close();
              this.save.emit();
              resolve();

            });
          } else {
            this.aditionalCheckLoadBroker();
            this.loadService
              .createPartialLoad(this.load, this.form.get('parentId')?.value)
              .subscribe((response) => {
                this.dialogRef.close(response);
                this.save.emit();
                resolve();
              });
          }
        } catch (err) {
          console.error(err);
        }
      } else {
        reject(new Error('Ошибка сохранения'));
      }
    });

  }

  handleFileChange(files: Array<File>) {
    this.files = files;
  }

  createAddress() {
    this.pickups.controls.forEach((item: any, i: number) => {
      this.addressSelectedFlags[i] = false;
      this.geometryService.findAddress('google-search' + 'p' + i, item).then(() => {
        this.addressSelectedFlags[i] = true;
      });
    });

    this.deliverys.controls.forEach((item: any, i: number) => {
      const index = this.pickups.controls.length + i;
      this.addressSelectedFlags[index] = false;
      this.geometryService.findAddress('google-search' + 'd' + i, item).then(() => {
        this.addressSelectedFlags[index] = true;
      });
    });

    this.geometryService.findAddress('google-search' + 'b', this.form);
  }

  focusNext(fieldName: string, optionalIDX: number = 0) {
    // check if fee is visible, if no skip
    if (fieldName === 'feeName' && !this.openType) fieldName = 'businessName';

    // Find the next field after a small delay to ensure MatSelect closes
    const nextField = this.formItem.toArray().filter(input => {
      if (input instanceof MatSelect) {
        input.close();
        return input._elementRef.nativeElement.getAttribute('formControlName') === fieldName;
      }
      if (input instanceof ElementRef) {
        return input.nativeElement.getAttribute('formControlName') === fieldName;
      }
      return false; // Return false if neither MatSelect nor ElementRef
    });

    // Ensure optionalIDX is within bounds
    if (nextField.length) {
      const targetField = nextField[optionalIDX];
      if (targetField instanceof MatSelect) {
        // targetField.close();
        // targetField._elementRef.nativeElement.addEventListener('blur', () => targetField.close() );
        targetField._elementRef.nativeElement.addEventListener('focus', () => targetField.open());
        targetField.focus();
      } else if (targetField instanceof ElementRef) {
        targetField.nativeElement.focus();
      }
    }
  }

  inputFocus(input: any) {
    setTimeout(() => {
      input.close();
    }, 100);
  }

  getAddressValue(item: any) {
    return item.get('street').value
      ? `${item.get('street').value}, ${item.get('city').value}, ${item.get('stateCode').value
      } ${item.get('zipCode').value}, ${item.get('countryCode').value}`
      : '';
  }

  private async getLocations() {
    const pickups = this.form.get('pickups')?.value || [];
    const delivery = this.form.get('delivery')?.value || [];
    for (const el of [...pickups, ...delivery]) {
      if (!el.countryCode) {
        const regex = /^(.+),\s*(.+),\s*([A-Z]{2})\s*([\d-]+)?,\s*(.+)$/;
        const match = el.fullAddress?.match(regex);
        if (match) {
          const streetAddress = match[1];
          const city = match[2];
          const state = match[3];
          const zipCode = match[4];
          const country = match[5];

          el.street = streetAddress;
          el.city = city;
          el.zipCode = zipCode;
          el.stateCode = state;
          el.countryCode = country === 'США' ? 'USA' : country;
        }

        // try {
        //   const res: any = await firstValueFrom(this.geometryService.getPosition(el));
        //   el.latitude = res.bbox[1]
        //   el.longitude = res.bbox[0]
        // } catch (error) {
        //   console.log(error);
        // }
      }
    }
  }

  async getAllBrokers() {
    let response = await this.brokerService.getAllBrokers();
    this.brokers = response;
  }

  formToLoad() {
    let deliveryLength = this.form.get('deliverys')?.value;
    let driverName = this.getDriverNameById.getName(
      this.drivers,
      this.form.get('driver')?.value
    );
    let carrierName = this.getCarrierIdByName.getName(
      this.carriers,
      this.form.get('carrier')?.value
    );
    let dispatcherId = this.getDispatcherIdByName.get(
      this.dispathers,
      this.form.get('dispatcher')?.value
    );

    let pickupsArray = this.getPhoneNumberAndDateInFormArray.get(
      this.form.get('pickups') as FormArray
    );
    let deliveryArray = this.getPhoneNumberAndDateInFormArray.get(
      this.form.get('deliverys') as FormArray
    );
    this.setInfoOrder();
    let load: Load = {
      customLoadId: this.form.get('loadId')?.value,
      parentId: this.form.get('parentId')?.value,
      broker: {
        businessName: this.form.get('company')?.value
          ? this.form.get('company')?.value
          : null,
        fullName: this.form.get('name')?.value,
        id: this.form.get('brokerId')?.value || 0,
        email: this.form.get('email')?.value,
        phone: this.getPhoneNumberByObject(this.form.get('phone')?.value),
        street: this.form.get('street')?.value,
        city: this.form.get('city')?.value,
        stateProvinceCode: '',
        countryCode: this.form.get('countryCode')?.value,
        zipCode: this.form.get('zipCode')?.value,
        notes: this.form.get('notes')?.value,
        statusCode: '',
        statusName: '',
      },
      brokerFee: '',
      booking: this.form.get('booking')?.value,
      geometry: '',
      delivery: deliveryArray,
      driveTime: 0,
      driverId: this.form.get('driver')?.value,
      driverName: driverName,
      emptyMiles: {
        geometry: '',
        distance: this.form.get('miles')?.value,
        longitude: this.form.get('longitude')?.value
          ? this.form.get('longitude')?.value
          : 0,
        latitude: this.form.get('latitude')?.value
          ? this.form.get('latitude')?.value
          : 0,
        type: this.typeOfEmptyMiles,
      },
      files: this.files,
      history: null,
      fees: this.form.get('fee')?.value,
      feeIncludesInPrice: null,
      insertDate: '',
      loadId: 0,
      pickup: pickupsArray,
      price: this.form.get('price')?.value,
      status: this.form.get('status')?.value,
      loadMiles: this.form.get('loadMiles')?.value,
      carrierId: this.form.get('carrier')?.value,
      carrierName: carrierName,
      dispatcher: {
        id: Number(dispatcherId),
        dispatcherFullName: this.form.get('dispatcher')?.value,
      },
      loadType: 'Load',
      partial: [],
      dateTimeLineBgn: this.form.get('pickups')?.value[0].dateScheduledFrom,
      dateTimeLineEnd:
        this.form.get('deliverys')?.value[deliveryLength.length - 1]
          .dateScheduledFrom,
    };
    console.log(load);
    return load;
  }

  setInfoOrder() {
    let stops = [
      ...this.form.get('pickups')?.value,
      ...this.form.get('deliverys')?.value,
    ];
    for (let i = 0; i < stops.length; i++) [(stops[i].infoOrder = i)];
  }

  openTimePicker(id: string) {
    let time = document.getElementById(id) as HTMLInputElement;
    time?.showPicker();
  }

  getPhoneNumberByObject(obj?: any) {
    if (obj && typeof obj !== 'string') {
      let phone = obj.number;
      return phone;
    }
    return '';
  }

  addCoordinates(value: string) {
    this.toWhomAddCoordinates = value;
    this.openMap = true;
    setTimeout(() => this.initMap());
  }

  onInputChange(type: 'pickup' | 'delivery', index: number) {
    if (type === 'pickup') {
      this.addressSelectedFlags[index] = false;
    } else if (type === 'delivery') {
      const deliveryIndex = this.pickups.controls.length + index;
      this.addressSelectedFlags[deliveryIndex] = false;
    }

    this.sortArrByDateSheduledAndGetLatLong();
  }

  sortArrByDateSheduledAndGetLatLong() {
    let points: number[][] = [];

    [...this.form.get('pickups')?.value, ...this.form.get('deliverys')?.value]
      .sort((a, b) =>
        new Date(a.dateScheduledFrom).getTime() - new Date(b.dateScheduledFrom).getTime()
      )
      .forEach((el: any) => {
        this.form.updateValueAndValidity()
        const lat = Number(el.latitude);
        const lng = Number(el.longitude);
        if (lat && lng) {
          points.push([lat, lng]);
        }
      });

    if (points.length > 1) {
      this.geometryService.getDirections(points).subscribe((direction: any) => {
        const miles = direction?.routes?.[0]?.summary?.distance * 0.000621371;
        if (miles) {
          this.form.get('loadMiles')?.setValue(this.convertNumber(miles));
        }
      });
    } else {
      console.warn('Not enough points to calculate directions.');
    }
  }



  initMap() {
    this.setViewMap();
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy;',
    }).addTo(this.map);
  }

  setViewMap() {
    if (this.map) {
      this.map.remove();
    }
    this.map = L.map('map', {
      center: [39, -104],
      zoom: 4,
    });
  }

  setMarkerForCoordinates() {
    this.map.on('click', (event: any) => {
      this.clearMap();
      L.marker([event.latlng.lat, event.latlng.lng]).addTo(this.map);
      if (event) {
        this.longitudeAndLatitude =
          event.latlng.lat && event.latlng.lng
            ? `${event.latlng.lat},${event.latlng.lng}`
            : '';
      }
    });
  }

  manualInputOfCoordinates() {
    if (this.longitudeAndLatitude.includes(',')) {
      let [latitude, longitude] = this.longitudeAndLatitude.split(',');
      L.marker([Number(latitude), Number(longitude)]).addTo(this.map);
    } else {
      let splitedCoordinates = convert(this.longitudeAndLatitude);
      L.marker([
        Number(splitedCoordinates.decimalLatitude),
        Number(splitedCoordinates.decimalLongitude),
      ]).addTo(this.map);
    }

  }

  clearMap() {
    this.map.eachLayer((layer: any) => {
      if (layer instanceof L.Polyline || layer instanceof L.Marker) {
        this.map.removeLayer(layer);
      }
    });
  }

  addCoordinatesInForm() {
    switch (this.toWhomAddCoordinates) {
      case 'pickUp':
        this.addCoordinatesForPickUps();
        break;
      case 'delivery':
        this.addCoordinatesForDeliverys();
        break;
    }
    this.sortArrByDateSheduledAndGetLatLong()
    this.openMap = false;
  }

  addCoordinatesForPickUps() {
    let [longitude, latitude] = this.longitudeAndLatitude.split(',');
    const pickupFormGroup = this.pickups.at(this.pIndex) as FormGroup;
    pickupFormGroup.patchValue({
      fullAddress: this.longitudeAndLatitude,
      latitude: Number(latitude),
      longitude: Number(longitude),
    });
    this.selectLastDelivery('lastDelivery')
  }

  addCoordinatesForDeliverys() {
    let [longitude, latitude] = this.longitudeAndLatitude.split(',');
    const deliveryFormGroup = this.deliverys.at(this.dIndex) as FormGroup;
    deliveryFormGroup.patchValue({
      fullAddress: this.longitudeAndLatitude,
      latitude: Number(latitude),
      longitude: Number(longitude),
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  convertNumber(num: number) {
    return Math.round(num)
  }
}
