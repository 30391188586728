import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Country } from '@angular-material-extensions/select-country';
import { NotificationService } from '../notification.service';

@Injectable({
  providedIn: 'root',
})
export class GeometryService {
  constructor(private http: HttpClient,
    private notificationService: NotificationService
  ) { }
  private emptyMiles = new BehaviorSubject<any>('');
  public emptyMilesItem = this.emptyMiles.asObservable();
  private longAngLatCoors = new BehaviorSubject<any>('');
  public longAngLatCoorsString = this.longAngLatCoors.asObservable();

  findAddress(id: string, item: any, alpha3Code: boolean = false): Promise<void> {
    return new Promise((resolve) => {
      const el = document.getElementById(id) as HTMLInputElement;
      const service = new google.maps.places.Autocomplete(el, {
        componentRestrictions: { country: 'us' },
      });
      service.addListener('place_changed', () => {
        const place = service.getPlace();
        this.parseAddress(
          item,
          place.formatted_address,
          place.geometry?.location,
          place.address_components,
          alpha3Code
        );

        if (id === 'google-searchp0' || id === 'google-searchppe0') {
          this.emptyMiles.next(item);
        }

        if (place && typeof item !== 'string') {
          if (
            place.business_status &&
            place.business_status === 'OPERATIONAL' &&
            place.name
          ) {
            item.businessName = place.name;
          }
          if (place.international_phone_number) {
            item.phone = place.international_phone_number;
          } else if (place.formatted_phone_number) {
            item.phone = place.formatted_phone_number;
          }
        }
        resolve();
      });

      this.emptyMiles.next('');
    });
  }

  parseAddress(point: any, address: any, location: any, components: any, alpha3Code: boolean) {
    const regex = /^(.+),\s*(.+),\s*([A-Z]{2})\s*([\d-]+)?,\s*(.+)$/;
    const match = address.match(regex);
    if (match) {
      const streetAddress = match[1];
      const city = match[2] || '';
      const state = match[3] || '';
      const zipCode = match[4] || '';
      const country = match[5] || '';

      if (typeof point !== 'string') {
        point.get('street').setValue(streetAddress);
        point.get('city').setValue(city);
        point.get('zipCode').setValue(zipCode);
        point.get('stateCode').setValue(state);
        if (alpha3Code) {
          let c: Country = {
            name: "United States of America",
            alpha2Code: "US",
            alpha3Code: country,
            numericCode: "",
            callingCode: ""
          }
          point.get('countryCode').setValue(c);
        }
        else point.get('countryCode').setValue(country);
        point
          .get('fullAddress')
          .setValue(
            `${point.get('street').value}, ${point.get('city').value}, ${point.get('stateCode').value
            }, ${point.get('zipCode').value}, ${alpha3Code ? point.get('countryCode').value.alpha3Code : point.get('countryCode').value}`
          );
      }
    } else {
      const split = address.split(', ');
      let state = '';
      for (let part of split) {
        if (part.length === 2) {
          state = part;
          break;
        }
        let temp = components.find((el: any) => el.long_name === part);
        if (temp && temp.short_name.length === 2) {
          state = temp.short_name;
          break;
        }
      }
      if (typeof point !== 'string') {
        point.get('street').setValue('');
        point.get('city').setValue('');
        point.get('zipCode').setValue('');
        point.get('stateCode').setValue(state);
        if (alpha3Code) {
          let c: Country = {
            name: "United States of America",
            alpha2Code: "US",
            alpha3Code: 'USA',
            numericCode: "",
            callingCode: ""
          }
          point.get('countryCode').setValue(c);
        }
        else point.get('countryCode').setValue('USA');

        point
          .get('fullAddress')
          .setValue(
            ` ${point.get('stateCode').value}, ${alpha3Code ? point.get('countryCode').value.alpha3Code : point.get('countryCode').value}`
          );
      }
    }
    if (location) {
      if (typeof point === 'string') {
        let latitude = location.lng();
        let longitude = location.lat();
        this.longAngLatCoors.next(latitude + ',' + longitude);
      } else {
        point.get('longitude').setValue(location.lat());
        point.get('latitude').setValue(location.lng());
      }
    }
  }

  getDirections(points?: any[]) {
    let body = { coordinates: points, radiuses: [50000] }
    return this.http.post(`https://map.zigzagplus.com/ors/v2/directions/driving-hgv`, body)
  }
}
