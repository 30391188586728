import { DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TimeLineCalendarComponent } from '../../../time-line-calendar/time-line-calendar.component';
import { SharedService } from '../../../../../../../services/sharedServices/shared.service';
import { EditLoadComponent } from './load/edit-load/edit-load.component';
import { Load } from '../../../types/load';
import { LoadsService } from '../../../../../../../services/loads/loads.service';
import { DialogService } from '../../../../../../../services/dialog/dialog.service';
import { DialogComponent } from '../../../../../../../components/dialog/dialog.component';
import { LoaderService } from '../../../../../../../services/loader/loader.service';
import { NotificationService } from '../../../../../../../services/notification.service';

@Component({
  selector: 'app-view-edit-load',
  templateUrl: './view-edit-load.component.html',
  styleUrls: ['./view-edit-load.component.scss'],
})
export class ViewEditLoadComponent implements OnInit {
  @ViewChild(EditLoadComponent) editLoadComponent!: EditLoadComponent;
  disabledForm = { value: true };
  selectedLoadId: any;
  selectedLoad: any;
  dispathers: any;
  carriers: any;
  drivers: any;
  result: any;
  partial: any;
  inputIdMap: string = 'map-edit';

  isFormDirty: boolean = false;

  index: number = 0;

  selectEdit: boolean = false;
  public isLoading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TimeLineCalendarComponent,
    public dialogRef: MatDialogRef<TimeLineCalendarComponent>,
    public sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private loadService: LoadsService,
    private dialogService: DialogService,
    private loaderService: LoaderService,
  ) {
  }

  async ngOnInit() {
    this.result = this.data;
    this.selectedLoadId = this.result.load;
    if (this.selectedLoadId) {
      await this.getLoadById(this.selectedLoadId);
    }
    this.carriers = this.result.carriers;
    this.drivers = this.result.drivers;
    this.dispathers = this.result.dispatcers;
    this.partial = this.result.partial
    if (this.result.inputIdMap) {
      this.inputIdMap = this.result.inputIdMap;
    }
  }

  editValue() {
    this.selectEdit = true;
    this.disabledForm = { value: false };
    this.cdr.markForCheck();
  }

  changeTab(event: any) {
    this.index = event.index;
  }

  updateLoad() {
    this.isLoading = true;
    this.loaderService.show();
    this.editLoadComponent.updateLoad().then(res => {
      this.isLoading = false;
      this.loaderService.hide();
    })
  }

  deleteLoad() {
    const deletIconStyle = { stroke: '#D92D20', width: '48px', heigth: '48px' };
    const btn1Style = { background: 'white', color: '#344054', border: '1px solid #D0D5DD' };
    const btn2Style = { background: '#D92D20', color: 'white', border: '1px solid #D92D20' };

    const dialogData: any = {
      title: 'Delete',
      text: `Are you sure you want to delete this load? This action cannot be undone.`,
      button1Text: 'Cancel',
      button2Text: 'Delete',
      icon: 'custom-trash',
      iconStyle: deletIconStyle,
      dsa: true
    };

    const dialogRefference = this.dialogService.openDialog(DialogComponent, {
      props: { ...dialogData, btn1Style, btn2Style }
    });

    dialogRefference.subscribe(dialog => {
      if (!dialog) return;
      switch (dialog.result) {
        case 'button1': break;
        case 'button2':
          this.loaderService.show();
          this.isLoading = true;
          this.editLoadComponent.deleteLoadById().then(() => {
            this.isLoading = false;
            this.loaderService.hide();
          });
          break;
      }
    });
  }

  onFormStatusChanged(isDirty: boolean) {
    this.isFormDirty = isDirty;
    this.cdr.markForCheck();
  }

  async getLoadById(id: number) {
    let resp = await this.loadService.getLoadById(id)
    this.selectedLoad = resp;
  }

  closeModal() {
    this.dialogRef.close();
  }
}
