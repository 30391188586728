import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../services/dialog/dialog.service';
import { LoadsService } from '../../../services/loads/loads.service';
import { Country as SelectedCountry } from '@angular-material-extensions/select-country';
import { Country, State, City }  from 'country-state-city';
import { PhoneNumberFormat } from 'google-libphonenumber';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input-gg';
import { Item } from '../../../interfaces/item';
import { PopUpSubscriptBase } from '../../../services/popup-service/popup_subscribe_services';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-edit-vehicle-popup',
  templateUrl: './edit-vehicle-popup.component.html',
  styleUrl: './edit-vehicle-popup.component.scss'
})
export class EditVehiclePopupComponent extends PopUpSubscriptBase implements OnInit, OnDestroy {

  onConfirm = new EventEmitter();
  onDeactivate = new EventEmitter();

  public vehicleForm: FormGroup;
  public states: Array<any> = new Array<any>();
  public data: any;

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private loadService: LoadsService,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditVehiclePopupComponent>,
  ) {
    super(new Subject<any>());
      this.vehicleForm = this.fb.group({
        carrierId: ['', Validators.required],
        vehicleUnit: ['', Validators.required],
        vin: [''],
        make: [''],
        model: [''],
        year: [''],
        fuelCode: [''],
        country: [{alpha2Code: "US"}],
        stateProvinceCode: [],
        plateNumber: [[]],
    });
    this.vehicleForm.valueChanges.subscribe((change) => {
      if(change.country) this.states = this.getStatesByCountry(change.country);
      else this.states.length = 0;
    });
  }
  public carriers: Array<any> = new Array<any>();
  public yearsArr: Array<any> = new Array<any>();
  public modelArr: Array<Item> = new Array<Item>();
  public fuelCodeArr: Array<Item> = new Array<Item>();;
  public vehicleData: any = {};
  
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  protected override showAPopUp(data: any): void {
    this.data = data.data;
  }

  ngOnInit(): void {
    this.initializePopupData();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  };

  initializePopupData() {
    Promise.all([
      this.loadService.getAllCarriers(),
      this.loadService.getVehicleById(this.data.data.vehicleId),
    ]).then(all=>{
      this.carriers = all[0];
      this.vehicleData = all[1];
      this.setFormData();
    });
    this.modelArr = this.data.makeType;
    this.fuelCodeArr = this.data.fuelType;
    const currYear = new Date().getFullYear();

    for (let i = currYear - 50; i <= currYear; i++) {
      const element = {value: i.toString(), viewValue: i.toString()};
      this.yearsArr.push(element);
    }
  }

  setFormData() {
    let selectedCountry: SelectedCountry = {alpha2Code: this.vehicleData.countryCode};
    this.vehicleForm.setValue({
      carrierId: this.vehicleData.carrierId,
      vehicleUnit: this.vehicleData.vehicleUnit,
      vin: this.vehicleData.vin,
      make: this.vehicleData.make,
      model: this.vehicleData.model,
      year: this.vehicleData.year,
      fuelCode: this.vehicleData.fuelCode,
      country: selectedCountry.alpha2Code.length > 0 ? selectedCountry : {alpha2Code: "US"},
      stateProvinceCode: this.vehicleData.stateProvinceCode,
      plateNumber: this.vehicleData.plateNumber,
    });
    this.vehicleForm.markAllAsTouched();
  }

  
  deactivateClick() {
    this._result.next({type:"deactivate", data: this.data});
  }
  
  closeBtnClick(){
    this._result.next({type:"close"});
  }

  close(): void {
    this.dialogRef.close();
  }

  okBtnClick() {
    this._result.next({type:"ok", formData: this.vehicleForm.value});
  }

  getStatesByCountry(country: SelectedCountry) {
    return State.getStatesOfCountry(country.alpha2Code);
  }

  isStateLabelVisible(): boolean {
    return !this.vehicleForm.get('stateProvinceCode')?.value || !this.states.some(s=>s.isoCode === this.vehicleForm.get('stateProvinceCode')?.value);
  }

  isCarrierLabelVisible(): boolean {
    return !this.vehicleForm.get('carrierId')?.value || !this.carriers.some(s=>s.id === this.vehicleForm.get('carrierId')?.value);
  }

  isYearLabelVisible(): boolean {
    return !this.vehicleForm.get('year')?.value || !this.yearsArr.some(s=>s.value === this.vehicleForm.get('year')?.value);
  }

  isMakeTypeLabelVisible(): boolean {
    return !this.vehicleForm.get('make')?.value || !this.modelArr.some(s=>s.code === this.vehicleForm.get('make')?.value);
  }

  isFuelLabelVisible(): boolean {
    return !this.vehicleForm.get('fuelCode')?.value || !this.fuelCodeArr.some(s=>s.code === this.vehicleForm.get('fuelCode')?.value);
  }
}
