<div class="create-container">
  <form [formGroup]="form">
    <div class="header-inputs">
      <div class="block-inputs">
        <label>Load Id</label>
        <input type="text" formControlName="customLoadId" />
      </div>
      <div class="block-inputs-icon-preffix">
        <label>Status</label>
        <mat-select #selectStatus class="status-select" matNativeControl formControlName="status" placeholder="Status">
          <ng-container *ngFor="let item of status; let i = index">
            <mat-option [value]="item.code">{{ item.name }}</mat-option>
          </ng-container>
        </mat-select>
        <mat-icon (click)="selectStatus.toggle()" matSuffix
          style="cursor: pointer; position: absolute; right: 5px;"><img src="/assets/svg/chevron-down.svg" /></mat-icon>
      </div>
      <div class="block-inputs">
        <label>Driver</label>
        <mat-icon matPreffix><img src="/assets/svg/search-img.svg" alt="" /></mat-icon>
        <mat-select matNativeControl formControlName="driver" placeholder="Driver">
          <mat-option *ngFor="let item of drivers" [value]="item.driverFullName">{{ item.driverFullName }}</mat-option>
        </mat-select>
        <img style="
            position: absolute;
            width: 16px;
            height: 16px;
            right: 11px;
            top: 10px;
          " src="/assets/svg/help-img.svg" alt="" />
      </div>
      <div class="block-inputs">
        <label>Carrier</label>
        <mat-icon matPrefix><img src="/assets/svg/search-img.svg" alt="" /></mat-icon>
        <mat-select matNativeControl formControlName="carrier" id="" placeholder="Carrier">
          <ng-container *ngFor="let item of carriers; let i = index">
            <mat-option [value]="item.name">{{ item.name }}</mat-option>
          </ng-container>
        </mat-select>
      </div>
      <div class="block-inputs">
        <label>Dispatcher</label>
        <mat-icon matPrefix><img src="/assets/svg/search-img.svg" alt="" /></mat-icon>
        <mat-select matNativeControl formControlName="dispatcher" placeholder="Dispatcher">
          <ng-container *ngFor="let item of dispathers; let i = index">
            <mat-option [value]="item.fullName">{{ item.fullName }}</mat-option>
          </ng-container>
        </mat-select>
        <img style="
            position: absolute;
            width: 16px;
            height: 16px;
            right: 11px;
            top: 10px;
          " src="/../../../assets/svg/help-img.svg" alt="" />
      </div>
    </div>
    <div class="container-information">
      <div class="pickup">
        <div style="display: flex; justify-content: space-between">
          <strong>{{ "Pickup Information " }}</strong>
          <button [ngStyle]="
              disabledForm.value ? { display: 'none' } : { display: 'flex' }
            " [disabled]="disabledForm.value" class="pick-btn" (click)="addPickup()" type="button">
            <img src="/../assets/svg/add-circle-outline-img.svg" alt="" />{{ "Add Pickup" }}
          </button>
        </div>
        <div>
          <div style="
              display: flex;
              justify-content: space-between;
            ">
            <div style="display: flex; overflow: auto;">
              <div style="margin: 5px" *ngFor="let item of pickups?.controls; let i = index" cdkDropList
                cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event, 'pickups')">
                <button class="inactive-btn" cdkDrag (click)="changeSelectedTab(i, 'pickup')"
                  [ngClass]="{ 'active-btn': pIndex === i }" type="button">
                  {{ "Pickup" }}{{ i + 1 }}
                </button>
              </div>
            </div>
            <div style="display: flex">
              <button [disabled]="disabledForm.value" style="background-color: transparent; border: none" type="button">
                <mat-icon class="remove-icon" *ngIf="pickups?.controls.length > 1" (click)="removePickup(pIndex)"><img
                    src="/assets/svg/remove-img.svg" /></mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div formArrayName="pickups">
          <div *ngFor="let pickup of pickups?.controls; let i = index" [formGroupName]="i">
            <div *ngIf="pIndex === i">
              <div class="input-container">
                <div class="block-inputs">
                  <label>Shipper</label>
                  <input formControlName="businessName" type="text" placeholder="Shipper" />
                </div>
                <div class="block-inputs">
                  <label>Phone Number</label>
                  <ngx-intl-tel-input style="width: 100%" [preferredCountries]="[CountryISO.UnitedStates]"
                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[
                      SearchCountryField.Iso2,
                      SearchCountryField.Name
                    ]" [selectFirstCountry]="true" [selectedCountryISO]="CountryISO.UnitedStates"
                    [separateDialCode]="true" [maxLength]="15" [phoneValidation]="true" name="phone"
                    formControlName="phone"></ngx-intl-tel-input>
                </div>
              </div>
              <div class="input-container">
                <div style="width: 100%" class="block-input-icon">
                  <label>Location</label>
                  <mat-icon><img src="/../../../assets/svg/location-on-img.svg" alt="" /></mat-icon>
                  <input formControlName="fullAddress" [id]="'google-search' + inputPickUp + i" required
                    placeholder="Ex: 7128 Tavita St, Las Vegas, NV 89113, USA*" type="text" />
                </div>
              </div>
              <div class="input-container">
                <div class="block-input-icon">
                  <label>Scheduled</label>
                  <mat-datepicker-toggle matPrefix [for]="picker" class="date-picker-icon"><mat-icon
                      matDatepickerToggleIcon>date_range</mat-icon></mat-datepicker-toggle>
                  <input class="date-picker-input" style="margin: 0 0 3px 5px" formControlName="dateScheduledFrom"
                    [matDatepicker]="picker" placeholder="Pickup Date" />
                  <mat-datepicker #picker></mat-datepicker>
                </div>
                <div class="block-input-icon">
                  <label for="pickupTime">From</label>
                  <div class="centered"><img (click)="openTimePicker('pickupTime')"
                      src="../../../../../../../../assets/svg/access-time-img.svg" alt="icon" />
                    <input class="custom-time-input" formControlName="pickupTime" id="pickupTime" type="time"
                      placeholder="Pickup Time">
                  </div>
                </div>
                <div class="block-input-icon">
                  <label for="pickUpDateScheduledTo">To</label>
                  <div class="centered">
                    <img (click)="openTimePicker('pickUpDateScheduledTo')"
                      src="../../../../../../../../assets/svg/access-time-img.svg" alt="icon" />
                    <input class="custom-time-input" formControlName="dateScheduledTo" name="selectTime"
                      id="pickUpDateScheduledTo" type="time" placeholder="To">
                  </div>
                </div>
              </div>
              <div class="input-container">
                <div class="block-input-icon">
                  <label>Full Name</label>
                  <mat-icon matPreffix><img src="/../../../assets/svg/person-img.svg" /></mat-icon>
                  <input formControlName="fullName" type="text" placeholder="Full Name" />
                </div>
                <div class="block-input-icon">
                  <label>Email</label>
                  <mat-icon matPrefix><img src="/../assets/svg/email-img.svg" /></mat-icon>
                  <input style="margin: 3px 0 3px 5px" formControlName="email" placeholder="Email" type="text" />
                </div>
              </div>
              <div class="input-container">
                <mat-form-field appearance="outline" class="custom-multi-input-cont full-width-input">
                  <mat-label>Notes</mat-label>
                  <input matInput formControlName="notes" type="text" maxlength="85" placeholder="Add Notes">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="delivery">
        @if(this.form?.get('status')?.value !== 'TONU' &&
        this.selectedLoad?.status !== 'TONU') {
        <div style="display: flex; justify-content: space-between">
          <strong>{{ "Delivery Information " }}</strong>
          <button [ngStyle]="
              disabledForm.value ? { display: 'none' } : { display: 'flex' }
            " [disabled]="disabledForm.value" class="pick-btn" (click)="addDelivery()" type="button">
            <img src="/../assets/svg/add-circle-outline-img.svg" alt="" />
            {{ "Add Delivery" }}
          </button>
        </div>
        <div style="
            display: flex;
            justify-content: space-between;
          ">
          <div style="display: flex; overflow: auto;">
            <div style="margin: 5px" *ngFor="let item of delivery?.controls; let i = index" cdkDropList
              cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event, 'delivery')">
              <button class="inactive-btn" cdkDrag (click)="changeSelectedTab(i, 'delivery')"
                [ngClass]="{ 'active-btn-delivery': dIndex === i }" type="button">
                {{ "Delivery" }}{{ i + 1 }}
              </button>
            </div>
          </div>
          <div style="display: flex">
            <button [disabled]="disabledForm.value" style="background-color: transparent; border: none" type="button">
              <mat-icon class="remove-icon" *ngIf="delivery?.controls.length > 1" (click)="removeDelivery(dIndex)"><img
                  src="/assets/svg/remove-img.svg" /></mat-icon>
            </button>
          </div>
        </div>
        <div formArrayName="delivery">
          <div *ngFor="let delivery of delivery?.controls; let i = index" [formGroupName]="i">
            <div *ngIf="dIndex === i">
              <div class="input-container">
                <div class="block-inputs">
                  <label>Receiver</label>
                  <input formControlName="businessName" type="text" placeholder="Receiver" />
                </div>
                <div class="block-inputs">
                  <label>Phone Number</label>
                  <ngx-intl-tel-input style="width: 100%" [preferredCountries]="[CountryISO.UnitedStates]"
                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[
                      SearchCountryField.Iso2,
                      SearchCountryField.Name
                    ]" [selectFirstCountry]="true" [selectedCountryISO]="CountryISO.UnitedStates"
                    [separateDialCode]="true" [maxLength]="15" [phoneValidation]="true" name="phone"
                    formControlName="phone"></ngx-intl-tel-input>
                </div>
              </div>
              <div class="input-container">
                <div style="width: 100%" class="block-input-icon">
                  <label>Location</label>
                  <mat-icon><img src="/../../../assets/svg/location-on-img.svg" alt="" /></mat-icon>
                  <input formControlName="fullAddress" [id]="'google-search' + inputDelivery + i" required
                    placeholder="Ex: 7128 Tavita St, Las Vegas, NV 89113, USA*" type="text" />
                </div>
              </div>
              <div class="input-container">
                <div class="block-input-icon">
                  <label>Scheduled</label>
                  <mat-datepicker-toggle class="date-picker-icon" matPrefix [for]="picker"><mat-icon
                      matDatepickerToggleIcon>date_range</mat-icon></mat-datepicker-toggle>
                  <input class="date-picker-input" style="margin: 0 0 3px 5px" formControlName="dateScheduledFrom"
                    [matDatepicker]="picker" placeholder="Delivery Date" />
                  <mat-datepicker #picker></mat-datepicker>
                </div>
                <div class="block-input-icon">
                  <label>From</label>
                  <div class="centered">
                    <img (click)="openTimePicker('deliveryTime')"
                      src="../../../../../../../../assets/svg/access-time-img.svg" alt="icon" />
                    <input class="custom-time-input" formControlName="deliveryTime" id="deliveryTime" type="time"
                      placeholder="Delivery Time">
                  </div>
                </div>
                <div class="block-input-icon">
                  <label>To</label>
                  <div class="centered">
                    <img (click)="openTimePicker('deliverydateScheduledTo')"
                      src="../../../../../../../../assets/svg/access-time-img.svg" alt="">
                    <input class="custom-time-input" formControlName="dateScheduledTo" id="deliverydateScheduledTo"
                      type="time" placeholder="To">
                  </div>
                </div>
              </div>
              <div class="input-container">
                <div class="block-input-icon">
                  <label>Full Name</label>
                  <mat-icon matPreffix><img src="/../../../assets/svg/person-img.svg" /></mat-icon>
                  <input formControlName="fullName" type="text" placeholder="Full Name" />
                </div>
                <div class="block-input-icon">
                  <label>Email</label>
                  <mat-icon matPrefix><img src="/../assets/svg/email-img.svg" /></mat-icon>
                  <input style="margin: 3px 0 3px 5px" formControlName="email" placeholder="Email" type="text" />
                </div>
              </div>
              <div class="input-container">
                <mat-form-field appearance="outline" class="custom-multi-input-cont full-width-input">
                  <mat-label>Notes</mat-label>
                  <input matInput formControlName="notes" type="text" maxlength="85" placeholder="Add Notes">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        } @else {
        <div style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          ">
          <div>
            <strong>{{ "Delivery Information " }}</strong>
          </div>
          <button class="view-info-btn" (click)="viewInfoAboutDelivery()" type="button">
            <img src="/../../../assets/svg/eye-img.svg" />{{
            "View Info"
            }}
          </button>
        </div>
        <div class="delivery-tonu">
          <div>TONU</div>
        </div>
        }
      </div>
    </div>
    <div class="container-information">
      @if (selectedLoad?.partial) {
      <div class="partial-load-exist">
        <div style="display: flex; justify-content: space-between">
          <strong>Partial Load</strong>
          <button [disabled]="disabledForm.value" class="pick-btn" [ngStyle]="
              disabledForm.value ? { display: 'none' } : { display: 'flex' }
            " type="button" (click)="createPartialLoad()">
            <img src="/../assets/svg/add-circle-outline-img.svg" alt="" />{{ "Add Partial Load" }}
          </button>
        </div>
        <div style="display: flex; overflow: auto;">
          <div style="margin: 5px" *ngFor="let item of partialLoad.controls; let i = index">
            <button class="inactive-btn" type="button" (click)="changeSelectedTab(i, 'partial')"
              [ngClass]="{ 'active-btn-delivery': partialId === i }">
              {{ "Load" }}{{ i + 1 }}
              <mat-icon [disabled]="disabledForm.value"
                [ngStyle]="disabledForm.value ? { display: 'none' } : { display: 'block' }" class="hovered-icon"
                (click)="editPartial(partialId)"><img src="/assets/svg/pencil-edit-underline.svg" /></mat-icon>
            </button>
          </div>
        </div>
        <div formArrayName="partialLoad">
          <div *ngFor="let item of partialLoad.controls; let i = index" [formGroupName]="i">
            <div *ngIf="partialId === i">
              <div class="input-container">
                <div class="block-inputs">
                  <label>Load ID</label>
                  <input type="text" formControlName="partialLoadId" />
                </div>
                <div class="block-inputs">
                  <label>Pickup</label>
                  <input type="text" formControlName="partialDateFrom" />
                </div>
                <div class="block-inputs">
                  <label>Delivery</label>
                  <input type="text" formControlName="partialDateTo" />
                </div>
              </div>
              <div class="input-container">
                <div class="block-inputs">
                  <label>Price</label>
                  <input type="text" formControlName="partialPrice" />
                </div>
                <div class="block-inputs">
                  <label>Total mi</label>
                  <input type="text" formControlName="partialTotalMi" />
                </div>
                <div class="block-inputs">
                  <label>Rate Mile</label>
                  <input type="text" formControlName="partialRatePerMi" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      } @else {
      <div class="partial-not-exist">
        <div style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          ">
          <strong>Partial Load</strong>
          <button *ngIf="!selectedLoad?.parentId" [ngStyle]="
              disabledForm.value ? { display: 'none' } : { display: 'flex' }
            " [disabled]="disabledForm.value" class="pick-btn" type="button" (click)="createPartialLoad()">
            <img src="/../assets/svg/add-circle-outline-img.svg" alt="" />{{ "Add Partial Load" }}
          </button>
        </div>
        <div class="no-parial">
          <div>Not partial load</div>
        </div>
      </div>
      }
      <div class="payment">
        <div>
          <strong>Payment</strong>
        </div>
        <div style="margin-bottom: 0; gap: 16px;" class="input-container">
          <div class="block-inputs">
            <label>{{"Price"}}</label>
            <input type="text" formControlName="price" placeholder="Payment" />
          </div>
          <div class="block-inputs">
            <label>{{"Partial"}}</label>
            <input type="text" formControlName="partialPrice" placeholder="Partial" />
          </div>
          <div class="block-inputs">
            <label>{{"Total"}}</label>
            <input type="text" formControlName="totalPrice" placeholder="Total" />
          </div>
        </div>
        <div style="display: flex; align-items: center; justify-content: space-between;">
          <div>
            <strong>Fee</strong>
          </div>
          <div style="display: flex; align-items: center; justify-content: center">
            <button [ngStyle]="
              disabledForm.value ? { display: 'none' } : { display: 'flex' }
            " [disabled]="disabledForm.value" class="pick-btn" (click)="addFee()" type="button">
              <img src="/../assets/svg/add-circle-outline-img.svg" alt="" />
            </button>
          </div>
        </div>
        <div *ngIf="openType || fee?.controls.length > 0">
          <div formArrayName="fee">
            <div *ngFor="let item of fee?.controls; let i = index" [formGroupName]="i">
              <div class="input-container" style="
                  display: flex;
                  gap: 16px;
                  align-items: center;
                ">
                <mat-form-field appearance="outline" class="custom-multi-input-cont">
                  <mat-label>Fee Type</mat-label>
                  <input matInput type="text" formControlName="feeName" [matAutocomplete]="type" placeholder="Fee Type">
                  <mat-autocomplete autoActiveFirstOption #type="matAutocomplete">
                    <mat-option *ngFor="let type of feeType" [value]="type.name" id="allOption">
                      {{ type.name }}
                    </mat-option>
                  </mat-autocomplete>
                  <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
                </mat-form-field>

                <mat-form-field appearance="outline" class="custom-multi-input-cont">
                  <mat-label>Action</mat-label>
                  <mat-select (selectionChange)="selectOptionInFee($event.value)" formControlName="addToGross"
                    placeholder="Select Action">
                    <mat-option *ngFor="let key of actionFeeKeys; let i = index" id="allOption" [value]="key">
                      {{ actionsFee[key] }}
                    </mat-option>
                  </mat-select>
                  <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
                </mat-form-field>

                <mat-form-field appearance="outline" class="custom-multi-input-cont">
                  <mat-label>Price</mat-label>
                  <input matInput placeholder="0" formControlName="feePrice" type="text" placeholder="Price" />
                </mat-form-field>

                <mat-icon [ngStyle]="disabledForm.value ? { display: 'none' } : { display: 'block'} "
                  style="height: 29px;" (click)="removeFee(i)" class="remove-icon"><img
                    src="../../../../../../../assets/svg/remove-img.svg"></mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="input-container">
          <div *ngIf="!openType && fee?.controls.length === 0" class="block-input-icon">
            <mat-icon disabled><img src="/assets/svg/error-outline-img.svg" /></mat-icon>
            <input disabled type="text" placeholder="No fee yet" />
          </div>
        </div>
      </div>
    </div>
    <div class="container-information">
      <div style="width: 100%" class="customer-broker-info">
        <div style="display: flex; justify-content: space-between;">
          <strong>Customer/Broker Information</strong>
          <button [disabled]="disabledForm.value" class="pick-btn" *ngIf="!selectedLoad?.broker" [ngStyle]="
              disabledForm.value ? { display: 'none' } : { display: 'flex' }
            " (click)="addBrokerInformation()" type="button">
            <img src="/../assets/svg/add-circle-outline-img.svg" alt="" />
          </button>
        </div>
        @if (selectedLoad.broker) {
        <div style="gap: 16px;" class="input-container">
          <div class="block-inputs">
            <label>Company</label>
            <input type="text" formControlName="company" [matAutocomplete]="auto" placeholder="Select Company" />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="
                addInformationAboutBrokerByName($event.option.value)
              ">
              @for (broker of filteredBroker| async; track brokers) {
              <mat-option [value]="broker.businessName">{{
                broker.businessName
                }}</mat-option>
              }
            </mat-autocomplete>
          </div>
          <div class="block-inputs">
            <label>Full Name</label>
            <input type="text" formControlName="name" />
          </div>
          <div class="block-inputs">
            <label>Phone Number</label>
            <ngx-intl-tel-input style="width: 100%" [preferredCountries]="[CountryISO.UnitedStates]"
              [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
              [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]" [selectFirstCountry]="true" [selectedCountryISO]="CountryISO.UnitedStates" [separateDialCode]="true"
              [maxLength]="15" [phoneValidation]="true" name="phone" formControlName="phone"></ngx-intl-tel-input>
          </div>
          <div class="block-input-icon">
            <label>Location</label>
            <mat-icon><img src="/../../../assets/svg/location-on-img.svg" alt="" /></mat-icon>
            <input [id]="'google-search' + inputBroker" type="text" formControlName="fullAddress" type="text"
              placeholder="Full Address" />
          </div>
          <div class="block-input-icon">
            <label>Email</label>
            <mat-icon><img src="/../../../assets/svg/email-img.svg" alt="" /></mat-icon>
            <input type="text" formControlName="email" />
          </div>
        </div>
        } @else {
        <div class="no-parial">
          <div>No broker data</div>
        </div>
        }
      </div>
    </div>
    <div class="container-information">
      <app-file-upload [files]="selectedLoad.files" (filesChanged)="handleFileChange($event)"></app-file-upload>
      <div style="height: 144px" class="history">
        <strong>History</strong>
        <div class="history-container" *ngIf="selectedLoad.loadsHistory">
          <div *ngFor="let history of selectedLoad.loadsHistory" class="history-item">
            <div class="history-date">
              <div class="history-date">{{ formatDate(history.date) }}</div>
              <div class="history-time">{{ formatTime(history.date) }}</div>
            </div>
            <div class="history-content">
              <div class="history-user">
                <div class="main-curcle">
                  <img src="/../../../assets/svg/Frame 1000003470.svg" alt="" />
                </div>
              </div>
            </div>
            <div>
              <div>
                <span class="history-name">{{ history.username }}</span><span class="history-role">{{
                  " (" + history.userRole + ")"
                  }}</span>
              </div>
              <div class="history-description">
                {{ history.description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="map-container">
      <div class="header-map">
        <div class="ranger">
          <div class="ranger-container">
            <span>{{ hours }}h</span>
            <span>{{ minutes }}m</span>
          </div>
          <div class="ranger-container">
            <span *ngIf="!selectedLoad.parentId" [ngStyle]=" this.disabledForm.value ? { 'pointer-events' : 'none' ,
              opacity: '0.6' } : { 'pointer-events' : 'auto' , opacity: '1' } " (click)="openDropDownEmptyMiles()"
              style="display: flex; cursor: pointer"><img style="width: 12px; height: 12px"
                src="/../../../assets/svg/settings-02-stroke-rounded-2-img.svg" /></span>
            <span>d/h:</span>
            <span>
              <input [readonly]="!milesInputDisable" type="text" formControlName="miles" class="dynamic-width-input"
                (input)="adjustWidth($event.target)" (change)="adjustWidth($event.target)" #milesInput />
            </span>
          </div>
          <div class="ranger-container">
            <span style="height: 17px;"><img style="width: 12px; height: 12px"
                src="/assets/svg/pencil-edit-underline.svg" /></span>
            <span>loaded mi: <input type="number" formControlName="loadMiles" class="dynamic-width-input"
                (input)="adjustWidth($event.target)" (change)="adjustWidth($event.target)" #loadInput />
            </span>
          </div>
          <div class="ranger-container">
            <span>total mi: <span>{{ convertNumber(selectedLoad.totalMi) }}</span></span>
          </div>
          <div class="ranger-container">
            <span>$/mi: <span>{{ selectedLoad.rateMile }}</span></span>
          </div>

          <ul *ngIf="dropdownOpen" class="dropdown-list">
            <li *ngFor="let option of emptyMilesOptions; let i = index" [attr.data-value]="option"
              (click)="selectOption(option)" class="dropdown-item">
              {{ option }}
            </li>
          </ul>
        </div>
        <div class="header-icons">
          <div class="drop-list" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="onDrop($event)">
            <div *ngFor="let item of stops; let i = index" class="drag-item">
              <div style="white-space: nowrap;" [ngClass]="{
                  'cumstom-drug-pickup': item.infoType === 'PICKUP',
                  'cumstom-drug-delivery': item.infoType === 'DELIVERY'
                }" cdkDrag>
                <div>
                  {{ item.infoType !== "DELIVERY" ? "Pickup " : "Delivery"
                  }}{{
                  item.infoType !== "DELIVERY"
                  ? getPickupNumber(i)
                  : getDeliveryNumber(i)
                  }}
                </div>
                <img *ngIf="item?.partial" src="/../../../assets/svg/partial-icon.svg" alt="" />
              </div>
              <div class="custom-arrow">
                <div style="top: -6px" class="dist-dur">
                  {{ convertNumber(item.distance) + "mi" }}
                </div>
                <img src="assets/svg/arrow-3-img.svg" alt="Arrow" />
                <div style="top: 8px" class="dist-dur">{{ item.duration }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="full" [id]="inputIdMap" (click)="open($event)"></div>
    </div>
  </form>
</div>
<div *ngIf="openLocationModal" style="
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 36;
    top: 0;
    background: rgb(128, 128, 128, 0.5);
  ">
  <app-location-empty-miles (addNewItem)="getEmitCoordsFromMap($event)"
    (closeModalWithCoors)="closeModalWithCoors($event)"></app-location-empty-miles>
</div>
<!-- <div class="block-input-icon" #dropdownContainer> -->

<!-- <mat-icon style="cursor: pointer; z-index: 1;" matSuffix><img
      src="/assets/svg/setting-input-img.svg" alt=""></mat-icon>
</div>  -->