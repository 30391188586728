import { DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, inject, Input, model, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateModalLoadComponent } from '../create-modal-load/create-modal-load.component';
import L from 'leaflet';
import { convert } from 'geo-coordinates-parser';
import { GeometryService } from '../../../../../../../services/geometry/geometry.service';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-location-empty-miles',
  templateUrl: './location-empty-miles.component.html',
  styleUrls: ['./location-empty-miles.component.css']
})
export class LocationEmptyMilesComponent implements OnInit {
  @Output() addNewItem = new EventEmitter<string>();
  @Output() closeModalWithCoors = new EventEmitter<boolean>();

  emptyMilesCoors: string = '';
  openMap: boolean = false;
  map!: any;
  pickups: any;

  constructor(
    public geometryService: GeometryService
  ) { }

  ngOnInit() {
    setTimeout(() => this.createAddress())
  }

  createAddress() {
    setTimeout(() => this.geometryService.findAddress('google-search-empty-miles', ''))
    this.geometryService.longAngLatCoorsString.subscribe(value => {
      this.emptyMilesCoors = value;
    })
  }

  addCoordinates() {
    this.openMap = true
    setTimeout(() => this.initMap())
  }

  initMap() {
    this.setViewMap()
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy;'
    }).addTo(this.map)
  }

  setViewMap() {
    if (this.map) {
      this.map.remove();
    }
    this.map = L.map('map', {
      center: [39, -104],
      zoom: 4
    })
  }

  setMarketForCoordinates() {
    this.map.on('click', (event: any) => {
      this.clearMap()
      L.marker([event.latlng.lat, event.latlng.lng]).addTo(this.map);
      if (event) {
        this.emptyMilesCoors = event.latlng.lat && event.latlng.lng ? `${event.latlng.lng},${event.latlng.lat}` : '';
      }
    })
  }

  manualInputOfCoordinates() {
    if (this.emptyMilesCoors.includes(',')) {
      let [latitude, longitude] = this.emptyMilesCoors.split(',')
      L.marker([Number(latitude), Number(longitude)]).addTo(this.map);
    } else {
      let splitedCoordinates = convert(this.emptyMilesCoors)
      L.marker([Number(splitedCoordinates.decimalLatitude), Number(splitedCoordinates.decimalLongitude)]).addTo(this.map);
    }
  }

  addSelectedCoorsOrAdress() {
    this.addNewItem.emit(this.emptyMilesCoors);
    this.emptyMilesCoors = '';
    this.geometryService.longAngLatCoorsString.subscribe(value => {
      value = '';
    })
  }

  closeModal() {
    this.closeModalWithCoors.emit(false);
  }

  closeMap() {
    this.openMap = false
  }

  clearMap() {
    this.map.eachLayer((layer: any) => {
      if (layer instanceof L.Polyline || layer instanceof L.Marker) {
        this.map.removeLayer(layer);
      }
    });
  }
}
