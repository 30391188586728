<div class="timeline-navigate-group">
  <div class="date-picker">
    <mat-datepicker-toggle matSuffix [for]="picker">
      <mat-icon style="position: absolute; top: 14px; left: 11px; color: #175cd3" matDatepickerToggleIcon><img
          src="../../../../../../assets/svg/calendar-03-stroke-rounded 2.svg" /></mat-icon>
    </mat-datepicker-toggle>
    <mat-date-range-input (click)="picker.open()" style="font-size: 13px; cursor: pointer;" [rangePicker]="picker">
      <input matInput class="input-range" [(ngModel)]="start" readonly matStartDate
        (dateChange)="onStartDateChange($event)" />
      <input matInput class="input-range" [(ngModel)]="end" readonly matEndDate
        (dateChange)="onEndDateChange($event)" />
    </mat-date-range-input>
    <mat-date-range-picker style="position: absolute; top: 51px !important;left: 289px !important" #picker
      panelClass="date-picker-panelclass"></mat-date-range-picker>
  </div>
  <div class="day-filter">
    <div class="btns-timeline">
      <button class="btn-timeline" mat-ripple [disabled]="disabledBtns" (click)="shiftTimeline(-7)">
        <mat-icon class="icon-header-container"><img src="../../../../../../assets/svg/1-week-arrow-previous.svg"
            alt=""></mat-icon>
        {{"1 Week" }}</button>
      <button class="btn-timeline" mat-ripple [disabled]="disabledBtns" (click)="shiftTimeline(-1)">
        <mat-icon class="icon-header-container"><img src="../../../../../../assets/svg/1-day-arrow-previous.svg"
            alt=""></mat-icon>
        {{"1 Day" }} </button>
    </div>
    <button class="btn-range" [disabled]="disabledBtns" [ngClass]="{ 'active': timelineSize === '7' }" mat-ripple
      (click)="setMonday(7)">
      7 Days
    </button>
    <button class="btn-range" [disabled]="disabledBtns" [ngClass]="{ 'active': timelineSize === '8' }" mat-ripple
      (click)="setMonday(8)">
      8 Days
    </button>
    <button class="btn-range" [disabled]="disabledBtns" [ngClass]="{ 'active': timelineSize === '9' }" mat-ripple
      (click)="setMonday(9)">
      9 Days
    </button>
    <button class="btn-range" [disabled]="disabledBtns" [ngClass]="{ 'active': timelineSize === '10' }" mat-ripple
      (click)="setMonday(10)">
      10 Days
    </button>
    <button class="btn-range" [disabled]="disabledBtns" [ngClass]="{ 'active': timelineSize === '14' }" mat-ripple
      (click)="setMonday(14)">
      14 Days
    </button>
    <button class="btn-range" mat-ripple [disabled]="disabledBtns"
      [ngClass]="{ 'active': timelineSize === 'Current week' }" (click)="setMonday('Current week')">
      Current Week
    </button>
    <button class="btn-range" [disabled]="disabledBtns" [ngClass]="{ 'active': timelineSize === 'month' }" mat-ripple
      (click)="setMonth()">
      Month
    </button>
    <div class="btns-timeline">
      <button class="btn-timeline" mat-ripple [disabled]="disabledBtns" (click)="shiftTimeline(1)">
        {{ "1 Day"}}<mat-icon class="icon-header-container"><img src="../../../../../../assets/svg/1-day-arrow-next.svg"
            alt=""></mat-icon>
      </button>
      <button class="btn-timeline" mat-ripple [disabled]="disabledBtns" (click)="shiftTimeline(7)">
        {{ "1 Week"}}<mat-icon class="icon-header-container"><img
            src="../../../../../../assets/svg/double-chevron-rigth.svg" alt=""></mat-icon>
      </button>
    </div>
  </div>
  <div class="settings">
    <button (click)="openSettingModal()" class="setting-button">
      <mat-icon><img src="../../../../../../assets/svg/settings-02-stroke-rounded-2-img.svg" alt=""></mat-icon>
    </button>
  </div>

  <div class="setting-container" *ngIf="openSettings">
    <div style="display: flex; justify-content: space-between; align-items: center; gap: 16px;">
      <div class="container-icon">
        <mat-icon><img src="../../../../../../assets/svg/settings-02-stroke-rounded-2-img.svg" alt=""></mat-icon>
      </div>
      <div>
        <strong class="timeline-strong">Timeline Settings</strong>
        <p>Your new project has been created.</p>
      </div>
      <div style="margin: 16px">
        <mat-icon style="cursor: pointer" (click)="closeSettingModal()">close</mat-icon>
      </div>
    </div>
    <div style="margin: 15px 0">
    </div>
    <div class="settings-inputs">
      <div class="toggle-containder">
        <input (click)="toggleSettings('loadId')" class="apple-switch" type="checkbox"
          [(ngModel)]="tempSettingsData.showLoadId">
        <span (click)="toggleSettings('loadId')" class="toggle-text">Show Load ID</span>
      </div>

      <div class="toggle-containder" style="margin: 5px 0">
        <input (click)="toggleSettings('pricePerLoad')" class="apple-switch" type="checkbox"
          [(ngModel)]="tempSettingsData.showPricePerLoad">
        <span (click)="toggleSettings('pricePerLoad')" class="toggle-text">Show Price per Load</span>
      </div>

      <div class="toggle-containder" style="margin: 5px 0">
        <input (click)="toggleSettings('miles')" class="apple-switch" type="checkbox"
          [(ngModel)]="tempSettingsData.showMiles">
        <span (click)="toggleSettings('miles')" class="toggle-text">Show Miles</span>
      </div>

      <div class="toggle-containder" style="margin: 5px 0">
        <input (click)="toggleSettings('ratePerMiles')" class="apple-switch" type="checkbox"
          [(ngModel)]="tempSettingsData.showRatePerMiles">
        <span (click)="toggleSettings('ratePerMiles')" class="toggle-text">Show Rate per Miles</span>
      </div>

      <div class="toggle-containder" style="margin: 5px 0">
        <input (click)="toggleSettings('hideTotalBy')" class="apple-switch" type="checkbox"
          [(ngModel)]="tempSettingsData.hideTotalBy">
        <span (click)="toggleSettings('hideTotalBy')" class="toggle-text">Hide Total by</span>
      </div>

      <div class="toggle-containder" style="margin: 5px 0">
        <input (click)="toggleSettings('hideUnassignedLoads')" class="apple-switch" type="checkbox"
          [(ngModel)]="tempSettingsData.hideUnassignedLoads">
        <span (click)="toggleSettings('hideUnassignedLoads')" class="toggle-text">Hide Unassigned Loads</span>
      </div>

      <div>
        <img src="../../../../../../assets/svg/Divider-wrap.svg" alt="">
      </div>

      <div class="toggle-containder" style="margin: 5px 0">
        <input (click)="toggleSettings('showTruck')" class="apple-switch" type="checkbox"
          [(ngModel)]="tempSettingsData.showTruck">
        <span (click)="toggleSettings('showTruck')" class="toggle-text">Show Truck</span>
      </div>

      <div class="toggle-containder" style="margin: 5px 0">
        <input (click)="toggleSettings('showTrailer')" class="apple-switch" type="checkbox"
          [(ngModel)]="tempSettingsData.showTrailer">
        <span (click)="toggleSettings('showTrailer')" class="toggle-text">Show Trailer</span>
      </div>

      <div class="toggle-containder" style="margin: 5px 0">
        <input (click)="toggleSettings('showTruck')" class="apple-switch" type="checkbox"
          [(ngModel)]="tempSettingsData.showTruck"><span (click)="toggleSettings('showTruck')" class="toggle-text">Hide
          Company</span>
      </div>

      <div class="toggle-containder" style="margin: 5px 0">
        <input (click)="toggleSettings('showTrailer')" class="apple-switch" type="checkbox"
          [(ngModel)]="tempSettingsData.showTrailer"><span (click)="toggleSettings('showTrailer')"
          class="toggle-text">Hide
          Dispatcher</span>
      </div>
    </div>
  </div>
</div>