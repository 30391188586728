import { Pipe, PipeTransform } from '@angular/core';
import { Resource } from '../modules/main/pages/timeline/types/resource';

@Pipe({
  name: 'filterDriverByCarrier'
})
export class FilterDriverByCarrierPipe implements PipeTransform {

  transform(drivers: Resource[], key: string): Resource[] {
    if (!drivers || !drivers.length) {
      return []
    }
    if (!key || key === 'all') {
      return drivers
    }
    return drivers.filter(driver => driver.carrierId === key);
  }

}
