export enum Status {
    NEW = 'New',
    ASSIGNED = 'Assigned',
    EN_ROUTE = 'En Route',
    PICKED_UP = 'Picked Up',
    DELIVERED = 'Delivered',
    // BILLED = 'Billed',
    // PAID = 'Paid',
    // ARCHIVED = 'Archived',
    // CANCELED = 'Canceled',
    TONU = 'Tonu',
}
