import {
  Directive,
  Input,
  TemplateRef,
  HostListener,
  OnInit,
  ElementRef,
  ComponentRef
} from '@angular/core';
import {
  OverlayRef,
  Overlay,
  OverlayPositionBuilder,
  ConnectedPosition
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TooltipComponent } from '../components/popover/popover.component';

@Directive({ selector: '[appTooltip]' })
export class TooltipDirective implements OnInit {
  @Input('appTooltip') content!: string | TemplateRef<any>;
  @Input('appTooltipPos') appTooltipPos!: ConnectedPosition[];
  private overlayRef!: OverlayRef;
  private tooltipRef: ComponentRef<TooltipComponent> | null = null;
  private isTooltipHovered = false;
  private isHostHovered = false;

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay
  ) {}

  ngOnInit() {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions(this.appTooltipPos);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener('mouseover')
  show() {
    this.isHostHovered = true;
    if (!this.tooltipRef) {
      const tooltipPortal = new ComponentPortal(TooltipComponent);
      this.tooltipRef = this.overlayRef.attach(tooltipPortal);

      if (typeof this.content === 'string') {
        this.tooltipRef.instance.text = this.content;
      } else {
        this.tooltipRef.instance.content = this.content;
      }

      // Add mouse events to the tooltip to handle hover
      this.tooltipRef.location.nativeElement.addEventListener(
        'mouseover',
        this.onTooltipMouseOver.bind(this)
      );
      this.tooltipRef.location.nativeElement.addEventListener(
        'mouseout',
        this.onTooltipMouseOut.bind(this)
      );
    }
  }

  @HostListener('mouseout')
  hide() {
    this.isHostHovered = false;
    setTimeout(() => {
      if (!this.isTooltipHovered && !this.isHostHovered) {
        this.detachTooltip();
      }
    }, 100);
  }

  // Mouse events for the tooltip itself to keep it visible on hover
  private onTooltipMouseOver() {
    this.isTooltipHovered = true;
  }

  private onTooltipMouseOut() {
    this.isTooltipHovered = false;
    setTimeout(() => {
      if (!this.isTooltipHovered && !this.isHostHovered) {
        this.detachTooltip();
      }
    }, 100);
  }

  private detachTooltip() {
    if (this.tooltipRef) {
      this.overlayRef.detach();
      this.tooltipRef = null;
    }
  }
}
