import { Component, ContentChild, EventEmitter, Input, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FormBuilder, Validators, FormControl, AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { Country as SelectedCountry } from '@angular-material-extensions/select-country';
import { Country, State, City }  from 'country-state-city';

@Component({
  selector: 'app-edit-carrier-form',
  templateUrl: './edit-carrier-form.component.html',
  styleUrl: './edit-carrier-form.component.scss'
})
export class EditCarrierFormComponent {

  @Output() formValid = new EventEmitter<any>();
  @Input() row!: any;
  @ContentChild('attachmentTemplate') attachmentTemplate?: TemplateRef<any>;
  
  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.carrierForm = this.formBuilder.group({
      name: ['', Validators.required],
      mcNumber: ['', Validators.required],
      dotNumber: [''],
      street: ['', [Validators.required]],
      country: [[], [Validators.required]],
      stateProvinceCode: [[], [Validators.required]],
      zipCode: [''],
      timezoneCode: [''],
    });

    this.carrierForm.valueChanges.subscribe((change) => {
      this.stateProvinceHandler(change);
      this.emitFormValidity();
    });
  }

  public carrierForm: FormGroup;
  public states: Array<any> = new Array<any>();

  ngOnInit(): void {
    this.setFormData();
  }

  setFormData() {
    this.carrierForm.setValue({
      name: this.row.name,
      mcNumber: this.row.mcNumber,
      dotNumber: this.row.dotNumber,
      street: this.row.street,
      country: this.row.countryCode.length > 0 ? {alpha2Code:this.row.countryCode} : {alpha2Code: "US"},
      stateProvinceCode: this.row.stateProvinceCode,
      zipCode: this.row.zipCode,
      timezoneCode: this.row.timezoneCode
    });
    this.carrierForm.markAllAsTouched();
  }

  getStatesByCountry(country: SelectedCountry) {
    return State.getStatesOfCountry(country.alpha2Code);
  }

  stateProvinceHandler(change: any) {
    if(change.country) this.states = this.getStatesByCountry(change.country);
    else this.states.length = 0;
    if(this.states.length === 0) this.carrierForm.get('stateProvinceCode')?.clearValidators();
    else this.carrierForm.get('stateProvinceCode')?.setValidators([Validators.required]);
    this.carrierForm.get('stateProvinceCode')?.updateValueAndValidity();
  }

  matchPassValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (abstractControl: AbstractControl) => {
        const control = abstractControl.get(controlName);
        const matchingControl = abstractControl.get(matchingControlName);

        if (matchingControl!.errors && !matchingControl!.errors?.['confirmedValidator']) {
            return null;
        }

        if (control!.value !== matchingControl!.value) {
          const error = { confirmedValidator: 'Passwords do not match.' };
          matchingControl!.setErrors(error);
          return error;
        } else {
          matchingControl!.setErrors(null);
          return null;
        }
    }    
  }

  emitFormValidity() {
    this.formValid.emit({isValid:this.carrierForm.valid});
    if(this.carrierForm.valid) this.formValid.emit({isValid:this.carrierForm.valid, formData: this.carrierForm.value});
  }
}
