import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private http: HttpClient,
  ) {}

  private apiUrl = environment.apiUrl

  getDeletedUsers(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/manage/deleted_users`)
  }

  getDrivers(): Observable<any[]> {
    const httpParams = new HttpParams()
    return this.http.get<any[]>(`${this.apiUrl}/api/manage/drivers`, {params: httpParams})
  }

  getDispatchers(): Observable<any[]> {
    const httpParams = new HttpParams()
    return this.http.get<any[]>(
      `${this.apiUrl}/api/manage/dispatchers`, {params: httpParams}
    )
  }

  getSupervisors(): Observable<any[]> {
    const httpParams = new HttpParams()
    return this.http.get<any[]>(
      `${this.apiUrl}/api/manage/supervisors`, {params: httpParams}
    )
  }

  deleteDriver(driverID: string[]): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/drivers/${driverID}`);
  }

  restoreUsers(data: Array<any>): Observable<any> {

    const userTypeMap = new Map<string, string>();
    let arr: any = [];

    data.forEach(user => {
      userTypeMap.set(user.userId.toString(), user.userType);
    });
    let obj:any = Object.fromEntries(userTypeMap.entries());
    arr.push(obj)
    
    return this.http.post(this.apiUrl+'/api/manage/restore_users', arr);
  }
}
