import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterEventsBySearchKey'
})
export class FilterEventsBySearchKeyPipe implements PipeTransform {

  transform(events: any[], key: string): any[] {
    if (!events) return []
    if (!events.length || !key) return events

    return events.filter(el => el.LoadId.toLowerCase().includes(key))
  }

}
