import { Component, ViewChild } from '@angular/core';
import { SettingsComponent } from '../../../../interfaces/settings-component';
import { TimeLineCalendarComponent } from './time-line-calendar/time-line-calendar.component';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrl: './timeline.component.scss'
})
export class TimelineComponent {
  @ViewChild(TimeLineCalendarComponent) timeLineCalendarComponent!: TimeLineCalendarComponent
  settingsFormHeader!: SettingsComponent

  constructor(
  ) {
  }

  receiveSettings(event: any) {
    this.settingsFormHeader = event
  }

  selectedTab(event: number) {
    this.timeLineCalendarComponent.createLoad(event)
  }

  refreshResource() {
    this.timeLineCalendarComponent.refreshFunc();
  }
}
