<div class="create-container">
  <form [formGroup]="form" class="create-tonu-from">
    <div class="header-inputs">
      <mat-form-field appearance="outline" class="custom-multi-input-cont">
        <mat-label>Dispatcher</mat-label>
        <mat-select formControlName="dispatcher" placeholder="Dispatcher">
          @for (dispather of dispathers; track $index) {
          <mat-option [value]="dispather.fullName" id="allOption">{{dispather.fullName}}</mat-option>
          }
        </mat-select>
        <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
      </mat-form-field>

      <div style="width: 33%" class="block-inputs" #dropdownContainer>
        <label style="color: #667085; left: 18px;">Empty Miles</label>
        <input [readonly]="!milesInputDisable" type="text" placeholder="Empty Miles" formControlName="miles" />
        <div style="margin: 10px 0;">
          <ul *ngIf="dropdownOpen" class="dropdown-list">
            <li *ngFor="let option of emptyMilesOptions; let i = index" [attr.data-value]="option"
              (click)="selectOption(option)" class="dropdown-item">
              {{ option }}
            </li>
          </ul>
        </div>
        <mat-icon (click)="openDropDownEmptyMiles()" style="cursor: pointer; z-index: 1;" matSuffix><img
            src="../../../../../../../assets/svg/setting-input-img.svg" alt=""></mat-icon>
      </div>

      <mat-form-field appearance="outline" class="custom-multi-input-cont" style="width: 33%">
        <mat-label>Load Miles</mat-label>
        <input matInput formControlName="loadMiles" type="text" style="z-index: 20;" placeholder="Load Miles">
        <svg-icon src="assets/svg/search-icon.svg" class="input-icon-svg"></svg-icon>
      </mat-form-field>

    </div>
    <div class="container">
      <div class="details">
        <strong>Details</strong>

        <mat-form-field appearance="outline" class="custom-multi-input-cont">
          <mat-label>Load ID</mat-label>
          <input matInput formControlName="loadId" type="text" required
            [style]="form?.get('loadId')?.invalid ? 'border-color: red' : '' " placeholder="Load ID" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="custom-multi-input-cont">
          <mat-label>Carrier</mat-label>
          <mat-select (selectionChange)="getDriverByCarrierId($event.value)" matNativeControl formControlName="carrier"
            placeholder="Carrier">
            @for (carrier of carriers; track $index) {
            <mat-option [value]="carrier.id" id="allOption">{{carrier.name}}</mat-option>
            }
          </mat-select>
          <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="custom-multi-input-cont">
          <mat-label>Driver</mat-label>
          <mat-select (selectionChange)="setSelectedDriverIdInForm($event.value)" matNativeControl
            formControlName="driver" placeholder="Driver">
            @for (filteredDriver of filteredDrivers; track $index) {
            <mat-option [value]="filteredDriver.id" id="allOption">{{filteredDriver.driverFullName}}</mat-option>
            }
          </mat-select>
          <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
        </mat-form-field>

      </div>
      <div class="payment">
        <strong>Payment</strong>

        <mat-form-field appearance="outline" class="custom-multi-input-cont">
          <mat-label>Price</mat-label>
          <input matInput formControlName="price" type="text" placeholder="Price" />
        </mat-form-field>

        <div style="display: flex; align-items: center; justify-content: space-between;">
          <strong>Add Fee</strong>
          <button class="pick-btn" (click)="addFee()" type="button"><img
              src="../../../../../../../../assets/svg/add-circle-outline-img.svg" alt=""></button>
        </div>
        <div formArrayName="fee" [style.display]="fee.controls.length === 0 ? 'none' : 'block'">
          <div *ngFor="let item of fee.controls; let i = index;" [formGroupName]="i">
            <div style="display: flex; justify-content: space-between; align-items: center; gap: 16px;"
              *ngIf="openType">

              <mat-form-field appearance="outline" class="custom-multi-input-cont">
                <mat-label>Type</mat-label>
                <input matInput type="text" formControlName="feeName" [matAutocomplete]="type">
                <mat-autocomplete autoActiveFirstOption #type="matAutocomplete">
                  <mat-option *ngFor="let type of feeType" [value]="type.name" id="allOption">
                    {{ type.name }}
                  </mat-option>
                </mat-autocomplete>
                <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
              </mat-form-field>

              <mat-form-field appearance="outline" class="custom-multi-input-cont">
                <mat-label>Action</mat-label>
                <mat-select formControlName="addToGross" placeholder="Select Action">
                  <mat-option *ngFor="let key of actionFeeKeys; let i = index" id="allOption" [value]="key">
                    {{ actionsFee[key] }}
                  </mat-option>
                </mat-select>
                <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
              </mat-form-field>

              <mat-form-field appearance="outline" class="custom-multi-input-cont">
                <mat-label>Price</mat-label>
                <input matInput placeholder="0" formControlName="feePrice" type="text" placeholder="0" />
              </mat-form-field>


              <mat-icon style="height: 29px;" (click)="removeFee(i)" class="remove-icon"><img
                  src="../../../../../../../assets/svg/remove-img.svg"></mat-icon>
            </div>
          </div>
        </div>
        <div style="margin-bottom: 16px;" style="display: flex; justify-content: space-between;">
          <div *ngIf="!openType || fee.controls.length === 0" class="block-input-icon">
            <mat-icon disabled><img src="../../../../../../../assets/svg/error-outline-img.svg"></mat-icon>
            <input disabled type="text" placeholder="No fee yet" />
          </div>
        </div>
      </div>
    </div>
    <div class="container-information">
      <div class="pickup">
        <div style="display: flex; justify-content: space-between; align-items: baseline;">
          <strong>{{'Pickup Information '}}</strong>
          <button class="pick-btn" (click)="addPickup()" type="button"><img
              src="../../../../../../../../assets/svg/add-circle-outline-img.svg" alt="">{{'Add Pickup'}}</button>
        </div>
        <div>
          <div style="display: flex; justify-content: space-between;">
            <div style="display: flex; overflow: auto;">
              <div style="margin: 5px" *ngFor="let item of pickups.controls; let i = index" cdkDropList
                cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event,'pickups')">
                <button class="inactive-btn" cdkDrag (click)="changeSelectedTab(i, 'pickup')"
                  [ngClass]="{'active-btn': pIndex === i}" type="button">{{'Pickup '}}{{i + 1}}</button>
              </div>
            </div>
            <div style="display: flex;">
              <mat-icon class="remove-btn" *ngIf="pickups.controls.length > 1" (click)="removePickup(pIndex)"><img
                  src="../../../../../../../assets/svg/remove-img.svg"></mat-icon>
            </div>
          </div>
        </div>
        <div formArrayName="pickups">
          <div *ngFor="let item of pickups.controls; let i = index" [formGroupName]="i">
            <div *ngIf="pIndex === i">
              <div class="input-container">
                <mat-form-field appearance="outline" style="width: 100%; margin-bottom: 6px;"
                  class="custom-multi-input-cont">
                  <mat-label>Business Name</mat-label>
                  <input matInput formControlName="businessName" type="text" placeholder="Business Name" />
                </mat-form-field>
              </div>
              <div class="input-container">
                <div style="width: 100%; justify-content: space-between;"
                  [style]="item?.get('fullAddress')?.invalid ? 'border-color: red' : '' " class="block-input-icon">
                  <mat-icon><img src="../../../../../../../../assets/svg/location-on-img.svg" alt=""></mat-icon>
                  <div class="fullAddress-container">
                    <input formControlName="fullAddress" [id]="'google-search' + 'tp' + i" required
                      placeholder="Ex: 7128 Tavita St, Las Vegas, NV 89113, USA*" type="text"
                      (blur)="onBlurMethodPickup()" (input)="onInputChange(i)">
                  </div>
                  <div class="coordinates-wrappper">
                    <button class="coordinates-btn" (click)="addCoordinates('pickUp')" type="button">Add
                      coordinates</button>
                  </div>
                </div>
              </div>
              <div class="input-container">
                <div class="block-input-icon"
                  [style]="item?.get('dateScheduledFrom')?.invalid ? 'border-color: red' : ''">
                  <mat-datepicker-toggle matPrefix [for]="picker"><mat-icon class="date-picker-icon"
                      matDatepickerToggleIcon>date_range</mat-icon></mat-datepicker-toggle>
                  <input class="date-picker-input" formControlName="dateScheduledFrom" [matDatepicker]="picker"
                    placeholder="Pickup Date" required>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
                <div class="block-input-icon" [style]="item?.get('pickupTime')?.invalid ? 'border-color: red' : '' ">
                  <div class="centered"><img (click)="openTimePicker('pickupTime')"
                      src="../../../../../../../../assets/svg/access-time-img.svg" alt="icon" />
                    <input class="custom-time-input" formControlName="pickupTime" id="pickupTime" type="time"
                      placeholder="Pickup Time">
                  </div>
                </div>
                <div class="block-input-icon">
                  <div class="centered">
                    <img (click)="openTimePicker('pickUpDateScheduledTo')"
                      src="../../../../../../../../assets/svg/access-time-img.svg" alt="icon" />
                    <input class="custom-time-input" formControlName="dateScheduledTo" name="selectTime"
                      id="pickUpDateScheduledTo" type="time" placeholder="To">
                  </div>
                </div>
              </div>
              <div class="input-container">
                <mat-form-field appearance="outline" class="custom-multi-input-cont" style="width: 48%;">
                  <mat-label>Full Name</mat-label>
                  <input matInput formControlName="fullName" type="text" placeholder="Full Name" />
                </mat-form-field>

                <div style="width: 48%;" class="block-inputs">
                  <ngx-intl-tel-input style="width: 100%;" [preferredCountries]="[CountryISO.UnitedStates]"
                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="true" [selectedCountryISO]="CountryISO.UnitedStates" [separateDialCode]="true"
                    [maxLength]="15" [phoneValidation]="true" name="phone" formControlName="phone"></ngx-intl-tel-input>
                </div>
              </div>
              <div class="input-container">
                <mat-form-field appearance="outline" class="custom-multi-input-cont" style="width: 100%;">
                  <mat-label>Notes</mat-label>
                  <input matInput formControlName="notes" type="text" maxlength="85" placeholder="Add Notes">
                </mat-form-field>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="delivery-tonu">
        <div>
          TONU
        </div>
      </div>
    </div>
    <div class="footer-info">
      <div class="customer-broker-info">
        <mat-icon style="height: 29px;" (click)="clearBroker()" class="remove-btn remove-broker-icon"><img
            src="assets/svg/remove-img.svg"></mat-icon>
        <strong>Customer/Broker Information</strong>
        <div style="margin: 0;" class="input-container">
          <mat-form-field appearance="outline" class="custom-multi-input-cont full-width-input">
            <mat-label>Company</mat-label>
            <input matAutocompletePosition="below" matInput type="text" formControlName="company"
              [matAutocomplete]="auto" #inputAutoComplete (input)="brokerInput($event)" placeholder="Select Company">
            <mat-autocomplete #auto="matAutocomplete"
              (optionSelected)="addInformationAboutBrokerByName($event.option.value)">
              @for (broker of filteredBroker| async; track brokers) {
              <mat-option [value]="broker" id="allOption">{{broker.businessName}}</mat-option>
              }
            </mat-autocomplete>
            <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
          </mat-form-field>

          <mat-form-field appearance="outline" class="custom-multi-input-cont addres-input  full-width-input">
            <mat-label>Full Address</mat-label>
            <input style="width: calc(100% - 20px);" matInput [id]="'google-search' + 'bt'" type="text"
              formControlName="fullAddress" type="text" placeholder="Full Address">
            <svg-icon class="icon-prefix" src="assets/svg/location-on-img.svg"></svg-icon>
          </mat-form-field>
        </div>
        <div style="margin: 0;" class="input-container">
          <mat-form-field style="width: 49%;" appearance="outline" class="custom-multi-input-cont">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" type="text" placeholder="Name">
          </mat-form-field>

          <div style="width: 49%;" class="block-inputs">
            <ngx-intl-tel-input [preferredCountries]="[CountryISO.UnitedStates]" [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true" [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="true"
              [selectedCountryISO]="CountryISO.UnitedStates" [separateDialCode]="true" [maxLength]="15"
              [phoneValidation]="true" name="phone" formControlName="phone"></ngx-intl-tel-input>
          </div>
        </div>
        <div style="margin: 0;" class="input-container">
          <mat-form-field appearance="outline" class="custom-multi-input-cont email-input full-width-input">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" type="text" placeholder="Email">
            <svg-icon class="icon-prefix" src="assets/svg/email-img.svg"></svg-icon>
          </mat-form-field>
        </div>
      </div>
      <app-file-upload [files]="files" (filesChanged)="handleFileChange($event)"></app-file-upload>
    </div>
  </form>
  <div *ngIf="openMap" class="map-frame">
    <div class="search-container">
      <strong>Search coordinates</strong>
      <div style="display: flex; justify-content: space-around; gap: 12px;">
        <div class="block-input-icon">
          <input type="text" placeholder="Enter coordinates" [(ngModel)]="latitudeAndLongitude"
            (input)="manualInputOfCoordinates()">
          <mat-icon matSuffix><img src="../../../../../../../../assets/svg/search-img.svg"></mat-icon>
        </div>
        <div>
          <button class="save-crd-btn" (click)="addCoordinatesInForm()" type="button">Save</button>
        </div>
      </div>
    </div>
    <div (click)="setMarketForCoordinates()" class="full" id="map"></div>
  </div>
  <div *ngIf="openLocationModal" style="width: 100%; height: 100%;
      display: flex;justify-content: center;align-items: center; position: absolute; z-index: 23; top: 0;
      background: rgb(128, 128, 128, 0.5);">
    <app-location-empty-miles (addNewItem)="getEmitCoors($event)"
      (closeModalWithCoors)="closeModalWithCoors($event)"></app-location-empty-miles>
  </div>
</div>