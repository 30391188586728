import { BehaviorSubject } from 'rxjs';

export class Notification {
    constructor(
        public id: any,
        public type: NotificationType,
        public title: string,
        public message: string,
        public timeout?: any,
        public isOpen?: boolean
    ) {

    }
}

export enum NotificationType {
    success = 'Success',
    warning = 'Warning',
    error = 'Error',
    info = 'Info'
}