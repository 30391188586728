import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AUTH_API_URL } from '../app-injection-tokens';

@Injectable({
  providedIn: 'root'
})
export class BrokerService {

  constructor(
    private http: HttpClient,
    @Inject(AUTH_API_URL) private apiUrl: string
  ) { }

  getAllBrokers(): Promise<any> {
    return this.http.get<any>(this.apiUrl + '/api/brokers').toPromise()
  }

}
