<div class="create-note-container">
  <div *ngIf="editLoad" style="display: flex; justify-content: space-between; margin-bottom: 20px;">
    <div style="display: flex; justify-content: space-between; width: 100%;">
      <div style="display: flex; align-items: center; gap: 16px;">
        <div class="container-icon">
          <mat-icon><img src="../../../../../../../../assets/svg/notebook-srtoke-rounded 1.svg" alt=""></mat-icon>
        </div>
        <div>
          <strong class="view-delivery-strong">{{'Edit Note '}}</strong>
          <p>Lorem Ipsum is simply dummy text of the printing</p>
        </div>
      </div>
      <div>
        <mat-icon style="cursor: pointer" (click)="closeModal()">close</mat-icon>
      </div>
    </div>
  </div>
  <form [formGroup]="form">
    <div class="input-container">
      <div class="block-inputs date-picker"
        [style.border]="(form.get('start')?.valid || !form.get('start')?.touched) ? '' : '1px solid red'">
        <label>{{ "Start*" }}</label>
        <mat-datepicker-toggle matSuffix [for]="pickerStart">
          <mat-icon matPrefix matDatepickerToggleIcon>date_range</mat-icon>
        </mat-datepicker-toggle>
        <input formControlName="start" [matDatepicker]="pickerStart" placeholder="Start Date" />
        <mat-datepicker #pickerStart></mat-datepicker>
      </div>
      <div class="block-inputs date-picker"
        [style.border]="(form.get('end')?.valid || !form.get('end')?.touched) ? '' : '1px solid red'">
        <label>{{ "End*" }}</label>
        <mat-datepicker-toggle matSuffix [for]="pickerEnd">
          <mat-icon matPrefix matDatepickerToggleIcon>date_range</mat-icon>
        </mat-datepicker-toggle>
        <input formControlName="end" [matDatepicker]="pickerEnd" placeholder="End Date" />
        <mat-datepicker #pickerEnd></mat-datepicker>
      </div>
    </div>

    <div class="input-container">
      <div class="block-inputs"
        [style.border]="(form.get('reason')?.valid || !form.get('reason')?.touched) ? '' : '1px solid red'">
        <label>{{ "Reason*" }}</label>
        <mat-select #reasonSelect formControlName="reason" #selectElement matNativeControl placeholder="Reason"
          panelClass="dropDownNoteFixClass" (selectionChange)="reasonChange($event.value)">
          @for (reason of reasons; track $index) {
          <mat-option [value]="reason" id="allOption">{{reason}}</mat-option>
          }
        </mat-select>
        <svg-icon src="assets/svg/chevron-down.svg" (click)="reasonSelect.toggle()"></svg-icon>
      </div>

      <div class="block-inputs">
        <label>{{ "Full address" }}</label>
        <input style="width: calc(100% - 20px);" matInput [id]="'google-search' + 'n'" type="text"
          formControlName="fullAddress" type="text" placeholder="Full Address">
        <svg-icon class="icon-prefix" src="assets/svg/location-on-img.svg"></svg-icon>
      </div>
    </div>

    <div class="input-container">
      <div class="block-inputs">
        <label>{{ "Other Reason" }}</label>
        <input [readonly]="form.get('reason')?.value !== 'Other'" formControlName="otherReason" type="text"
          placeholder="Other reason" />
      </div>

      <div *ngIf="!editLoad" class="block-inputs"
        [style.border]="(form.get('driverId')?.valid || !form.get('driverId')?.touched) ? '' : '1px solid red'">
        <label>{{ "Driver*" }}</label>
        <mat-select #driverSelect formControlName="driverId" placeholder="Driver" matNativeControl
          panelClass="dropDownNoteFixClass">
          @for (driver of drivers; track $index) {
          <mat-option [value]="driver.id" id="allOption">{{driver.driverFullName}}</mat-option>
          }
        </mat-select>
        <svg-icon src="assets/svg/chevron-down.svg" (click)="driverSelect.toggle()"></svg-icon>
      </div>
    </div>
  </form>
  <div *ngIf="editLoad" class="footer-btn">
    <button class="remove-btn" (click)="deleteNote()">Delete</button>
    <button class="save-btn" (click)="updateNote()">Save</button>
  </div>
</div>