<div class="dialog-popup-wrapper" [ngClass]="{ 'hide-dsa': !data.props.dsa, 'user-warning' : data.props.icon === 'warning-icon' }" >
  <div class="dialog-popup-header-wrapper">
    <div class="icon-container">
      <svg-icon *ngIf="data.props?.icon.length > 0" [ngStyle]="data.props.iconStyle" [src]="iconPath" alt="icon"> </svg-icon>
    </div>

    <div class="dialog-popup-text">
      <h1 mat-dialog-title>{{ data.props.title }}</h1>
      <!-- <div class="dialog-popup-content">{{ data.props.text }} <span *ngIf="data.props.email" class="overflow"> {{data.props.email}} </span>
        <div *ngIf="data.props.tooltip"
          class="overflow"
          matTooltipClass="tooltip"
          [matTooltip]="data.props.tooltip"
          >
          {{data.props.tooltip}}
        </div>
      </div> -->
      <div class="dialog-popup-content">
        <div>
          {{ data.props.text }}
          <span *ngIf="data.props.email" class="overflow"> {{ data.props.email }} </span>
        </div>
        <div *ngIf="data.props.tooltip"
          class="overflow"
          matTooltipClass="tooltip"
          [matTooltip]="data.props.tooltip">
          {{ data.props.tooltip }}
        </div>
      </div>
      
    </div>
  </div>

  <div class="dialog-popup-footer-wrapper" >
    <div class="check-option-wrapper" *ngIf="data.props.dsa">
      <input id="dsa" class="custom-checkbox" type="checkbox" (change)="dsaChange($event)" [(ngModel)]="dsa"><label for="dsa"><span class="checkbox-label-style">Don’t show again</span></label>
    </div>
    <div mat-dialog-actions class="dialog-popup-footer-btns" [style.width]="(!data.props.button1Text || data.props.dsa) ? '45% !important' : '90% !important'">
      <button *ngIf="data.props.button1Text" [ngStyle]="data.props.btn1Style" class="global-btn" mat-button (click)="onButton1Click()">
        <span class="btn-text">{{ data.props.button1Text }}</span>
      </button>
      <button [ngStyle]="data.props.btn2Style" class="global-btn" mat-button (click)="onButton2Click()">
        <span class="btn-text">{{ data.props.button2Text }}</span>
      </button>
    </div>
  </div>
</div>