<div class="split-container">
  <strong style="margin-bottom: 16px;">Split Driver</strong>
  <form [formGroup]="form">
    <div class="input-container">
      <mat-form-field appearance="outline" class="custom-multi-input-cont full-width-input">
        <mat-label>Select Carrier</mat-label>
        <input (input)="carrierInput($event.target.value)" matInput type="text" formControlName="carrier"
          [matAutocomplete]="autoCarrier" #inputAutoComplete placeholder="Select Carrier">
        <mat-autocomplete #autoCarrier="matAutocomplete" (optionSelected)="filterDriverByCarrierId($event.option.value)"
          [displayWith]="displayFnCarrier.bind(this)">
          @for (carrier of filteredCarriers| async; track carriers) {
          <mat-option [value]="carrier.id" id="allOption">{{carrier.name}}</mat-option>
          }
        </mat-autocomplete>
        <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" class="custom-multi-input-cont full-width-input">
        <mat-label>Select Driver</mat-label>
        <input (input)="driverInput($event.target.value)" matInput type="text" formControlName="driver"
          [matAutocomplete]="autoDriver" #inputAutoComplete placeholder="Select Driver">
        <mat-autocomplete #autoDriver="matAutocomplete" (optionSelected)="addValueInFormByDriverId($event.option.value)"
          [displayWith]="displayFnDriver.bind(this)">
          @for (driver of filteredDrivers | async; track allDrivers) {
          <mat-option [value]="driver.id">{{driver.driverFullName}}</mat-option>
          }
        </mat-autocomplete>
        <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
      </mat-form-field>
    </div>
    <div class="input-container">
      <div class="block-inputs">
        <label>Split Price</label>
        <input type="number" formControlName="driverPrice" placeholder="Split Price">
      </div>
      <div class="block-inputs">
        <label>Split Miles</label>
        <input type="number" formControlName="miles" placeholder="Split Miles">
      </div>
    </div>
    <div class="input-container">
      <div style="width: 100%;" class="block-input-icon">
        <label>Location</label>
        <mat-icon><img src="../../../../../../../../../../assets/svg/location-on-img.svg" alt=""></mat-icon>
        <input formControlName="fullAddress" [id]="'google-search' + 's'" required
          placeholder="Ex: 7128 Tavita St, Las Vegas, NV 89113, USA*" type="text">
      </div>
    </div>
    <div class="input-container">
      <div style="width: 100%;" class="block-input-icon">
        <label>Comments</label>
        <mat-icon><img src="../../../../../../../../../../assets/svg/group-comment-bank-img.svg" alt=""></mat-icon>
        <input placeholder="Comments (to manually introduce split trip reasons)" type="text"
          formControlName="driverComments">
      </div>
    </div>
    <strong>Split Dispatcher</strong>
    <div style="margin-top: 16px;" class="input-container">
      <div class="block-input-icon">
        <label>Company</label>
        <input type="text" formControlName="company">
      </div>

      <mat-form-field appearance="outline" class="custom-multi-input-cont full-width-input">
        <mat-label>Select Dispatcher</mat-label>
        <input (input)="dispInput($event.target.value)" matInput type="text" formControlName="dispatcher"
          [matAutocomplete]="autoDisp" #inputAutoComplete placeholder="Select Dispatcher">
        <mat-autocomplete #autoDisp="matAutocomplete" (optionSelected)="filterDriverByDispatcherId($event.option.value)"
          [displayWith]="displayFnDispatcher.bind(this)">
          @for (disp of filteredDispatchers | async; track dispathers) {
          <mat-option [value]="disp.id">{{disp.fullName}}</mat-option>
          }
        </mat-autocomplete>
        <svg-icon src="assets/svg/chevron-down.svg" class="input-icon-svg"></svg-icon>
      </mat-form-field>

      <div class="block-inputs">
        <label>Split Price</label>
        <input type="text" formControlName="splitDispatcherPrice" placeholder="Split Price">
      </div>
    </div>
    <div class="input-container">
      <div style="width: 100%;" class="block-input-icon">
        <label>Comments</label>
        <mat-icon><img src="../../../../../../../../../../assets/svg/group-comment-bank-img.svg" alt=""></mat-icon>
        <input placeholder="Comments (to manually introduce split trip reasons)" type="text"
          formControlName="dispatcherComments">
      </div>
    </div>
  </form>
  <div class="footer-btn">
    <button style="width: 100%;" class="save-btn" (click)="createSplit()">{{'Create Split'}}</button>
  </div>
</div>