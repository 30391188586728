import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class TooltipComponent {

  @Input() text!: string;
  @Input() content!: TemplateRef<any>;
}