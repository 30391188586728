
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from './popover.directive';
import { Directive, Input, TemplateRef, HostListener, OnInit, ElementRef, ComponentRef } from '@angular/core';
import {
  OverlayRef,
  Overlay,
  OverlayPositionBuilder
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TooltipComponent } from '../components/popover/popover.component';

@NgModule({
  declarations: [TooltipDirective, TooltipComponent],  // Declare it once here
  imports: [CommonModule],           // Import CommonModule for Angular directives
  exports: [TooltipDirective],       // Export the directive to use it in other modules
})
export class TooltipModule {}
