import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../../../../services/dialog/dialog.service';
import { Item } from '../../../../../../interfaces/item';

@Component({
  selector: 'app-target-popup',
  templateUrl: './target-popup.component.html',
  styleUrl: './target-popup.component.scss'
})
export class TargetPopupComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TargetPopupComponent>,
  ) {
    this.targetForm = this.fb.group({
      targetType: [null, Validators.required],
      targetValue: [, Validators.required],
      targetIntervalType: [null, Validators.required],
    });
  }

  public targetForm: FormGroup;
  public isTargetSectionVisible: boolean = false;
  public targetTypeArr: Array<any> = [{ code: 'gross', name: 'Gross' }, { code: 'miles', name: 'Miles' }]
  public targetIntervalTypeArr: Array<any> = [{ code: 'day', name: 'Per Day' }, { code: 'week', name: 'Per Week' }, { code: 'month', name: 'Per Month' }]

  ngOnInit(): void {
    this.targetForm.patchValue({
      targetType: 'gross'
    })
  }

  applyBtnClick() {
    this.data.totalbyData.targets.push(this.targetForm.value);
    this.isTargetSectionVisible = false;
  }

  targetInput(event: any) {
    if (event.target.value.length > 5) {
      event.target.value = event.target.value.slice(0, 5);
    }
  }

  removeItem() {
    this.data.totalbyData.targets.pop();
  }

  getMainBadgeClass(word: string): string {
    switch (word.toLocaleLowerCase()) {
      case 'miles': return 'blue-badge';
      case 'gross': return 'green-badge';
      default: return '';
    }
  }

  getTargetBadgeClass(word: string): string {
    switch (word.toLocaleLowerCase()) {
      case 'day': return 'orange-badge';
      case 'week': return 'blue-badge';
      case 'month': return 'white-badge';
      default: return '';
    }
  }

  isTargetTypeLabelVisible(): boolean {
    return !this.targetForm.get('targetType')?.value || !this.targetTypeArr.some(s => s.code === this.targetForm.get('targetType')?.value);
  }

  isTargetIntervalLabelVisible(): boolean {
    return !this.targetForm.get('targetIntervalType')?.value || !this.targetIntervalTypeArr.some(s => s.code === this.targetForm.get('targetIntervalType')?.value);
  }

  closeBtnClick() {
    this.dialogService.closeDialog(this.dialogRef, undefined);
  }

  okBtnClick() {
    this.dialogService.closeDialog(this.dialogRef, {
      data: this.data.totalbyData.targets
    });
  }
}
