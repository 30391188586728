export const invalid = [
    {
    start: '00:00',
    end: '24:00',
    title: '',
    recurring: {
      repeat: 'weekly',
      weekDays: 'SA,SU'
    },
    cssClass: 'md-lunch-break-class mbsc-flex',
  }
]
