import { Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, ValidatorFn, AbstractControl, FormControl, AsyncValidatorFn } from '@angular/forms';
import { Country as SelectedCountry } from '@angular-material-extensions/select-country';
import { IState, State } from 'country-state-city';
import {
  CountryISO,
  SearchCountryField,
  PhoneNumberFormat
} from "ngx-intl-tel-input-gg";
import { LoadsService } from '../../../../../../services/loads/loads.service';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, Observable, of, startWith, switchMap, tap } from 'rxjs';
import { GeometryService } from '../../../../../../services/geometry/geometry.service';

@Component({
  selector: 'app-edit-driver-form',
  templateUrl: './edit-driver-form.component.html',
  styleUrl: './edit-driver-form.component.scss'
})
export class EditDriverFormComponent implements OnInit{
  @Output() formValid = new EventEmitter<any>();
  @Output() addCoDriver = new EventEmitter<any>();
  @Output() editCodriver = new EventEmitter<any>();
  @Input() row!: any;
  @ContentChild('attachmentTemplate') attachmentTemplate?: TemplateRef<any>;
  @ViewChild('customCarrier') customCarrierInput!: ElementRef;
  @ViewChild('customVehicle') customVehicleInput!: ElementRef;
  @ViewChild('customTrailer') customTrailerInput!: ElementRef;
  
  constructor(
    private formBuilder: FormBuilder,
    private loadService: LoadsService,
    private geometryService: GeometryService
  ) { }

  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom]
  public fieldTextType: string = 'password';
  public driverForm!: FormGroup;
  public statesL: Array<IState> = new Array<IState>();
  public statesH: Array<IState> = new Array<IState>();
  public dispatchers: Array<any> = new Array<any>();
  public carriers: Array<any> = new Array<any>();
  public vehicles: Array<any> = new Array<any>();
  public trailers: Array<any> = new Array<any>();
  public filteredTrailers!: Observable<any[]>; 
  public filteredVehicles!: Observable<any[]>;
  public customCarrierInputVisible: boolean = false;
  public customVehicleInputVisible: boolean = false;
  public customTrailerInputVisible: boolean = false;
  public customCarrier: any;
  public customCarrierControl!: FormControl;  // Define a FormControl for custom input
  public customVehicleControl!: FormControl;  // Define a FormControl for custom input
  public customTrailerControl!: FormControl;  // Define a FormControl for custom input
  private initialEmail: string = '';
  private masterDriverEmail: string = '';
  public isEmailLoading: boolean = false;

  ngOnInit(): void {
    
    if(this.row.masterDriverData) this.row = {...this.row.masterDriverData, ...this.row}
    // console.log(this.row);

    this.initializeForm(this.row.tempCreateDriver);
    Promise.all([
      this.loadService.getAllDispatchers(),
      this.loadService.getAllCarriers(),
      this.loadService.getAllVehicles(),
      this.loadService.getAllTrailers(),
    ]).then(all=>{
      this.dispatchers = all[0];
      this.carriers = all[1];
      this.vehicles = all[2];
      this.trailers = all[3];
      this.initializeAutocomplete();
      if(!this.row.tempCreateDriver) this.setFormData();   
      if(this.row.tempCreateDriver && !this.row.coDriverVisible) this.masterDriverEmail = this.row.email;
      if(this.row.masterDriverData) this.setCoDriverData();
      this.driverForm.markAllAsTouched();
      setTimeout(() => {
        this.createAddress()
        this.addEmailAsyncValid();
      });
    });
  }

  initializeForm(isRequired: boolean): void {
    this.customCarrierControl = new FormControl('');
    this.customVehicleControl = new FormControl('');
    this.customTrailerControl = new FormControl('');

    if(isRequired) {
      this.driverForm = this.formBuilder.group({
        carrierId: ['', Validators.required],
        dispatcherId: ['', Validators.required],
        firstName: ['', [Validators.required]],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', [Validators.required]],
        vehicleId: ['', [Validators.required]],
        trailerId: ['', [Validators.required]],
        driverLicenseNumber: [''],
        driverLicenseCountryCode: [{alpha2Code: "US"}],
        driverLicenseStateCode: [''],
        fullAddress: [''],
        street: [''],
        countryCode: [{alpha2Code: "US"}],
        city: [''],
        stateCode: [''],
        zipCode: [''],
        password: new FormControl(null, [
          (c: AbstractControl) => Validators.required(c),
          Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*]).{6,}$/)
        ]),
        password_confirm: new FormControl(null, [
          (c: AbstractControl) => Validators.required(c),
          Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*]).{6,}$/)
        ]),
        latitude: [''],
        longitude: [''],
      });

      this.driverForm.addValidators(
        this.matchPassValidator('password', 'password_confirm')
      );

    } else {
      this.driverForm = this.formBuilder.group({
        carrierId: ['', Validators.required],
        dispatcherId: ['', Validators.required],
        firstName: ['', [Validators.required]],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', [Validators.required]],
        vehicleId: ['', [Validators.required]],
        trailerId: ['', [Validators.required]],
        fullAddress: [''],
        driverLicenseNumber: [''],
        driverLicenseCountryCode: [{alpha2Code: "US"}],
        driverLicenseStateCode: [''],
        street: [''],
        countryCode: [{alpha2Code: "US"}],
        stateCode: [''],
        city: [''],
        zipCode: [''],
        latitude: [''],
        longitude: [''],
      });
    }

    this.driverForm.valueChanges.subscribe((change) => {
      if(change.driverLicenseCountryCode) this.statesL = this.getStatesByCountry(change.driverLicenseCountryCode);
      else this.statesL.length = 0;
      // if(change.countryCode){
      //   this.statesH = this.getStatesByCountry(change.countryCode);
      // }
      // else this.statesH.length = 0;
      this.emitFormValidity();
    });

    this.driverForm.controls['countryCode'].valueChanges.subscribe((change)=>{
      if(change) {
        this.statesH = this.getStatesByCountry(change);
        this.driverForm.patchValue({stateCode: this.driverForm.get('stateCode')?.value});//this is for setup statecode in form
      } 
      else this.statesH = new Array<IState>();;
    });
  }

  setFormData() {
    this.driverForm.setValue({
      firstName:  this.row.firstName,
      lastName:  this.row.lastName,
      email: this.row.email,
      phone: this.row.phone || 0,
      driverLicenseNumber:  this.row.driverLicenseNumber,
      driverLicenseCountryCode: this.row.driverLicenseCountryCode.length > 0 ? {alpha2Code:this.row.driverLicenseCountryCode} : {alpha2Code:"US"},
      driverLicenseStateCode: this.row.driverLicenseStateCode,
      fullAddress: this.row?.fullAddress || '',
      street:  this.row.street,
      countryCode: this.row.countryCode.length > 0 ? {alpha2Code:this.row.countryCode} : {alpha2Code:"US"},
      stateCode: this.row.stateProvinceCode,
      city:  this.row.city,
      zipCode:  this.row.zipCode,
      carrierId: this.row.carrierId,
      dispatcherId: this.row.dispatcherId,
      vehicleId: this.row.vehicleId,
      trailerId: this.row.trailerId,
      latitude: this.row.addressLatitude,
      longitude: this.row.addressLongitude
    });
    this.driverForm.markAllAsTouched();
    this.initialEmail = this.row.email;
  }

  addEmailAsyncValid() {
    this.driverForm.get('email')?.valueChanges.pipe(
      debounceTime(1000), // Wait 1000ms after the user stops typing
      distinctUntilChanged(), // Only emit distinct values
      filter(value => !!value && value.length !== 0),
      filter(value => value !== this.initialEmail),
      filter(value => !/[^a-zA-Z0-9]$/.test(value)), // Proceed only if last character is not a special symbol
      switchMap(value => {
        if (this.driverForm.get('email')?.invalid) {
          return of(null); // Skip async validation if already invalid
        }
        this.isEmailLoading = true
        return this.loadService.verifyEmail(value).pipe(
          tap(response => {
            if (response) {
              this.driverForm.get('email')?.setErrors({ emailTaken: true });
            }
          }),
          catchError(() => {
            this.driverForm.get('email')?.setErrors(null);
            return of(null);
          }),
          finalize(() => {
            if (!this.driverForm.get('email')?.errors?.['emailTaken'] && this.masterDriverEmail.toLowerCase() === value.toLowerCase()) {
              this.driverForm.get('email')?.setErrors({ emailTaken: true });
            }            
            this.isEmailLoading = false
          })
        );
      })
    ).subscribe();
  }
  
  initializeAutocomplete() {
    this.filteredTrailers = this.driverForm.controls['trailerId'].valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? this._filterTrailers(value) : this.trailers)
    );
    this.filteredVehicles = this.driverForm.controls['vehicleId'].valueChanges.pipe(
      startWith(''),
      map(value => typeof value === 'string' ? this._filterVehicles(value) : this.vehicles)
    );
  }

  private _filterTrailers(value: string): any[] {   
    const filterValue = value.toLowerCase();
    return this.trailers.filter(trailer => trailer.trailerUnit.toLowerCase().includes(filterValue));
  }

  private _filterVehicles(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.vehicles.filter(vehicle => vehicle.vehicleUnit.toLowerCase().includes(filterValue));
  }

  trailerDisplayFn(trailerID: number) {
    if (trailerID == null) return '';
  
    const trailer = this.trailers?.find(t => t.id === trailerID);
    return trailer ? trailer.trailerUnit : '';
  }

  vehicleDisplayFn(vehicleId: number) {
    if (vehicleId == null) return '';

    const vehicle = this.vehicles?.find(v => v.id === vehicleId);
    return vehicle ? vehicle.vehicleUnit : '';
  }

  setCoDriverData() {
    if(this.row.newCarrierName) {
      this.carriers.push({id: 0, name: this.row.newCarrierName});
      this.driverForm.get('carrierId')?.setValue(0);
    }
    if(this.row.newVehicleUnit) {
      this.vehicles.push({id: 0, vehicleUnit: this.row.newVehicleUnit});
      this.driverForm.get('vehicleId')?.setValue(0);
    }
    if(this.row.newTrailerUnit) {
      this.trailers.push({id: 0, trailerUnit: this.row.newTrailerUnit});
      this.driverForm.get('trailerId')?.setValue(0);
    }
    this.driverForm.patchValue({
      carrierId: this.row.carrierId,
      dispatcherId: this.row.dispatcherId,
      vehicleId: this.row.vehicleId,
      trailerId: this.row.trailerId,
    });
    this.driverForm.get('carrierId')?.disable();
    this.driverForm.get('dispatcherId')?.disable();
    this.driverForm.get('vehicleId')?.disable();
    this.driverForm.get('trailerId')?.disable();
  }

  onCarrierSelect(event: any): void {
    if (event.value === 'custom') this.enableCarrierCustomInput();
  }

  onVehicleSelect(event: any) {
    if (event.option.value === 'custom') this.enableVehicleCustomInput();
  }

  onTrailerSelect(event: any) {
    if (event.option.value === 'custom') this.enableTrailerCustomInput();
  }

  // Show custom input field
  enableCarrierCustomInput(): void {
    this.customCarrierInputVisible = true;
    setTimeout(() => {
      this.customCarrierInput.nativeElement.focus();
    }, 100);
  }
  
  enableVehicleCustomInput(): void {
    this.customVehicleInputVisible = true;
    setTimeout(() => {
      this.customVehicleInput.nativeElement.focus();
    }, 100);
  }
  
  enableTrailerCustomInput(): void {
    this.customTrailerInputVisible = true;
    setTimeout(() => {
      this.customTrailerInput.nativeElement.focus();
    }, 100);
  }

  addCustomCarrier(): void {
    if (this.customCarrierControl.value && this.customCarrierControl.value.trim().length > 0) {
      const newCarrier = { id: 0, name: this.customCarrierControl.value };
      this.carriers.push(newCarrier);
      this.driverForm.get('carrierId')?.setValue(newCarrier.id);
    } else {
      this.driverForm.get('carrierId')?.setValue(null);
    }
    this.customCarrierInputVisible = false;
    this.customCarrierControl.reset();
    this.driverForm.get('carrierId')?.updateValueAndValidity();
  }

  addcustomVehicle(): void {
    if (this.customVehicleControl.value && this.customVehicleControl.value.trim().length > 0) {
      const newVehicle = { id: 0, vehicleUnit: this.customVehicleControl.value };
      this.vehicles.push(newVehicle);
      this.driverForm.get('vehicleId')?.setValue(newVehicle.id);
    } else {
      this.driverForm.get('vehicleId')?.setValue(null);
    }
    this.customVehicleInputVisible = false;
    this.customVehicleControl.reset();
    this.driverForm.get('vehicleId')?.updateValueAndValidity();
  }

  addcustomTrailer(): void {
    if (this.customTrailerControl.value && this.customTrailerControl.value.trim().length > 0) {
      const newTrailer = { id: 0, trailerUnit: this.customTrailerControl.value };
      this.trailers.push(newTrailer);
      this.driverForm.get('trailerId')?.setValue(newTrailer.id);
    } else {
      this.driverForm.get('trailerId')?.setValue(null);
    }
    this.customTrailerInputVisible = false;
    this.customTrailerControl.reset();
    this.driverForm.get('trailerId')?.updateValueAndValidity();
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }

  addCoDriverClick(data:any) {
    if(!this.driverForm.valid) return;
    // console.log(data);
    // data.coDriverId = 999;
    this.addCoDriver.emit(this.driverForm.value);
  }

  editCodriverClick(data:any) {
    if(!this.driverForm.valid) return;
    // console.log(data);
    // data.coDriverId = 999;
    this.editCodriver.emit(this.driverForm.value);
  }
  
  togglePassType() {
    this.fieldTextType = this.fieldTextType === 'password' ? 'text' : 'password';    
  }

  getStatesByCountry(country: SelectedCountry):IState[] {
    return State.getStatesOfCountry(country.alpha2Code);
  }

  matchPassValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (abstractControl: AbstractControl) => {
        const control = abstractControl.get(controlName);
        const matchingControl = abstractControl.get(matchingControlName);

        if (control!.errors && !matchingControl!.errors?.['confirmedValidator']) {
            return null;
        }

        if ((control?.value !== matchingControl?.value) && control?.value && !matchingControl!.errors?.['confirmedValidator']) {
          const error = { confirmedValidator: 'Passwords do not match.' };
          matchingControl!.setErrors(error);
          return error;
        } else {
          matchingControl!.setErrors(null);
          return null;
        }
    }    
  }

  isTrailerTypeLabelVisible(): boolean {
    return !this.driverForm.get('trailerId')?.value || !this.trailers.some(s=>s.id === this.driverForm.get('trailerId')?.value);
  }

  isVehicleTypeLabelVisible(): boolean {
    return !this.driverForm.get('vehicleId')?.value || !this.vehicles.some(s=>s.id === this.driverForm.get('vehicleId')?.value);
  }

  emitFormValidity() {
    const result = this.updateFormEntities();
    let formData = {...this.driverForm.value, ...result};
    this.formValid.emit({isValid:this.driverForm.valid, formData: formData});
  }

  getEntityFieldById(id: number, entityList: any[], field: string): string | null {
    if (id !== 0) return null;
    const foundEntity = entityList.find(entity => entity.id === id);
    return foundEntity ? foundEntity[field] : null;
  }
  
  updateFormEntities() {
    const carrierID = this.driverForm.get("carrierId")?.value;
    const newCarrierName = this.getEntityFieldById(carrierID, this.carriers, 'name');
  
    const trailerID = this.driverForm.get("trailerId")?.value;
    const newTrailerUnit = this.getEntityFieldById(trailerID, this.trailers, 'trailerUnit');
  
    const vehicleID = this.driverForm.get("vehicleId")?.value;
    const newVehicleUnit = this.getEntityFieldById(vehicleID, this.vehicles, 'vehicleUnit');
  
    return { newCarrierName, newTrailerUnit, newVehicleUnit }
  }

  createAddress() {
    this.geometryService.findAddress('google-search' + this.row.gSearchID, this.driverForm, true);
    this.driverForm.updateValueAndValidity();
  }

  fullAdressFocus() {
    const pacContainer = document.querySelector('.pac-container') as HTMLElement;
    if (!pacContainer) return;
    pacContainer.style.transform = 'translateX(-5px)';
    pacContainer.style.display = 'block !important';    
  }
  
}
