import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { GeometryService } from '../../../../../../../../../services/geometry/geometry.service';
import { LoadsService } from '../../../../../../../../../services/loads/loads.service';
import { Split } from '../../../../../../../../../interfaces/loads/split';
import { DialogRef } from '@angular/cdk/dialog';
import { map, Observable, of, startWith } from 'rxjs';

@Component({
  selector: 'app-edit-split',
  templateUrl: './edit-split.component.html',
  styleUrls: ['./edit-split.component.scss']
})
export class EditSplitComponent implements OnInit {
  @Input() selectedLoad: any;
  @Input() dispathers: any;
  @Input() carriers: any[] = [];
  @Input() allDrivers: any[] = [];

  filteredDrivers!: Observable<any[]>;
  filteredDispatchers!: Observable<any[]>;
  filteredCarriers!: Observable<any[]>;

  drivers: any[] = [];

  disableCarrier: boolean = false;
  disableDispatch: boolean = false;
  form!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private geometryService: GeometryService,
    private loadService: LoadsService,
    public dialogRef: DialogRef<string>,
  ) { }

  ngOnInit() {
    this.drivers = JSON.parse(JSON.stringify(this.allDrivers))
    this.initForm();

    this.updateCompanyOnDispatcherChange(this.form.get('dispatcher')?.value);
    this.form.get('company')?.disable();

    this.form.get('dispatcher')?.valueChanges.subscribe((dispatcherId: any) => {
      this.updateCompanyOnDispatcherChange(dispatcherId);
    });

    this.filteredCarriers = this.filteredCarrier();
    this.filteredDrivers = this.filteredDriver();
    this.filteredDispatchers = this.filteredDispatcher();

    if (this.selectedLoad.splitLoad) {
      this.addValueInFormByDriverId(this.selectedLoad.splitLoad.driverId)
    }
    setTimeout(() => this.createAddress());
  }

  filteredCarrier(): any {
    let filteredCarrier = this.form.get('carrier')?.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : '';
        return name ? this._filterCarrier(name as string) : this.carriers;
      })
    );

    return filteredCarrier;
  }

  filteredDriver(): any {
    let filteredDriver = this.form.get('driver')?.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.driverFullName;
        return name ? this._filterDriver(name as string) : this.drivers.slice();
      })
    );

    return filteredDriver;
  }

  filteredDispatcher(): any {
    if (!this.form.get('dispatcher')) {
      return of([]);
    }
    let filteredDispatcher = this.form.get('dispatcher')?.valueChanges.pipe(
      startWith(''),
      map((value) => {
        console.log("🚀 ~ EditSplitComponent ~ map ~ value:", value)
        const name = typeof value === 'string' ? value : value?.fullName;
        return name ? this._filterDispatcher(name as string) : this.dispathers.slice();
      })
    );

    return filteredDispatcher;
  }

  initForm() {
    this.form = this.fb.group({
      carrier: new FormControl(null),
      company: new FormControl(''),
      driver: new FormControl(this.selectedLoad?.splitLoad?.driverId ? this.selectedLoad?.splitLoad?.driverId : 0),
      driverPrice: new FormControl(this.selectedLoad?.splitLoad?.splitDriverPrice ? this.selectedLoad?.splitLoad?.splitDriverPrice : null),
      miles: new FormControl(this.selectedLoad?.splitLoad?.splitMiles ? this.selectedLoad?.splitLoad?.splitMiles : ''),
      fullAddress: new FormControl(this.selectedLoad?.splitLoad?.fullAddress ? this.selectedLoad?.splitLoad?.fullAddress : ''),
      street: new FormControl(''),
      city: new FormControl(''),
      stateCode: new FormControl(''),
      zipCode: new FormControl(''),
      countryCode: new FormControl(''),
      longitude: new FormControl(''),
      latitude: new FormControl(),
      driverComments: new FormControl(this.selectedLoad?.splitLoad?.driverComments ? this.selectedLoad?.splitLoad?.driverComments : ''),
      dispatcher: new FormControl(this.selectedLoad?.splitLoad?.dispatcherId ? this.selectedLoad?.splitLoad?.dispatcherId : 0),
      splitDispatcherPrice: new FormControl(this.selectedLoad?.splitLoad?.splitDispatcherPrice ? this.selectedLoad?.splitLoad?.splitDispatcherPrice : null),
      dispatcherComments: new FormControl(this.selectedLoad?.splitLoad?.dispatcherComments ? this.selectedLoad?.splitLoad?.dispatcherComments : ''),
      loadId: new FormControl(this.selectedLoad.loadId),
    })
  }

  updateCompanyOnDispatcherChange(dispatcherId: any) {
    if (dispatcherId) {
      this.dispathers.forEach((disp: any) => {
        if (disp.id === dispatcherId) {
          this.form.get('company')?.setValue(disp.dispatchCompanyName);
        }
      });
    }
  }

  createAddress() {
    this.geometryService.findAddress('google-search' + 's', this.form);
  }

  createSplit() {
    let split: Split = this.formToSplit();
    this.loadService.createSplit(split).subscribe(resp => {
      if (resp) {
        this.dialogRef.close(resp);
      }
    })
  }

  formToSplit() {
    let split: Split = {
      companyId: this.form.get('carrier')?.value,
      driverId: this.form.get('driver')?.value,
      loadId: this.form.get('loadId')?.value,
      splitDriverPrice: this.form.get('driverPrice')?.value,
      splitMiles: this.form.get('miles')?.value,
      fullAddress: this.form.get('fullAddress')?.value,
      driverComments: this.form.get('driverComments')?.value,
      dispatcherId: this.form.get('dispatcher')?.value,
      splitDispatcherPrice: this.form.get('splitDispatcherPrice')?.value,
      dispatcherComments: this.form.get('dispatcherComments')?.value
    }

    return split
  }

  filterDriverByCarrierId(carrierId: any) {
    if (carrierId) {
      this.filteredDrivers = of(this.allDrivers.filter(value => value.carrierId === carrierId));
    } else {
      this.filteredDrivers = of(this.allDrivers);
    }
    this.form.get('driver')?.setValue('')
    this.form.get('dispatcher')?.disable();
  }

  addValueInFormByDriverId(driverId: any) {
    for (let driver of this.drivers) {
      if (driver.id === driverId) {
        this.form.get('carrier')?.setValue(driver.carrierId)
        this.filteredDispatchers = of(this.dispathers.filter((disp: any) => disp.id === driver.dispatcherId))
        if (driver.dispatcherId !== this.form.get('dispatcher')?.value || driver.dispatcherFullName.toLowerCase() !== this.form.get('dispatcher')?.value.toLowerCase()) {
          this.form.get('dispatcher')?.setValue(0)
        }
        this.form.get('dispatcher')?.enable();
        this.form.get('carrier')?.enable();
      }
    }
  }

  driverInput(event: any) {
    if (!event) {
      this.filteredDispatchers = of(this.dispathers);
      this.filteredCarriers = of(this.carriers);
      if (!this.form.get('dispatcher')?.value && !this.form.get('carrier')) {
        this.filteredDrivers = of(this.allDrivers);
      }
      this.form.get('carrier')?.setValue('');
      this.form.get('driver')?.setValue(0)
    }
  }

  carrierInput(event: any) {
    if (!event) {
      this.filteredDrivers = of(this.allDrivers)
      this.filteredDispatchers = of(this.dispathers);
      this.form.get('carrier')?.setValue('');
      this.form.get('dispatcher')?.enable();
    }
  }

  dispInput(event: any) {
    console.log("🚀 ~ EditSplitComponent ~ dispInput ~ event:", event)
    if (!event) {
      this.form.get('dispatcher')?.setValue(0)
      this.form.get('carrier')?.enable()
      if (!this.form.get('driver')?.value) {
        this.filteredDispatchers = of(this.dispathers)
      }
      console.log(this.form.get('carrier')?.value);

      if (this.form.get('carrier')?.value) {
        this.filteredDrivers = of(this.allDrivers.filter(value => value.carrierId === this.form.get('carrier')?.value))
      }
    }
  }

  filterDriverByDispatcherId(dispatcherId: any) {
    if (dispatcherId) {
      this.filteredDrivers = of(this.allDrivers.filter(value => value.dispatcherId === dispatcherId))
    } else {
      this.filteredDrivers = of(this.allDrivers)
    }
    this.form.get('carrier')?.disable()
  }

  displayFnCarrier(carrierId: any): string {
    const carrier = this.carriers?.find(c => c.id === carrierId);
    return carrier ? carrier.name : '';
  }

  displayFnDriver(driverId: any) {
    let driver = this.drivers?.find(d => d.id === driverId);
    return driver ? driver.driverFullName : '';
  }

  displayFnDispatcher(dispatcherId: any): string {
    let disp = this.dispathers.find((value: any) => value.id === dispatcherId)
    return disp ? disp.fullName : '';
  }

  private _filterCarrier(name: string): any {
    const filterValue = name.toLowerCase();
    return this.carriers.filter(carrier => carrier.name.toLowerCase().includes(filterValue));

  }

  private _filterDriver(name: string): any {
    const filterValue = name.toLowerCase();
    return this.drivers.filter((driver) => driver.driverFullName.toLowerCase().includes(filterValue));
  }

  private _filterDispatcher(name: string): any {
    const filterValue = name.toLowerCase();
    return this.dispathers.filter((disp: any) => disp.fullName.toLowerCase().includes(filterValue));
  }
}
