<div class="attacment">
    <strong>Attachments</strong>
    <div class="upload-file">
      <input type="file" name="upload" (change)="handleFileChange($event)" />
      <div class="newLoad-upload-title">
        <div>
          <span style="color: #175CD3;" class="text-upload">{{ 'Click to upload' }}</span>
          <span class="text-upload">{{ ' or drag and drop' }}</span>
        </div>
        <div>
          <span class="text-upload">{{ 'PNG, JPG or PDF (less than 20 MB)' }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="files">
      <div class="uploaded-file" *ngFor="let file of files; let idx = index">
        <div class="file-name" style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 10px;">
          <div style="display: flex; align-items: center;">
            <mat-icon class="icon-file">
              <img src="assets/svg/file-img.svg" alt="" />
            </mat-icon>
            <span style="font-family: Inter; font-size: 14px; font-style: normal; font-weight: 500; line-height: 20px;">{{ file.fileName }}</span>
          </div>
          <div class="icons-container">
            <svg-icon class="remove-btn" src="assets/svg/download.svg" (click)="downloadFile(file)"></svg-icon>
            <svg-icon class="remove-btn" src="assets/svg/file_preview.svg" (click)="previewFile(file)"></svg-icon>
            <svg-icon class="remove-btn red-icon" src="assets/svg/remove-img.svg" (click)="deleteFile(idx)"></svg-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  