import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import {
  MbscCalendarColor,
  MbscCalendarEvent,
  MbscEventcalendar,
  MbscEventcalendarOptions,
  MbscEventcalendarView,
  MbscPopup,
  MbscPopupOptions,
} from '@mobiscroll/angular';
import { view } from '../../../../../utils/view';
import { Driver } from '../../../../../interfaces/driver/driver';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Status } from '../../../../../enum/status.enum';
import { Carrier } from '../../../../../interfaces/carrier';
import { invalid } from '../../../../../utils/invalid';
import { loadColors, loadColorsForBackground } from '../types/load-status-color';
import { Load } from '../types/load';
import { TooltipLoadInformation } from '../../../../../interfaces/tooltip-load-information';
import { SharedService } from '../../../../../services/sharedServices/shared.service';
import { SharedTimeService } from '../../../../../services/sharedServices/shared-time.service';
import { CreateModalAllComponent } from '../modal/create-modal-all/create-modal-all.component';
import { LoadsService } from '../../../../../services/loads/loads.service';
import { Dispatcher } from '../../../../../interfaces/loads/dispatcher';
import { ViewEditLoadComponent } from '../modal/edit/view-edit-load/view-edit-load.component';
import { EditUserPopupComponent } from '../../users/components/edit-user-popup/edit-user-popup.component';
import { DialogService } from '../../../../../services/dialog/dialog.service';
import { LoaderService } from '../../../../../services/loader/loader.service';
import { DialogComponent } from '../../../../../components/dialog/dialog.component';
import { UsersPageService } from '../../../services/users/users.service';
import { DriverNotePopupComponent } from '../modal/driver-note-popup/driver-note-popup.component';
import { EditTrailerPopupComponent } from '../../../edit-trailer-popup/edit-trailer-popup.component';
import { EditVehiclePopupComponent } from '../../../edit-vehicle-popup/edit-vehicle-popup.component';
import { SettingsComponent } from '../../../../../interfaces/settings-component';
import { Subscription } from 'rxjs';
import { DataService } from '../../../../../services/reference-data/reference-data.service';
import { Item } from '../../../../../interfaces/item';
import { TargetPopupComponent } from '../modal/target-popup/target-popup.component';
import { Note } from '../../../../../interfaces/loads/note';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { CreateModalNoteComponent } from '../modal/create-modal-all/create-modal-note/create-modal-note.component';
import { UsersService } from '../../manage-pages/dispatch/services/users/users.service';
import { StorageService } from '../../../../../services/storage/storage.service';
import moment from 'moment';
import { UserRolesCode } from '../../../../../enum/user-role.enum';
import { NotificationService } from '../../../../../services/notification.service';

@Component({
  selector: 'app-time-line-calendar',
  templateUrl: './time-line-calendar.component.html',
  styleUrls: ['./time-line-calendar.component.css'],
})
export class TimeLineCalendarComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() settingsFormHeader!: SettingsComponent;
  @ViewChild('popup', { static: false })
  tooltip!: MbscPopup;
  @ViewChild('eventcalendar', { static: false }) eventcalendar!: MbscEventcalendar;
  isInitialized: boolean = false;
  itemsSubscription: Subscription = new Subscription();
  isMenuOpen: boolean = false;
  toolTipImformation!: TooltipLoadInformation;
  timelineSize: any;
  minDay: boolean = false;
  loads: Load[] = [];
  notes: Note[] = [];
  copyLoads: Load[] = [];
  bLoading: boolean = false;
  allSelectedDispatchers: any[] = [];
  allSelectedCarriers: any[] = [];
  coDriverTooltipPos: ConnectedPosition[] = [
    {
      originX: 'end', // Center of the host element
      originY: 'center', // Center of the host element
      overlayX: 'start', // Start (left) of the overlay
      overlayY: 'center' // Center of the overlay
    }
  ];

  allDrivers: Driver[] = [];
  allDispatchers: Dispatcher[] = [];
  allCarriers: Carrier[] = [];
  drivers: any[] = [];
  timeLineSize: number = 7;
  view: MbscEventcalendarView = {
    ...view,
    timeline: { size: this.timeLineSize },
  };
  weekendCellsColor: Array<MbscCalendarColor> = [
    {
      background: '#fafafb',
      recurring: { repeat: 'weekly', weekDays: 'SU' },
      cssClass: 'my-class',
    },
    {
      background: '#fafafb',
      recurring: { repeat: 'weekly', weekDays: 'SA' },
      cssClass: 'my-class',
    },
  ];
  myResources: any[] = [];
  myEvents: MbscCalendarEvent[] = [];
  isDragLoad = false;
  newLoad: any;
  calculateTotalPriceBy: string = 'pickup';
  createLoadWindow = false;
  invalid = invalid;
  carrierId: string = 'all';

  timeLineStart: Date = this.getMonday();
  timeLineEnd: Date = new Date();
  currentDate: Date = new Date();
  selectedDate: Date = new Date();
  copyDriver: Driver[] = [];
  selectedCarrier: any;
  selectedDriver: any;
  createStartDate: any;
  createEndDate: any;
  showAllDriversOfSupervisor: boolean = false;
  role!: string;

  readonly panelOpenState = signal(false);

  public searchLoadKey: string = '';

  popupOptions: MbscPopupOptions = {
    display: 'anchored',
    touchUi: false,
    showOverlay: false,
    contentPadding: false,
    closeOnOverlayClick: false,
    width: 350,
  };

  popupDelayTimer: any;

  timer: any;
  tooltipEvent: any;
  anchor?: HTMLElement;
  currentEvent: any;

  time: any;
  reason: any;
  location: any;

  settings!: SettingsComponent;

  calendarOptions: MbscEventcalendarOptions = {
    view: {
      timeline: {
        type: 'day',
        startDay: 1,
        endDay: 5,
        startTime: '08:00',
        endTime: '16:00',
        allDay: false,
      },
    },
    refDate: this.timeLineStart,
    dragToCreate: true,
    showEventTooltip: false,
    onEventHoverIn: (args) => {
      const event: any = args.event;
      if (event.loadType === 'Load') {
        const pickUpCity =
          event?.pickup && event.pickup.length > 0 ? event.pickup[0].city : '';
        const deliveryCity =
          event?.delivery && event.delivery.length > 0
            ? event.delivery[0].city
            : '';
        this.toolTipImformation = {
          driverName: event.driverName,
          carrierName: event.carrierName,
          loadId: event.loadId,
          customLoadId: event.customLoadId,
          status: this.convertToTitleCase(event.status),
          price: event.totalPrice,
          fee: event.fees.feeIncludesInPrice,
          pickUp: pickUpCity,
          delivery: deliveryCity,
          dh: event.emptyMiles.distance | 0,
          miles: event.loadMiles,
          totalMiles: event.totalMi,
          rate: event.rateMile,
          duration: this.getHoursAndMinutes(event),
        };

        this.anchor = args.domEvent.target;
        this.popupDelayTimer = setTimeout(() => {
          if (this.popupDelayTimer) {
            this.tooltip.open();
            clearTimeout(this.popupDelayTimer)
            this.popupDelayTimer = undefined
          }
        })
      }
    },
    onEventHoverOut: () => {
      this.timer = setTimeout(() => {
        this.tooltip.close();
        this.tooltipEvent = null;
        this.isDropdownOpen = false
      }, 100);

      this.popupDelayTimer = undefined;
    },
    onEventClick: () => {
      this.tooltip.close();
      this.isDropdownOpen = false
    },
  };

  options: any = {
    refDate: this.timeLineStart,
  };

  tempFilters: {
    carrier?: string;
    dispatcher?: string;
  } = {};

  activeFilters: {
    carrier?: string;
    dispatcher?: string;
  } = {};

  selectedSortingByDriver: any;
  selectedSortingByDispather: any;
  mySelectedEvent: any;

  selectedStartDate: any;
  selectedEndDate: any;
  totalPrice!: number;
  totalMi!: number;
  totalRatePerMi!: number;
  currentResource: any = [{ name: '', allLoads: [] }];
  totalByBtnTitle: string = 'PICKUP';
  index: any;

  isDropdownOpen = false;
  selectedStatus = 'Pending';
  statusOptions!: any;

  public trailerTypeSource: Array<Item> = new Array<Item>();
  public fuelTypeSource: Array<Item> = new Array<Item>();
  public ownerTypeSource: Array<Item> = new Array<Item>();
  public makeTypeSource: Array<Item> = new Array<Item>();
  intervalId: any;
  browserTime: string = '';
  openDropDownTotal: boolean = false;

  private subscription!: Subscription;


  constructor(
    public dialog: MatDialog,
    private sharedService: SharedService,
    private sharedTimelineService: SharedTimeService,
    private loadService: LoadsService,
    private dialogService: DialogService,
    private loaderService: LoaderService,
    private usersPageService: UsersPageService,
    private dataService: DataService,
    private usersService: UsersService,
    private storageService: StorageService,
    private sharedTimeService: SharedTimeService,
    private notificationService: NotificationService
  ) { }

  async ngOnInit() {
    this.startClock();
    const role = localStorage.getItem('role');
    this.role = role !== null ? role : '';
    this.statusOptions = Object.values(Status);
    this.startItemsObserver();
    let storage = this.storageService.getStorage();
    await Promise.all([
      this.getAllLoads(this.timeLineStart),
      this.getAllDrivers(),
      this.getAllCarriers(),
      this.getAllDispatchers(),
    ]).then(() => {
      this.searchByFilteredDriver();
      this.searchLoad();
      this.setMonday();
      this.setMonth();
      this.shiftTimeline();

      this.onStartDateChange();
      this.onEndDateChange();
      this.refreshResources(this.copyDriver);
      this.calculateAll(this.copyDriver)
      this.getStorage();
      this.sharedService.setFilterDriver(this.allDrivers);
      this.isInitialized = true;
    });

    // this.subscription = this.sseService.getLoadUpdates().subscribe(
    //   (loadUpdate) => {
    //     console.log("🚀 ~ ngOnInit ~ loadUpdate:", loadUpdate)
    //     if (loadUpdate.load) {
    //       this.updateLoads(loadUpdate);
    //       this.refreshResources();
    //       this.calculateAll(this.copyDriver);
    //     }

    //   },
    //   (error) => {
    //     console.error('Error receiving load updates', error);
    //   }
    // );
  }

  getDateDay(data: Date): number {
    return new Date(data).getDate();
  }

  getDateMonth(data: Date): string {
    return new Date(data).toLocaleString('default', { weekday: 'short' }).toUpperCase();
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.itemsSubscription.unsubscribe();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    document
      .querySelectorAll('.event-tonu.mbsc-schedule-event.mbsc-ltr')
      .forEach((el) => {
        (el as HTMLElement).style.width = '0';
      });
  }

  startItemsObserver() {
    this.itemsSubscription = this.dataService.items$.subscribe((items) => {
      items.map((item) => {
        switch (item.type) {
          case 'TrailerType':
            this.trailerTypeSource.push(item);
            break;
          case 'Ownership':
            this.ownerTypeSource.push(item);
            break;
          case 'Fuel':
            this.fuelTypeSource.push(item);
            break;
          case 'Make':
            this.makeTypeSource.push(item);
            break;
        }
      });
    });
  }

  onStartDateChange() {
    this.sharedTimelineService.startDateTimeString.subscribe((value) => {
      const date = value;
      if (date) {
        this.selectedStartDate = new Date(date);
      }
    });
  }

  onEndDateChange() {
    this.sharedTimelineService.endtDateTimeString.subscribe(async (value) => {
      const date = value;
      if (date) {
        const startDate: any = new Date(this.selectedStartDate);
        const endDate: any = new Date(date);
        const differenceInMilliseconds = endDate - startDate;
        const differenceInDays =
          differenceInMilliseconds / (1000 * 60 * 60 * 24);

        this.selectedEndDate = endDate;
        this.selectedDate = new Date(startDate);
        this.timeLineStart = new Date(startDate);
        this.options = {
          refDate: this.timeLineStart,
        };
        this.calendarOptions = this.options;
        this.refreshResources(this.copyDriver);
        this.changeSize(differenceInDays + 1);
        await this.checkCurrentMonth();
      } else {
        this.selectedEndDate;
      }
    });

  }

  async getAllLoads(date: any) {
    const formattedDate = new Date(date).toLocaleDateString('en-US', {
      year: '2-digit',
      month: '2-digit'
    }).replace(/\//g, '-');

    let resp = await this.loadService.getLoadsAndNotesForTmln(formattedDate)
    this.loads = resp.loads;
    this.notes = resp.notes;
    this.copyLoads = JSON.parse(JSON.stringify(this.loads));
    this.sharedService.setLoad(this.loads);

    this.copyLoads = this.loads.filter((el: any) => {
      if (el.partial && el.partial.length) {
        el.partial = el.partial.filter(
          (partial: any) => partial.status !== 'DELETED'
        );
      }
      return el.status !== 'DELETED';
    });
  }

  async refreshFunc() {
    // await this.getAllDrivers();
    await this.getAllLoads(this.timeLineStart);
    this.refreshResources(this.copyDriver);
  }

  async getAllDrivers() {
    let resp = await this.loadService.getAllDrivers(false);
    this.allDrivers = resp;
    this.copyDriver = JSON.parse(JSON.stringify(this.allDrivers));
    this.sharedService.setDriver(this.allDrivers);
  }
  async getAllCarriers() {
    let allCarriers = await this.loadService.getAllCarriers();
    this.allCarriers = allCarriers;

    this.sharedService.setCarrier(this.allCarriers);
  }

  async getAllDispatchers() {
    let allDispatchers = await this.loadService.getAllDispatchers();
    this.allDispatchers = allDispatchers;

    this.sharedService.setDispatcher(this.allDispatchers);
  }

  public dragStart(e: any): void {
    e.event.type = 'mainLoad';
  }

  openFilterMenu() {
    this.isMenuOpen = true;
  }
  closeMenu() {
    this.isMenuOpen = false;
  }

  public async dragEnd(event: any) {
    this.createStartDate = event.event.start;
    this.createEndDate = event.event.end;
    try {
      const driver: Driver | undefined = this.allDrivers.find(
        (el) => el.id === event.event.resource
      );
      const dispatcher = this.allDispatchers.find(
        (el) => el.id === driver?.dispatcherId
      );
      let tempLoad = {
        start: event.event.start,
        end: event.event.end,
        driverId: event.event.resource,
        carrierId: 0,
        dispatcher,
      };
      let idx = this.allDrivers.findIndex((el) => el.id === tempLoad.driverId);
      if (idx >= 0) {
        tempLoad.carrierId = this.allDrivers[idx].carrierId;
      }
      this.isDragLoad = true;
      this.newLoad = tempLoad;
      this.createLoad(0, tempLoad);
    } catch (e) {
      console.log(e);
      await this.refreshFunc()
    }
  }

  public calculateAll(drivers?: any) {
    this.myResources.forEach((driver) => {
      driver.totalPrice = 0;
      driver.loadMiles = 0;
      driver.rate = 0;
    });

    if (drivers) {
      let obj = {
        from: this.loadService.parseStartDate(this.timeLineStart),
        to: this.loadService.parseEndDate(this.selectedEndDate),
        type: this.totalByBtnTitle,
        drivers: this.getDriverAndAllLoadsIds(drivers),
      };
      this.loadService.getTotalBy(obj).subscribe((response) => {
        if (response) {
          if (response.driversTotalBys.length !== 0) {
            for (let i = 0; i < response.driversTotalBys?.length; i++) {
              this.myResources.forEach((driver) => {
                if (response.driversTotalBys[i].driverId === driver.id) {
                  driver.totalPrice = response.driversTotalBys[i].gross;
                  driver.loadMiles = response.driversTotalBys[i].totalMiles;
                  driver.rate = response.driversTotalBys[i].grossPerMile;
                }
              });
            }
          }
        }
        this.totalPrice = response.summaryTotalBy.gross;
        this.totalMi = response.summaryTotalBy.totalMiles;
        this.totalRatePerMi = response.summaryTotalBy.grossPerMile;
      });
      this.clearTrialLabels();
    }
  }

  private parseStartDate(date: any) {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let localISOTime = `${year}-${month}-${day}T00:00:00Z`;

    return localISOTime
  }

  getDriverAndAllLoadsIds(driver: any) {
    let drivers = [
      {
        driverId: 0,
        loadIds: []
      }
    ]
    drivers = driver?.map((d: any) => {
      let loadsId = d.allLoads?.map((value: any) => value.loadId)
      return {
        driverId: d.id,
        loadIds: loadsId
      }
    })
    return drivers
  }

  addCalcLoad(resource: any) {
    //Alexander logic
    let endDate = new Date(this.timeLineStart);
    endDate.setDate(endDate.getDate() + (this.timeLineSize - 1));
    let loadsId = resource.allLoads?.map((value: any) => value.loadId)
    let obj = {
      from: this.timeLineStart,
      to: endDate,
      type: this.totalByBtnTitle,
      drivers: [{
        driverId: resource.id,
        loadIds: loadsId
      }],
    };
    this.loadService.getTotalBy(obj).subscribe((totalbyData) => {
      let driverTarget = totalbyData.driversTotalBys.filter((f: any) => f.driverId === resource.id);
      if (driverTarget.length > 0)
        this.openTargetPopup(resource, driverTarget[0]);
    });

  }

  openTargetPopup(resource: any, totalbyData: any) {

    let targetData = { resource: resource, totalbyData: totalbyData };
    let dialogRef: MatDialogRef<TargetPopupComponent> = this.dialog.open(
      TargetPopupComponent,
      {
        data: targetData,
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      this.updateTarget(result.data, resource.id);
    });
  }

  checkIfExistFullAddress(): boolean {
    for (let i = 0; i > this.loads.length; i++) {
    }
    return false;
  }

  async calcPrice(type: string) {
    this.totalByBtnTitle = type;
    this.calculateAll(this.copyDriver);
    this.openDropDownTotal = false;
  }

  public checkStatus(event: any): string {
    const eventDate = new Date(event.original.dateTimeLineBgn);
    const timeLineStartDate = new Date(this.timeLineStart);

    if (eventDate.getMonth() < timeLineStartDate.getMonth() ||
      (eventDate.getMonth() === timeLineStartDate.getMonth() && eventDate.getDate() < timeLineStartDate.getDate())) {
      return 'none';
    } else {
      return '7px solid ' + loadColors[event.original.status];
    }
  }

  public checkStatusForBackground(status: any) {
    if (status in loadColorsForBackground) {
      return loadColorsForBackground[status];
    } else {
      return '#d9d9d9';
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const clickedInside = (event.target as HTMLElement).closest('.main-container-status-dropdown-menu');
    if (!clickedInside) {
      this.isDropdownOpen = false;
    }
  }

  mouseEnter(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.tooltip.open();
      this.timer = null;
    }
  }

  mouseLeave(): void {
    this.timer = setTimeout(() => {
      this.tooltip.close();
      this.isDropdownOpen = false
    }, 200);
  }

  public getInitials(fullDriverName: string) {
    let parts = fullDriverName.trim().split(/\s+/);
    let initials = parts
      .slice(0, 2)
      .map((part: string) => part.charAt(0))
      .join('');
    return initials.toUpperCase();
  }

  private getMonday(): Date {
    const today = new Date();
    const day = today.getDay();
    const diff = today.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(today.setDate(diff));
  }

  protected refreshResources(drivers: any): void {
    this.myResources = drivers
    this.myResources.forEach((el) => {
      el.carrierId = el.carrierId;
      el.id = el.id;
      el.totalPrice = 0;
      el.loadMiles = 0;
      el.rate = 0;
      el.allLoads = [];
    });
    this.myEvents = [];

    let loadsAndNotes = [...this.copyLoads, ...this.notes]

    loadsAndNotes.forEach((el: any) => {
      try {
        const temp = {
          title: el?.customLoadId ? el?.customLoadId : '',
          start: el.dateTimeLineBgn,
          end: el?.dateTimeLineEnd,
          resource: el?.driverId,
          fees: el?.fees,
          type: el?.type,
          moreThanOneDay:
            new Date(el?.dateTimeLineEnd).getTime() -
            new Date(el?.dateTimeLineBgn).getTime() >
            82800000,
        };

        if (el.loadType !== 'Partial') {
          this.myEvents.push({ ...el, ...temp });
        }

        const idx = this.myResources.findIndex(
          (item) => item.id === el.driverId
        );
        if (idx >= 0) {
          this.myResources[idx].allLoads.push(el);
        }
        this.bLoading = false;
      } catch (e) {
        this.bLoading = false;
        console.log(e);
      }
    });

    if (this.isInitialized) {
      this.calculateAll(drivers)
    }
    this.clearTrialLabels();
  }

  getMinDay(startDate: any, minDate: any) {
    let date1: any = new Date(startDate);
    let date2: any = new Date(minDate);
    let timediferens = date2 - date1;
    let differens = Math.floor(timediferens / (1000 * 60 * 60 * 24));
    if (differens > 1) {
      this.minDay = true;
    }
    this.minDay = false;
  }

  selectedCarriers(event: any) {
    const carrierName = event.target?.value;

    if (event.target.checked) {
      this.tempFilters.carrier = carrierName;
      this.allSelectedCarriers.push(carrierName);

      this.allCarriers.forEach(car => {
        if (this.allSelectedCarriers.includes(car.name)) {
          car.selectedCarrier = true;
        }
      });
    } else {
      let index = this.allSelectedCarriers.indexOf(carrierName);
      if (index !== -1) {
        this.allSelectedCarriers.splice(index, 1);
      }

      this.allCarriers.forEach(car => {
        if (this.allSelectedCarriers.includes(car.name)) {
          car.selectedCarrier = true;
        } else {
          car.selectedCarrier = false;
        }
      });

      // delete this.tempFilters.carrier;
    }
  }


  selectedDispatcher(event: any) {
    if (event) {
      this.tempFilters.dispatcher = event.target.value;
      this.allSelectedDispatchers.push(event.target.value);
      this.allDispatchers.forEach((dispatcher) => {
        if (
          this.allSelectedDispatchers.includes(dispatcher.dispatcherFullName)
        ) {
          dispatcher.selectedDispatcher = true;
        }
      });
    } else {
      let index = this.allSelectedDispatchers.indexOf(event.target.value);
      this.allSelectedDispatchers.splice(index, 1);
      this.allDispatchers.forEach((dispatcher) => {
        if (
          this.allSelectedDispatchers.includes(dispatcher.dispatcherFullName)
        ) {
          dispatcher.selectedDispatcher = true;
        } else {
          dispatcher.selectedDispatcher = false;
        }
      });
      // delete this.tempFilters.dispatcher;
    }
  }

  async applyFilters() {
    if (this.showAllDriversOfSupervisor) {
      await this.getDriversOfSupervisor();
    }
    this.activeFilters = { ...this.tempFilters };
    let filteredDrivers = [...this.copyDriver];
    this.storageService.updateStorageField('selectedSortingByDriver', this.selectedSortingByDriver)
    this.storageService.updateStorageField('selectedSortingByDispather', this.selectedSortingByDispather)
    this.storageService.updateStorageField('companySort', this.allCarriers)
    filteredDrivers.sort((a: any, b: any) => {
      if (this.selectedSortingByDriver === 0) {
        return !b.driverFullName
          ? -1
          : a.driverFullName
            .toLowerCase()
            .localeCompare(b.driverFullName.toLowerCase());
      } else if (this.selectedSortingByDriver === 1) {
        return !a.driverFullName
          ? 1
          : !b.driverFullName
            ? -1
            : b.driverFullName
              .toLowerCase()
              .localeCompare(a.driverFullName.toLowerCase());
      }
    });

    filteredDrivers.sort((a: any, b: any) => {
      if (this.selectedSortingByDispather === 0) {
        return !b.dispatcherFullName
          ? -1
          : a.dispatcherFullName
            .toLowerCase()
            .localeCompare(b.dispatcherFullName.toLowerCase());
      } else if (this.selectedSortingByDispather === 1) {
        return !a.dispatcherFullName
          ? 1
          : !b.dispatcherFullName
            ? -1
            : b.dispatcherFullName
              .toLowerCase()
              .localeCompare(a.dispatcherFullName.toLowerCase());
      }
    });

    filteredDrivers.sort((a: any, b: any) => {
      const aSelected = this.allCarriers.some(carrier => carrier.selectedCarrier && carrier.name === a.carrierName);
      const bSelected = this.allCarriers.some(carrier => carrier.selectedCarrier && carrier.name === b.carrierName);

      if (aSelected && !bSelected) return -1;
      if (!aSelected && bSelected) return 1;
      return 0;
    });

    this.copyDriver = filteredDrivers;
    this.myResources = this.copyDriver;
    this.refreshResources(this.copyDriver);
    this.isMenuOpen = false;
  }

  getStorage() {
    let storage = this.storageService.getStorage();

    if (storage?.selectedSortingByDriver !== -1 || storage?.selectedSortingByDispather !== -1 || storage.companySort.length !== 0) {
      this.selectedSortingByDispather = storage?.selectedSortingByDispather
      this.selectedSortingByDriver = storage?.selectedSortingByDriver
      this.allCarriers = storage?.companySort || this.allCarriers;
      this.applyFilters()
    }
  }

  onCheckboxChange(event: any) {
    this.showAllDriversOfSupervisor = (
      event.target as HTMLInputElement
    ).checked;
  }

  async getDriversOfSupervisor() {
    this.copyDriver = await this.loadService.getAllDrivers(
      this.showAllDriversOfSupervisor
    );
  }

  resetFilter() {
    this.tempFilters = {};
    this.activeFilters = {};
    this.selectedSortingByDriver = null;
    this.selectedSortingByDispather = null;
    this.showAllDriversOfSupervisor = false;
    this.allCarriers.forEach((value) => (value.selectedCarrier = false));
    this.allDispatchers.forEach((value) => (value.selectedDispatcher = false));
    this.refreshResources(this.copyDriver);
  }

  searchByFilteredDriver() {
    this.sharedService.filteredDriver.subscribe((filteredData) => {
      if (this.isInitialized) {
        if (filteredData) {
          this.copyDriver = filteredData;
        } else {
          this.copyDriver = this.allDrivers
        }
        this.refreshResources(this.copyDriver);
      }
    });
  }

  searchLoad() {
    this.sharedService.fiteredLoads.subscribe((filteredDataLoad) => {
      if (this.isInitialized) {
        if (filteredDataLoad.length !== this.loads.length) {
          let loadsIds = filteredDataLoad.map(value => value.driverId);
          let selectedDrivers = this.copyDriver.filter((value: any) =>
            loadsIds.includes(value.id)
          );

          this.copyLoads = filteredDataLoad;
          this.myEvents = this.copyLoads;
          this.refreshResources(selectedDrivers)
          this.navigateToEvent(filteredDataLoad[0]?.dateTimeLineBgn);
          this.options = {
            refDate: filteredDataLoad[0]?.dateTimeLineBgn,
          };
          this.calendarOptions = this.options;
        } else {
          this.copyLoads = filteredDataLoad;
          this.myEvents = this.copyLoads;
          this.sharedTimeService.setTimeForTimeline('Current week');
          this.refreshResources(this.copyDriver);
        }
      }
    });
  }

  navigateToEvent(event: any) {
    if (this.eventcalendar && event) {
      const eventDate = new Date(event);
      this.timeLineStart = eventDate;
      this.eventcalendar.navigate(eventDate);
    }
  }

  public setMonday() {
    this.sharedTimelineService.timelineArray.subscribe(async (count) => {
      if (this.isInitialized) {
        if (count && count !== 'Current week') {
          this.timelineSize = `${count} days`;
          this.selectedDate = new Date();
          const monday = this.getMonday();
          this.timeLineStart = monday;
          let start = new Date();
          start.setDate(monday.getDate() - count)
          this.sharedTimelineService.setStartAndEndTime(start, monday);
          this.calendarOptions.refDate = this.timeLineStart;
          this.changeSize(count);
          await this.checkCurrentMonth();
        } else {
          this.timelineSize = `${7} days`;
          this.selectedDate = new Date();
          const monday = this.getMonday();
          this.timeLineStart = monday;
          let lastDate = new Date();
          lastDate.setDate(monday.getDate() + 7);
          this.sharedTimelineService.setStartAndEndTime(monday, lastDate);
          this.calendarOptions.refDate = this.timeLineStart;
          this.changeSize(7);
          await this.checkCurrentMonth();
        }
      }
    });
  }

  public changeSize(size: number) {
    this.timeLineSize = size;
    this.view = { timeline: { ...view.timeline, size: this.timeLineSize } };
    this.clearTrialLabels();
  }

  public setMonth(): void {
    this.sharedTimelineService.timelineMonthArray.subscribe(async (m) => {
      if (this.isInitialized) {
        if (m === 'month') {
          this.timelineSize = 'month';
          const { firstDay, daysInMonth } = this.getFirstDayOfMonth();
          let lastDate = new Date();
          lastDate.setDate(firstDay.getDate() + daysInMonth);
          this.sharedTimelineService.setStartAndEndTime(firstDay, lastDate)
          this.selectedDate = new Date();
          this.timeLineStart = firstDay;
          this.options = {
            refDate: this.timeLineStart,
          };
          this.calendarOptions = this.options;
          this.changeSize(daysInMonth);
          await this.checkCurrentMonth();
        }
      }
    });
  }

  public getFirstDayOfMonth(): { firstDay: Date; daysInMonth: number } {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const daysInMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    ).getDate();
    return { firstDay, daysInMonth };
  }

  public shiftTimeline(): void {
    this.sharedTimelineService.timelineShiftArray.subscribe(async (count) => {
      if (this.isInitialized) {
        if (count) {
          this.selectedDate.setDate(this.selectedDate.getDate() + count);
          this.timeLineStart.setDate(this.timeLineStart.getDate() + count);
          this.options = {
            refDate: this.timeLineStart,
          };
          this.calendarOptions = this.options;
          this.view = { timeline: { ...view.timeline, size: this.timeLineSize } };
          let lastDate = new Date(this.timeLineStart);
          lastDate.setDate(lastDate.getDate() + (this.timeLineSize - 1));
          this.sharedTimelineService.setStartAndEndTime(new Date(this.timeLineStart), lastDate)
          await this.checkCurrentMonth();
          this.clearTrialLabels();
        }
      }
    });
  }

  async checkCurrentMonth() {
    if (this.timeLineStart.getMonth() === this.currentDate.getMonth()) {
      return
    } else {
      this.currentDate = this.timeLineStart
      await this.getAllLoads(this.currentDate)
      this.refreshResources(this.copyDriver)
    }
  }

  createLoad(value: number, selected?: any) {
    this.createLoadWindow = false;
    const dialogRef = this.dialog.open(CreateModalAllComponent, {
      data: {
        partial: false,
        index: value,
        drivers: this.allDrivers,
        carriers: this.allCarriers,
        load: this.newLoad,
        dispatcers: this.allDispatchers,
        startDate: this.createStartDate,
        endDate: this.createEndDate,
      },
    });
    dialogRef.afterClosed().subscribe(async () => {
      await this.getAllLoads(this.timeLineStart);
      this.searchByFilteredDriver();
      this.newLoad = undefined;
      this.createStartDate = undefined;
      this.createEndDate = undefined
    });
  }

  editLoad(selectedLoad: any) {
    const dialogRef = this.dialog.open(ViewEditLoadComponent, {
      data: {
        load: selectedLoad.original.loadId,
        drivers: this.allDrivers,
        carriers: this.allCarriers,
        dispatcers: this.allDispatchers,
      },
    });
    dialogRef.afterClosed().subscribe(async (resp) => {
      if (resp) {
        await this.getAllLoads(this.timeLineStart);
        this.searchByFilteredDriver();
      }
    });
  }

  editNote(selectedNote: any) {
    const dialogRef = this.dialog.open(CreateModalNoteComponent, {
      data: {
        note: selectedNote.original,
        editLoad: true,
      },
      width: '640px'
    });
    dialogRef.afterClosed().subscribe(async () => {
      await this.getAllDrivers();
      await this.getAllLoads(this.timeLineStart)
    });
  }

  editTrailer(resource: any, event: Event) {
    event.stopPropagation();
    this.openEditTrailerPopup({
      data: resource,
      fuelType: this.fuelTypeSource,
      ownerType: this.ownerTypeSource,
      trailerType: this.trailerTypeSource,
      makeType: this.makeTypeSource,
    });
  }

  editVehicleClick(resource: any, event: Event) {
    event.stopPropagation();
    this.openeditVehiclePopup({
      data: resource,
      fuelType: this.fuelTypeSource,
      makeType: this.makeTypeSource,
    });
  }

  openeditVehiclePopup(resource: any) {
    const dialogRef = this.dialog.open(EditVehiclePopupComponent);
    const componentInstance = dialogRef.componentInstance;

    componentInstance.subscribe({ data: resource }, popup => {
      switch (popup.type) {
        case "deactivate":
          const text: string = 'Are you sure you want to deactivate ' + popup.data.data.vehicleUnit + ' ? This action cannot be undone.'
          this.openDeleteDialog(text).then(res => {
            if (res) {
              this.loaderService.show();
              this.loadService.deleteVehicle(resource.data.vehicleId).subscribe(async () => {
                this.loaderService.hide();
                await this.refreshFunc();
                componentInstance.close();
              },
                (error) => {
                  this.loaderService.hide();
                  console.log(error);
                });
            }
          })
          break;
        case "close": componentInstance.close(); break;
        case "ok":
          this.updateVehicle(popup.formData, resource.data.vehicleId).then(async () => {
            await this.refreshFunc();
            componentInstance.close()
          });
          break;
      }
    });
  }

  openEditTrailerPopup(resource: any) {
    const dialogRef = this.dialog.open(EditTrailerPopupComponent);
    const componentInstance = dialogRef.componentInstance;

    componentInstance.subscribe({ data: resource }, popup => {
      switch (popup.type) {
        case "deactivate":
          const text: string = 'Are you sure you want to deactivate ' + popup.data.data.trailerUnit + ' ? This action cannot be undone.'
          this.openDeleteDialog(text).then(res => {
            if (res) {
              this.loaderService.show();
              this.loadService.deleteTrailer(resource.data.trailerId).subscribe(async () => {
                this.loaderService.hide();
                await this.refreshFunc();
                componentInstance.close();
              }, (error) => {
                this.loaderService.hide();
                console.log(error);
              });
            }
          })
          break;
        case "close": componentInstance.close(); break;
        case "ok":
          this.updateTrailer(popup.formData, resource.data.trailerId).then(async () => {
            await this.refreshFunc();
            componentInstance.close()
          });
          break;
      }
    });
  }

  editDriver(resource: any, event: Event) {
    event.stopPropagation();
    this.loaderService.show();
    this.loadService.getDriverById(resource.id).then((result) => {
      // let data = { ...result, roles: [UserRolesCode.DRIVER], phone: result.phoneNumber, gSearchID: 'masterDriver', coDriverVisible: true };
      result = { ...result, gSearchID: 'masterDriver', roles: [UserRolesCode.DRIVER], coDriverVisible: true };
      this.openEditMainDriverPopup(result);
      this.loaderService.hide();
    });
  }

  editCarrier(resource: any, event: Event) {
    event.stopPropagation();
    this.loaderService.show();
    this.loadService.getCarrierById(resource.carrierId).then((data) => {
      data.roles = ['Carrier'];
      this.openEditUserPopup(data);
      this.loaderService.hide();
    });
  }

  editDispatcher(resource: any, event: Event) {
    event.stopPropagation();
    this.loaderService.show();
    this.loadService.getUserById(resource.dispatcherId).then((data) => {
      data.roles = [UserRolesCode.DISPATCHER];
      this.openEditUserPopup(data);
      this.loaderService.hide();
    });
  }

  openEditUserPopup(row: any, mainDriver?: any) {
    const dialogRef = this.dialog.open(EditUserPopupComponent);
    const componentInstance = dialogRef.componentInstance;

    componentInstance.subscribe({ data: row }, (popup: any) => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "deactivate": this.deactivateHandler(row, componentInstance); break;
        case "ok": this.confirmHandler(popup, row, componentInstance, mainDriver); break;
      }
    });
  }

  openEditMainDriverPopup(userData: any) {
    const dialogRef = this.dialog.open(EditUserPopupComponent);
    const componentInstance = dialogRef.componentInstance;

    componentInstance.subscribe({ data: userData }, popup => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "deactivate":
          const text: string = 'Are you sure you want to deactivate ' + popup.formData.firstName + ' ' + popup.formData.lastName + ' ? This action cannot be undone.'
          this.driverDeactivateHandler(text, popup.formData).then(res => {
            if (res) componentInstance.close()
          });
          break;
        case "ok": this.driverConfirmHandler(popup.formData, userData.id, componentInstance); break;
        case "editCodriver":
          popup.formData.id = userData.id;
          this.editCoDriverPopup(popup.formData, userData.coDriverId, componentInstance);
          break;

        case "addCoDriver": this.addCoDriverPopup(false, popup.formData, componentInstance, userData.id); break;
      }
    });
  }

  async editCoDriverPopup(mainDriver: any, codriverID: number, masterPopupInstance: any) {
    let coDriverData = await this.loadService.getDriverById(codriverID);
    const masterDriverData = {
      trailerId: mainDriver.trailerId,
      vehicleId: mainDriver.vehicleId,
      dispatcherId: mainDriver.dispatcherId,
      carrierId: mainDriver.carrierId,
      newTrailerUnit: mainDriver.newTrailerUnit || null,
      newVehicleUnit: mainDriver.newTrailerUnit || null,
      newCarrierName: mainDriver.newTrailerUnit || null,
    }
    coDriverData = { ...coDriverData, gSearchID: 'coDriver', coDriverVisible: false, masterDriverData: masterDriverData, roles: [UserRolesCode.DRIVER] };
    const dialogRef = this.dialog.open(EditUserPopupComponent);
    const componentInstance = dialogRef.componentInstance;

    let data: any = { data: coDriverData };

    componentInstance.subscribe(data, popup => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "deactivate":
          const text: string = 'Are you sure you want to deactivate ' + popup.formData.firstName + ' ' + popup.formData.lastName + ' ? This action cannot be undone.'
          masterPopupInstance.driverFormComp.row.coDriverId = null;
          componentInstance.close();
          this.driverDeactivateHandler(text, popup.formData).then(res => {
            if (res) {
              masterPopupInstance.driverFormComp.row.coDriverId = null;
              componentInstance.close();
            }
          });
          break;
        case "ok":
          mainDriver.coDriver = popup.formData;
          mainDriver.coDriver.id = codriverID;
          mainDriver.roles = [UserRolesCode.DRIVER];
          this.driverConfirmHandler(mainDriver, mainDriver.id, masterPopupInstance, componentInstance);
          break;
      }
    });
  }

  addCoDriverPopup(coDriverVisible: boolean, masterDriverData: any, masterPopupInstance: any, masterDriverID?: number) {
    const dialogRef = this.dialog.open(EditUserPopupComponent);
    const componentInstance = dialogRef.componentInstance;
    const data = { roles: [UserRolesCode.DRIVER], tempCreateDriver: true, coDriverVisible: coDriverVisible, masterDriverData: masterDriverData, gSearchID: 'coDriver' };
    componentInstance.subscribe({ data: data }, popup => {
      switch (popup.type) {
        case "close": componentInstance.close(); break;
        case "ok":
          masterDriverData.coDriver = popup.formData;
          if (masterDriverID) {
            this.updateDriver(masterDriverData, masterDriverID).then(() => {
              this.notificationService.success("Succes", String.raw`Driver \ CoDriver was updated succesfully!`);
              this.loaderService.hide();
              componentInstance.close();
              masterPopupInstance.close();
            }, err => {
              console.log(err);
              this.loaderService.hide();
            });
          } else {
            this.loaderService.show();
            this.createDriver(masterDriverData).then(async () => {
              this.notificationService.success("Succes", String.raw`Driver \ CoDriver was added succesfully!`);
              await this.refreshFunc();
              this.loaderService.hide();
              componentInstance.close();
              masterPopupInstance.close();
            }, err => {
              console.log(err);
              this.loaderService.hide();
            });
          }
          break;
      }
    });
  }

  driverConfirmHandler(masterDriverForm: any, driverID: number, masterPopupInstance: any, detailPopupInstance?: any) {
    this.updateDriver(masterDriverForm, driverID).then(async () => {
      if (masterDriverForm.coDriver) this.notificationService.success("Succes", String.raw`Driver \ CoDriver was updated succesfully!`);
      else this.notificationService.success("Succes", `Driver was updated succesfully!`);
      masterPopupInstance.close();
      if (detailPopupInstance) detailPopupInstance.close();
    });
  }

  driverDeactivateHandler(text: string, row: any): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      this.openDeleteDialog(text).then(res => {
        if (!res) return;
        this.loaderService.show();
        this.usersService.deleteDriver(row.id).subscribe(async () => {
          await this.getAllDrivers();
          this.refreshResources(this.copyDriver);
          resolve(true);
          this.loaderService.hide();
        }, error => {
          reject()
          this.loaderService.hide();
          console.log(error);
        });
      });
    })
  }

  deactivateHandler(row: any, parentDialogRef: any) {
    let deleteName: string = '';
    switch (row.roles[0]) {
      case 'Dispatcher': deleteName = row.firstName + ' ' + row.lastName;
        break;
      case 'Carrier': deleteName = row.name;
        break;
    }
    const text: string = 'Are you sure you want to deactivate ' + deleteName + ' ? This action cannot be undone.'

    this.openDeleteDialog(text).then(res => {
      if (!res) return;

      switch (row.roles[0]) {
        case 'Dispatcher':
          this.loaderService.show();
          this.usersPageService.deleteUser(row.id).subscribe(() => {
            this.loaderService.hide();
            parentDialogRef.close();
          },
            (error) => {
              this.loaderService.hide();
              console.log(error);
            });
          break;
        case 'Carrier':
          this.loaderService.show();
          this.loadService.deleteCarrier(row.id).subscribe(() => {
            this.loaderService.hide();
            parentDialogRef.close();
          },
            (error) => {
              this.loaderService.hide();
              console.log(error);
            });
          break;
        case 'Driver':
          this.loaderService.show();
          this.usersService.deleteDriver(row.id).subscribe(() => {
            this.loaderService.hide();
            parentDialogRef.close();
          },
            (error) => {
              this.loaderService.hide();
              console.log(error);
            });
          break;
      }
    });
  }

  confirmHandler(popup: any, row: any, componentInstance: any, mainDriver?: any) {
    switch (row.roles[0]) {
      case 'Dispatcher':
        this.editUser(popup.formData, row.id).then(() => componentInstance.close());
        break;
      case 'Carrier':
        this.updateCarrier(popup.formData, row.id).then(() => componentInstance.close());
        break;
      case 'Driver':
        mainDriver.coDriver = popup.formData;
        console.log(mainDriver);
        this.updateDriver(popup.formData, row.id).then(() => componentInstance.close());
        break;
    }
  }

  openNotePopup(resource: any) {
    let dialogRef: MatDialogRef<DriverNotePopupComponent> = this.dialog.open(
      DriverNotePopupComponent,
      {
        panelClass: 'custom-container',
        data: resource,
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (!result || result.data.trim().length === 0) return;
      this.loadService
        .createDriverNote(result.data, resource.id)
        .then(() => {
          this.getAllDrivers()
        })
        .catch((error) => {
          this.openWarningDialog(error.error.message);
          console.error(error);
        });
    });
  }

  openDeleteDialog(deleteText: string): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      const deletIconStyle = { stroke: '#D92D20', width: '48px', heigth: '48px' };
      const btn1Style = {
        background: 'white',
        color: '#344054',
        border: '1px solid #D0D5DD',
      };
      const btn2Style = {
        background: '#D92D20',
        color: 'white',
        border: '1px solid #D92D20',
      };

      const dialogData: any = {
        title: 'Deactivate',
        text: deleteText,
        button1Text: 'Cancel',
        button2Text: 'Delete',
        icon: 'custom-trash',
        iconStyle: deletIconStyle,
        dsa: true,
      };

      const dialogRef = this.dialogService.openDialog(DialogComponent, {
        props: { ...dialogData, btn1Style, btn2Style },
      });

      dialogRef.subscribe((dialog) => {
        if (!dialog) return;
        switch (dialog.result) {
          case 'button1': resolve(false); break;
          case 'button2': resolve(true); break;
        }
      });
    });
  }

  editUser(data: Array<any>, userID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loaderService.show();
      this.usersPageService.editUser(data, userID).subscribe((res) => {
        resolve(res);
        this.loaderService.hide();
      }, (error) => {
        console.log(error);
        reject(error);
        this.loaderService.hide();
      }
      );
    });
  }

  updateTarget(data: Array<any>, driverID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loaderService.show();
      this.loadService.updateTarget(data, driverID).subscribe((res) => {
        resolve(res);
        this.loaderService.hide();
      }, (error) => {
        console.log(error);
        this.loaderService.hide();
        reject(error);
      }
      );
    });
  }

  updateCarrier(data: Array<any>, carrierID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loaderService.show();
      this.loadService.updateCarrier(data, carrierID).subscribe(
        async (res) => {
          this.loaderService.hide();
          await this.getAllLoads(this.timeLineStart);
          this.refreshResources(this.copyDriver)
          resolve(res);
        },
        (error) => {
          console.log(error);
          this.loaderService.hide();
          reject(error);
          // this.openWarningDialog(error.error.message);
        }
      );
    });
  }

  updateDriver(data: Array<any>, driverID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loaderService.show();
      this.loadService.updateDriver(data, driverID).subscribe(
        async (res) => {
          this.loaderService.hide();
          await this.getAllLoads(this.timeLineStart);
          await this.getAllDrivers();
          this.refreshResources(this.copyDriver);
          resolve(res);
        },
        (error) => {
          console.log(error);
          this.loaderService.hide();
          reject(error);
          // this.openWarningDialog(error.error.message);
        }
      );
    });
  }

  createDriver(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loaderService.show();
      this.loadService.createDriver(data).subscribe(res => {
        this.loaderService.hide();
        resolve(data);
      }, error => {
        console.log(error);
        this.loaderService.hide();
        reject(error);
      })
    });
  }

  updateTrailer(data: Array<any>, trailerID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loaderService.show();
      this.loadService.updateTrailer(data, trailerID).subscribe((res) => {
        this.loaderService.hide();
        resolve(res);
      }, (error) => {
        console.log(error);
        this.loaderService.hide();
        reject(error);
      });
    });
  }

  updateVehicle(data: Array<any>, trailerID: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.loaderService.show();
      this.loadService.updateVehicle(data, trailerID).subscribe((res) => {
        this.loaderService.hide();
        resolve(res);
      }, (error) => {
        console.log(error);
        this.loaderService.hide();
        reject(error);
      });
    });
  }

  openWarningDialog(msg: string) {
    const warningIconStyle = {
      stroke: '#FEF0C7',
      width: '48px',
      heigth: '48px',
    };
    const btn2Style = {
      background: '#175CD3',
      color: 'white',
      border: '1px solid #175CD3',
    };

    const dialogData: any = {
      title: 'Warning',
      text: msg,
      button2Text: 'Confirm',
      icon: 'warning-icon',
      iconStyle: warningIconStyle,
    };

    const dialogRef = this.dialogService.openDialog(DialogComponent, {
      props: { ...dialogData, btn2Style },
    });

    dialogRef.subscribe((dialog) => {
      if (!dialog) return;
      switch (dialog.result) {
        case 'button2':
          break;
        default:
          break;
      }
    });
  }

  clearTrialLabels() {
    setTimeout(() => {
      let elements = document.querySelectorAll('.mbsc-schedule-event div ');
      elements.forEach((el) => {
        if (el.textContent === 'TRIAL') {
          el.classList.add('display-none');
        }
      });
    }, 200);
  }

  clearTrialLabelsOnCalendar() {
    setTimeout(() => {
      let elements = document.querySelectorAll('.mbsc-popup div ');
      elements.forEach((el) => {
        if (el.textContent === 'TRIAL') {
          el.classList.add('opacity-none');
        }
      });
    }, 50);
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectStatus(loadId: any, status: string) {
    this.selectedStatus = status;
    this.loadService.changeStatusByLoadId(loadId, status).subscribe(async () => {
      if (this.toolTipImformation.loadId === loadId) {
        this.toolTipImformation.status = this.convertToTitleCase(status)
      }
      this.isDropdownOpen = false;
      await this.getAllLoads(this.timeLineStart);
      this.refreshResources(this.copyDriver);

    });

  }

  convertToTitleCase(status: string): string {
    return status
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

  startClock(): void {
    this.updateTime();
    this.intervalId = setInterval(() => {
      this.updateTime();
    }, 1000);
  }

  updateTime(): void {
    const date = new Date();
    this.browserTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  }

  openDropTotal() {
    this.openDropDownTotal = !this.openDropDownTotal
  }

  private updateLoads(newLoad: any) {
    let existingLoad: any;

    if (newLoad?.load) {
      existingLoad = this.loads.find(load => load?.loadId === newLoad.load?.loadId);
    } else if (newLoad?.note) {
      existingLoad = this.notes.find(note => note?.id === newLoad.note?.id);
    }

    if (existingLoad) {
      Object.assign(existingLoad, newLoad);
    } else {
      if (newLoad?.type === 'Load') {
        this.loads.push(newLoad);
        this.copyLoads.push(newLoad);
      } else if (newLoad?.type === 'Note') {
        this.notes.push(newLoad);
      }
    }
  }

  convertNumber(num: number) {
    return Math.round(num)
  }

  private getHoursAndMinutes(selectedLoad: any) {
    let hours = Math.floor(selectedLoad?.driveTime / 3600);
    let minutes = Math.floor((selectedLoad?.driveTime % 3600) / 60);

    return hours.toString() + 'h' + minutes.toString() + 'm';
  }

}