import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  CdkDragDrop,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import {
  CountryISO,
  SearchCountryField,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input-gg';
import { DialogRef } from '@angular/cdk/dialog';
import { Load } from '../../../types/load';
import { File } from '../../../types/file';
import { LoadsService } from '../../../../../../../services/loads/loads.service';
import { GeometryService } from '../../../../../../../services/geometry/geometry.service';
import { Status } from '../../../../../../../enum/status.enum';
import { GetCarrierIdByNameService } from '../../../../../../../services/serviceForTransformFields/getCarrierIdByName.service';
import { GetDispatcherIdByNameService } from '../../../../../../../services/serviceForTransformFields/getDispatcherIdByName.service';
import { GetDriverNameByIdService } from '../../../../../../../services/serviceForTransformFields/getDriverNameById.service';
import { GetPhoneNumberAndDateInFormArrayService } from '../../../../../../../services/serviceForTransformFields/getPhoneNumberAndDateInFormArray.service';
import L from 'leaflet';
import { convert } from 'geo-coordinates-parser';
import { EmptyMilesRequest } from '../../../../../../../interfaces/loads/emptyMilesRequest';
import { map, Observable, startWith } from 'rxjs';
import { Broker } from '../../../../../../../interfaces/loads/broker';
import { BrokerService } from '../../../../../../../services/brokers/broker.service';
import { ActionsFee } from '../../../../../../../enum/actionsFee';
import { NotificationService } from '../../../../../../../services/notification.service';

@Component({
  selector: 'app-create-modal-tonu',
  templateUrl: './create-modal-tonu.component.html',
  styleUrls: ['./create-modal-tonu.component.scss']
})
export class CreateModalTonuComponent implements OnInit {
  @Input() dispathers: any;
  @Input() start!: any;
  @Input() end!: any;
  @Input() carriers: any[] = [];
  @Input() drivers: any[] = [];
  @Input() loadInfo: any;

  @Output() save = new EventEmitter<void>();
  @Output() isFormValid = new EventEmitter<boolean>();

  form!: FormGroup;
  pIndex: any;
  dIndex: any;
  miles: any;

  visibleIndex: number | null = null;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  setTime: any;
  openType: boolean = false;
  load!: Load;
  loadForCreate!: Load;
  fileLabels: any;
  files: File[] = [];
  result: any;
  milesInputDisable: boolean = false;
  openLocationModal: boolean = false;
  emptyMilesRequest = {} as EmptyMilesRequest;

  brokers!: Broker[];
  filteredBroker: Observable<Broker[]> | undefined;
  typeOfEmptyMiles: string = '';

  map: any;
  openMap: any;
  latitudeAndLongitude: any;

  emptyMilesOptions = ["Last Delivery", "Location", "Manual", "Driver Home"]
  dropdownOpen: boolean = false;
  feeType: any[] = [];
  filteredDrivers: any;

  actionsFee: any;
  actionFeeKeys: any;
  addressSelectedFlags: boolean[] = [];

  constructor(
    public dialogRef: DialogRef<string>,
    private fb: FormBuilder,
    private loadService: LoadsService,
    private geometryService: GeometryService,
    private brokerService: BrokerService,
    private getDriverNameById: GetDriverNameByIdService,
    private getCarrierIdByName: GetCarrierIdByNameService,
    private getDispatcherIdByName: GetDispatcherIdByNameService,
    private getPhoneNumberAndDateInFormArray: GetPhoneNumberAndDateInFormArrayService,
    private notificationService: NotificationService
  ) { }

  //  setVisibleIndex(index: number) {
  //   if (this.visibleIndex === index) {
  //     this.visibleIndex = null;
  //   } else {
  //     this.visibleIndex = index;
  //   }
  // }

  async ngOnInit() {
    this.actionsFee = ActionsFee;
    this.actionFeeKeys = Object.keys(ActionsFee);
    this.filteredDrivers = this.drivers;
    this.initForm();
    this.getReference();
    this.changeSelectedTab(0);
    this.removeFee(0);
    this.form.markAllAsTouched();

    await this.getAllBrokers();

    this.filteredBroker = this.form.get('company')?.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.businessName;
        return name ? this._filterBroker(name as string) : this.brokers.slice();
      }),
    );
  }

  displayFn(broker: Broker): string {
    return broker && broker.businessName ? broker.businessName : '';
  }

  private _filterBroker(name: string): Broker[] {
    if (!name) {
      return this.brokers;
    }
    const filterValue = name.toLowerCase();
    return this.brokers.filter(broker => {
      const businessName = broker.businessName || '';
      return businessName.toLowerCase().includes(filterValue);
    });
  }

  getReference() {
    this.loadService.getReference().subscribe(value => {
      value.forEach((el: any) => {
        if (el.type === 'FeeType') {
          this.feeType.push(el)
        }
      })
    })
  }

  initForm() {
    let selectedDriver = this.drivers.find((driver) => driver.id === this.loadInfo?.driverId)

    this.form = this.fb.group({
      loadId: new FormControl('', Validators.required),
      parentId: new FormControl(''),
      carrier: new FormControl(selectedDriver?.carrierId ? selectedDriver?.carrierId : '', Validators.required),
      driver: new FormControl(selectedDriver?.id ? selectedDriver?.id : '', Validators.required),
      driverId: new FormControl(selectedDriver?.id ? selectedDriver?.id : '', Validators.required),
      dispatcher: new FormControl(selectedDriver?.dispatcherFullName ? selectedDriver?.dispatcherFullName : '', Validators.required),
      phone: new FormControl(''),
      company: new FormControl(''),
      brokerId: new FormControl(''),
      fullAddress: new FormControl(''),
      street: new FormControl(''),
      city: new FormControl(''),
      stateCode: new FormControl(''),
      zipCode: new FormControl(''),
      countryCode: new FormControl(''),
      name: new FormControl(''),
      miles: new FormControl(''),
      loadMiles: new FormControl(''),
      longitude: new FormControl(''),
      latitude: new FormControl(),
      price: new FormControl('', Validators.required),
      email: new FormControl(''),
      pickups: this.fb.array([this.createPickup()]),
      fee: this.fb.array([this.createFee()]),
    });

    this.form.valueChanges.subscribe((change) => {
      this.isFormValid.emit(this.form.valid);
    });
  }
  get fee(): FormArray {
    return this.form.get('fee') as FormArray;
  }

  get pickups(): FormArray {
    return this.form.get('pickups') as FormArray;
  }

  createPickup(): FormGroup {
    const hours = this.start ? this.start.getHours().toString().padStart(2, '0') : null;
    const minutes = this.start ? this.start.getMinutes().toString().padStart(2, '0') : null;
    const time = (hours && minutes) ? `${hours}:${minutes}` : null;

    return this.fb.group({
      businessName: [''],
      fullAddress: ['', Validators.required],
      street: [''],
      city: [''],
      stateCode: [''],
      zipCode: [''],
      countryCode: [''],
      dateScheduledFrom: [this.start ? this.start : null, Validators.required],
      pickupTime: [time, Validators.required],
      dateScheduledTo: [''],
      fullName: [''],
      phone: [''],
      notes: [''],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      email: [''],
    });
  }


  createFee(): FormGroup {
    return this.fb.group({
      feeName: [''],
      feePrice: [''],
      addToGross: [''],
    });
  }

  addPickup() {
    this.pickups.push(this.createPickup());
  }


  addFee() {
    this.openType = true;
    this.fee.push(this.createFee());
  }

  removePickup(index: number) {
    this.pickups.removeAt(index);
    this.changeSelectedTab(index - 1);
  }

  removeFee(index: number) {
    this.fee.removeAt(index);
  }

  handleFileDelete(idx: number) {
    this.files.splice(idx, 1);
  }

  async getAllBrokers() {
    let response = await this.brokerService.getAllBrokers();
    this.brokers = response;
    this.filteredBroker
  }

  getDriverByCarrierId(carrierId: any) {
    this.filteredDrivers = this.drivers.filter(driver => driver.carrierId === carrierId);
    console.log(this.filteredDrivers);
  }

  setSelectedDriverIdInForm(selectedDriverId: number) {
    this.form.get('driverId')?.setValue(selectedDriverId)
  }

  async addInformationAboutBrokerByName(broker: any) {
    for (let i = 0; i < this.brokers.length; i++) {
      if (this.brokers[i].id === broker.id) {
        this.form.get('company')?.setValue(this.brokers[i].businessName);
        this.form.get('brokerId')?.setValue(this.brokers[i].id);
        this.form.get('email')?.setValue(this.brokers[i].email);
        this.form.get('name')?.setValue(this.brokers[i].fullName);
        this.form.get('phone')?.setValue(this.brokers[i].phone);
        this.form
          .get('fullAddress')
          ?.setValue(this.makeFullAddress(this.brokers[i]));
        break;
      } else this.clearBroker();
    }
  }

  makeFullAddress(broker: Broker) {
    let fullAddress = broker.street ? broker.street : '' + "," +
      broker.city ? broker.city : '' + "," +
        broker.stateCode ? broker.stateCode : '' + "," +
          broker.zipCode ? broker.zipCode : '' + "," +
            broker.countryCode ? broker.countryCode : '';
    return fullAddress;
  }

  selectOption(selectedEmptyMilesOption: any) {
    let selectedOption = this.changedSelectedEmptyMilesOptionToCamelCase(selectedEmptyMilesOption);
    this.typeOfEmptyMiles = selectedOption;
    this.dropdownOpen = false;
    switch (selectedOption) {
      case 'lastDelivery':
        this.calcEmptyMilesByDriversHomeLastDelivery(selectedOption);
        break;
      case 'location':
        this.openDialog(selectedOption);
        break;
      case 'manual':
        this.setManualyEmptyMIles();
        break;
      case 'driverHome':
        this.calcEmptyMilesByDriversHomeLastDelivery(selectedOption);
        break;
    }
  }

  changedSelectedEmptyMilesOptionToCamelCase(selectedEmptyMilesOption: string) {
    return selectedEmptyMilesOption.split(' ').reduce(
      (acc, word, index) =>
        acc +
        (index === 0
          ? word.toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()),
      ''
    );
  }

  openDropDownEmptyMiles() {
    this.dropdownOpen = !this.dropdownOpen
  }

  openTimePicker(id: string) {
    let time = document.getElementById(id) as HTMLInputElement
    time?.showPicker();
  }

  changeSelectedTab(index: any) {
    // setTimeout(() => this.createAddress());
    this.pIndex = index;
    setTimeout(() => {
      this.geometryService.findAddress(
        'google-search' + 'tp' + this.pIndex,
        this.pickups.controls[this.pIndex]
      ).then(() => {
        this.addressSelectedFlags[this.pIndex] = true;
      })
    });
  }

  onBlurMethodPickup() {
    // setTimeout(() => {
    //   const pickups = this.pickups.value;
    //   for (let i = 0; i < pickups.length; i++) {
    //     if (!this.addressSelectedFlags[i]) {
    //       this.notificationService.warning(
    //         "Warning",
    //         "Select an address from the drop-down list or paste the coordinates directly for pickup location"
    //       );
    //       break;
    //     }
    //   }
    // }, 500);
  }

  onInputChange(index: number) {
    this.addressSelectedFlags[index] = false;
  }

  createAddress() {
    this.pickups.controls.forEach((item: any, i: number) => {
      this.addressSelectedFlags[i] = false;
      this.geometryService.findAddress('google-search' + 'tp' + i, item).then(() => {
        this.addressSelectedFlags[i] = true;
      });
    });
    this.geometryService.findAddress('google-search' + 'bt', this.form);

    this.getLocations();
  }

  async calcEmptyMilesByDriversHomeLastDelivery(type: string) {
    this.milesInputDisable = false
    let pickup = this.pickups.controls;
    this.emptyMilesRequest.type = type;
    this.emptyMilesRequest.firstPickupLatitude = pickup[0].get('latitude')?.value;
    this.emptyMilesRequest.firstPickupLongitude = pickup[0].get('longitude')?.value;
    this.emptyMilesRequest.driverId = this.form.get('driverId')?.value;
    let emptyMilesLastDelivery = await this.loadService.setAndGetEmptyMiles(this.emptyMilesRequest)
    this.form.get('miles')?.setValue(emptyMilesLastDelivery.distance.toFixed(2))
    this.form.get('longitude')?.setValue(emptyMilesLastDelivery.longitude)
    this.form.get('latitude')?.setValue(emptyMilesLastDelivery.latitude)
  }

  async openDialog(type: string) {
    this.openLocationModal = true
    let selectedType = type;
    this.milesInputDisable = false
    this.emptyMilesRequest.type = selectedType;
  }

  async getEmitCoors(event: any) {
    let [latitude, longitude] = event.split(',')
    let pickup = this.pickups.controls;
    this.emptyMilesRequest.firstPickupLatitude = pickup[0].get('latitude')?.value;
    this.emptyMilesRequest.firstPickupLongitude = pickup[0].get('longitude')?.value;
    this.emptyMilesRequest.targetLatitude = Number(latitude);
    this.emptyMilesRequest.targetLongitude = Number(longitude);
    this.openLocationModal = false;
    let emptyMilesLastDelivery = await this.loadService.setAndGetEmptyMiles(this.emptyMilesRequest)
    this.form.get('miles')?.setValue(emptyMilesLastDelivery.distance.toFixed(2))
    this.form.get('longitude')?.setValue(emptyMilesLastDelivery.longitude)
    this.form.get('latitude')?.setValue(emptyMilesLastDelivery.latitude)
  }

  closeModalWithCoors(event: any) {
    this.openLocationModal = event.value;
  }

  setManualyEmptyMIles() {
    this.milesInputDisable = true
  }

  private async getLocations() {
    const pickups = this.form.get('pickups')?.value || [];
    for (const el of [...pickups]) {
      if (!el.countryCode) {
        const regex = /^(.+),\s*(.+),\s*([A-Z]{2})\s*([\d-]+)?,\s*(.+)$/;
        const match = el.fullAddress?.match(regex);
        if (match) {
          const streetAddress = match[1];
          const city = match[2];
          const state = match[3];
          const zipCode = match[4];
          const country = match[5];

          el.street = streetAddress;
          el.city = city;
          el.zipCode = zipCode;
          el.stateCode = state;
          el.countryCode =
            country === 'США' ? 'USA' : country;
        }

        // try {
        //   const res: any = await firstValueFrom(this.geometryService.getPosition(el));
        //   el.latitude = res.bbox[1]
        //   el.longitude = res.bbox[0]
        // } catch (error) {
        //   console.log(error);
        // }
      }
    }
  }

  openTypes() {
    this.openType = true;
  }

  drop(event: CdkDragDrop<any[]>, type: string) {
    let controls: FormArray;
    let timeField: string;

    controls = this.pickups;
    timeField = 'pickupTime';


    const prevIndex = controls.controls.findIndex((item) => item === event.item.data);
    const newIndex = event.currentIndex;

    const previousItem = controls.at(prevIndex).value;
    const currentItem = controls.at(newIndex).value;

    if (previousItem[timeField] > currentItem[timeField] && prevIndex < newIndex ||
      previousItem[timeField] < currentItem[timeField] && prevIndex > newIndex) {

      const copyPreviousItem = JSON.parse(JSON.stringify(previousItem));
      const copyCurrentItem = JSON.parse(JSON.stringify(currentItem));

      previousItem[timeField] = copyCurrentItem[timeField];
      previousItem.dateScheduledFrom = copyCurrentItem.dateScheduledFrom;
      previousItem.dateScheduledTo = copyCurrentItem.dateScheduledTo;

      currentItem[timeField] = copyPreviousItem[timeField];
      currentItem.dateScheduledFrom = copyPreviousItem.dateScheduledFrom;
      currentItem.dateScheduledTo = copyPreviousItem.dateScheduledTo;

      controls.setControl(prevIndex, this.fb.group(currentItem));
      controls.setControl(newIndex, this.fb.group(previousItem));
    } else {
      const movedItem = controls.at(prevIndex);
      controls.removeAt(prevIndex);
      controls.insert(newIndex, movedItem);
    }

    console.log(controls.controls);
  }

  trackByFn(index: number, item: any): number {
    return index;
  }

  saveTonu(): Promise<void> {
    return new Promise((resolve, reject) => {

      if (resolve) {
        this.load = this.formToLoad();
        try {
          this.loadService.createLoad(this.load).subscribe(() => {
            this.dialogRef.close();
            this.save.emit();
            resolve();
          });
        } catch (err) {
          console.error(err);
        }
      } else {
        reject(new Error('Ошибка сохранения'));
      }
    });

  }

  formToLoad() {
    let deliveryLength = this.form.get('delivery')?.value;
    let driverName = this.getDriverNameById.getName(this.drivers, this.form.get('driver')?.value);
    let carrierName = this.getCarrierIdByName.getName(this.carriers, this.form.get('carrier')?.value);
    let dispatcherId = this.getDispatcherIdByName.get(this.dispathers, this.form.get('dispatcher')?.value);

    let pickupsArray = this.getPhoneNumberAndDateInFormArray.get(this.form.get('pickups') as FormArray);

    let load: Load = {
      customLoadId: this.form.get('loadId')?.value,
      parentId: null,
      broker: {
        businessName: this.form.get('company')?.value ? this.form.get('company')?.value : null,
        fullName: this.form.get('name')?.value,
        id: this.form.get('brokerId')?.value || 0,
        email: this.form.get('email')?.value,
        phone: this.getPhoneNumberByObject(this.form.get('phone')?.value),
        street: this.form.get('street')?.value,
        city: this.form.get('city')?.value,
        stateProvinceCode: '',
        countryCode: this.form.get('countryCode')?.value,
        zipCode: this.form.get('zipCode')?.value,
        notes: this.form.get('notes')?.value,
        statusCode: '',
        statusName: '',
      },
      brokerFee: '',
      booking: this.form.get('booking')?.value,
      geometry: '',
      delivery: [],
      driveTime: 0,
      driverId: this.form.get('driver')?.value,
      driverName: driverName,
      emptyMiles: {
        geometry: '',
        distance: this.form.get('miles')?.value,
        longitude: 0,
        latitude: 0,
        type: this.typeOfEmptyMiles,
      },
      files: this.files,
      history: null,
      fees: this.form.get('fee')?.value,
      feeIncludesInPrice: null,
      insertDate: '',
      loadId: 0,
      pickup: pickupsArray,
      price: this.form.get('price')?.value,
      status: 'TONU',
      loadMiles: 0,
      carrierId: this.form.get('carrier')?.value,
      carrierName: carrierName,
      dispatcher: {
        id: Number(dispatcherId),
        dispatcherFullName: this.form.get('dispatcher')?.value,
      },
      loadType: 'Load',
      partial: [],
      dateTimeLineBgn: this.form.get('pickups')?.value[0].dateScheduledFrom,
      dateTimeLineEnd:
        this.form.get('delivery')?.value[deliveryLength.length - 1]
          .dateScheduledFrom,

    };
    console.log(load)
    return load;
  }

  getPhoneNumberByObject(obj?: any) {
    if (obj && (typeof obj) !== 'string') {
      let phone = obj.number;
      return phone;
    }
    return '';
  }

  handleFileChange(files: Array<File>) {
    this.files = files;
  }

  addCoordinates() {
    this.openMap = true
    setTimeout(() => this.initMap())
  }

  initMap() {
    this.setViewMap()
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy;'
    }).addTo(this.map)
  }

  setViewMap() {
    if (this.map) {
      this.map.remove();
    }
    this.map = L.map('map', {
      center: [39, -104],
      zoom: 4
    })
  }

  manualInputOfCoordinates() {
    if (this.latitudeAndLongitude.includes(',')) {
      let [latitude, longitude] = this.latitudeAndLongitude.split(',')
      L.marker([Number(latitude), Number(longitude)]).addTo(this.map);
    } else {
      let splitedCoordinates = convert(this.latitudeAndLongitude)
      L.marker([Number(splitedCoordinates.decimalLatitude), Number(splitedCoordinates.decimalLongitude)]).addTo(this.map);
    }
  }

  addCoordinatesInForm() {
    let [longitude, latitude] = this.latitudeAndLongitude.split(',');
    const pickupFormGroup = this.pickups.at(this.pIndex) as FormGroup;
    pickupFormGroup.patchValue({
      fullAddress: this.latitudeAndLongitude,
      latitude: Number(latitude),
      longitude: Number(longitude),
    });
    this.openMap = false;
  }

  setMarketForCoordinates() {
    this.map.on('click', (event: any) => {
      this.clearMap()
      L.marker([event.latlng.lat, event.latlng.lng]).addTo(this.map);
      if (event) {
        this.latitudeAndLongitude = event.latlng.lat && event.latlng.lng ? `${event.latlng.lat},${event.latlng.lng}` : '';
      }
    })
  }

  clearMap() {
    this.map.eachLayer((layer: any) => {
      if (layer instanceof L.Polyline || layer instanceof L.Marker) {
        this.map.removeLayer(layer);
      }
    });
  }

  brokerInput(event: any) {
    const val = event.target.value;

    if (!Array.isArray(this.brokers)) {
      return;
    }

    let obj = this.brokers.filter(
      (f) => f.businessName && f.businessName.includes(val)
    );

    if (obj.length === 0) {
      this.form.get('brokerId')?.setValue(0);
      this.form.get('email')?.setValue(null);
      this.form.get('name')?.setValue(null);
      this.form.get('phone')?.setValue(null);
      this.form.get('fullAddress')?.setValue(null);
    }
  }

  clearBroker() {
    this.form.get('company')?.setValue(null);
    this.form.get('brokerId')?.setValue(null);
    this.form.get('email')?.setValue(null);
    this.form.get('name')?.setValue(null);
    this.form.get('phone')?.setValue(null);
    this.form.get('fullAddress')?.setValue(null);
  }
}