<div class="main-containet-local">
  <div>
    <div class="input-container">
      <div style="width: auto; display: flex; justify-content: space-between;" class="block-input-icon">
        <mat-icon matSuffix><img src="../../../../../../../../assets/svg/location-on-img.svg" alt=""></mat-icon>
        <div style="width: 400px;">
          <input id="google-search-empty-miles" [(ngModel)]="emptyMilesCoors" required
            placeholder="Ex: 7128 Tavita St, Las Vegas, NV 89113, USA*" type="text">
        </div>
        <div>
          <button class="coordinates-btn" cdkFocusInitial (click)="addCoordinates()">Add
            coordinates</button>
        </div>
      </div>
    </div>
    <div class="footer-btn">
      <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      <button class="save-btn" (click)="addSelectedCoorsOrAdress()">Save</button>
    </div>
  </div>
  <div *ngIf="openMap" class="map-frame">
    <div class="search-container">
      <strong>Search coordinates</strong>
      <div style="display: flex; justify-content: space-around; gap: 12px;">
        <div class="block-input-icon">
          <input type="text" placeholder="Enter coordinates" [(ngModel)]="emptyMilesCoors"
            (input)="manualInputOfCoordinates()">
          <mat-icon matSuffix><img src="../../../../../../../../assets/svg/search-img.svg"></mat-icon>
        </div>
        <div>
          <button class="save-crd-btn" (click)="closeMap()">Save</button>
        </div>
      </div>
    </div>
    <div (click)="setMarketForCoordinates()" class="full" id="map"></div>
  </div>
</div>