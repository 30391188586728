import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TimelineRoutingModule } from './timeline-routing.module';
import { MbscModule } from '@mobiscroll/angular';
import { FilterEventsBySearchKeyPipe } from '../../../../pipes/filter-events-by-search-key/filter-events-by-search-key.pipe';
import { FilterResourcesBySearchKeyPipe } from '../../../../pipes/filter-resources-by-search-key/filter-resources-by-search-key.pipe';
import { SortDriversAlphabeticallyPipe } from '../../../../pipes/sort-drivers-alphabetically/sort-drivers-alphabetically.pipe';
import { FilterDriverByCarrierPipe } from '../../../../pipes/filter-driver-by-carrier.pipe';
import { ActiveDriversPipe } from '../../../../pipes/active-drivers.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectCountryModule } from "@angular-material-extensions/select-country";
import { MatTreeModule } from '@angular/material/tree';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { TimeLineHeaderComponent } from './time-line-header/time-line-header.component';
import { TimeLineFilterComponent } from './time-line-filter/time-line-filter.component';
import { MatInput } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { TimeLineCalendarComponent } from './time-line-calendar/time-line-calendar.component';
import { MatMenu } from '@angular/material/menu';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { CreateModalAllComponent } from './modal/create-modal-all/create-modal-all.component';
import { CreateModalLoadComponent } from './modal/create-modal-all/create-modal-load/create-modal-load.component';
import { CreateModalTonuComponent } from './modal/create-modal-all/create-modal-tonu/create-modal-tonu.component';
import { CreateModalNoteComponent } from './modal/create-modal-all/create-modal-note/create-modal-note.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ViewEditLoadComponent } from './modal/edit/view-edit-load/view-edit-load.component';
import { EditLoadComponent } from './modal/edit/view-edit-load/load/edit-load/edit-load.component';
import { EditSplitComponent } from './modal/edit/view-edit-load/split/edit-split/edit-split.component';
import { LocationEmptyMilesComponent } from './modal/create-modal-all/location-empty-miles/location-empty-miles.component';
import { ViewDeliveryInfoComponent } from './modal/edit/view-edit-load/load/edit-load/view-delivery-info/view-delivery-info.component';
import { TimelineComponent } from './timeline.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TooltipComponent } from '../../../../components/popover/popover.component';
import { DriverNotePopupComponent } from './modal/driver-note-popup/driver-note-popup.component';
import { TargetPopupComponent } from './modal/target-popup/target-popup.component';
import { EditBrokerInfoComponent } from './components/edit-broker-info/edit-broker-info.component';
import { SharedFileUploaderModule } from '../../../../components/file-uploader/shared.file-uploader-module.ts';
import { TooltipModule } from '../../../../directives/tooltip.module';
import { AlertComponent } from '../../../../components/alert/alert.component';
import { ScrollingModule } from '@angular/cdk/scrolling';

const PIPES = [
  FilterResourcesBySearchKeyPipe,
  FilterEventsBySearchKeyPipe,
  FilterDriverByCarrierPipe,
  SortDriversAlphabeticallyPipe,
  ActiveDriversPipe,
];

const MATERIAL = [
  MatIconModule,
  MatTreeModule,
  MatButtonModule,
  MatFormField,
  MatAutocompleteModule,
  MatLabel,
  MatTooltipModule,
  MatFormField,
  MatInput,
  MatSelectModule,
  MatCardModule,
  MatMenu,
  MatMenuModule,
  MatExpansionModule,
  MatCheckboxModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  DragDropModule,
  MatTabsModule,
  MatSlideToggleModule,
];
@NgModule({
  declarations: [
    TimelineComponent,
    TimeLineFilterComponent,
    TimeLineHeaderComponent,
    TimeLineCalendarComponent,
    CreateModalLoadComponent,
    CreateModalAllComponent,
    CreateModalTonuComponent,
    CreateModalNoteComponent,
    ViewEditLoadComponent,
    EditLoadComponent,
    EditSplitComponent,
    ViewDeliveryInfoComponent,
    LocationEmptyMilesComponent,
    DriverNotePopupComponent,
    TargetPopupComponent,
    EditBrokerInfoComponent,
    [...PIPES],
  ],
  imports: [
    TooltipModule,
    CommonModule,
    TimelineRoutingModule,
    MbscModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    NgxMatTimepickerModule,
    SharedFileUploaderModule,
    ScrollingModule,
    AngularSvgIconModule.forRoot(),
    MatSelectCountryModule.forRoot('en'),
    [...MATERIAL],
  ],
  exports: [],
  providers: [MatDatepickerModule],
})
export class TimelineModule { }