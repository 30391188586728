interface LoadStatusColor {
    [key: string]: string;
}
interface LoadStatusColorForBackGround {
    [key: string]: string;
}

export const loadColors: LoadStatusColor = {
    NEW: '#648DF5',
    ASSIGNED: '#175CD3',
    UNASSIGNED: '#D0D7DE',
    EN_ROUTE: '#777737',
    PICKED_UP: ' #833AB4',
    DELIVERED: '#0F9D58',
    BILLED: '#C39204',
    PAID: '#32C75F',
    ARCHIVED: '#e2c6fc',
    TONU: '#D92D20',
    HOME: '#8C9AAB',
    SHOP: '#d1d1d1',
    NOTE: '#FCAF45',
    DELETED: 'red',
    OTHER: '#d1d1d1'
};

export const loadColorsForBackground: LoadStatusColorForBackGround = {
    New: '#648DF5',
    Assigned: '#175CD3',
    Unassigned: '#D0D7DE',
    "En Route": '#777737',
    "Picked Up": '#833AB4',
    Delivered: '#0F9D58',
    Billed: '#C39204',
    Paid: '#32C75F',
    Archived: '#e2c6fc',
    Tonu: '#D92D20',
    Home: '#8C9AAB',
    Shop: '#d1d1d1',
    Note: '#FCAF45',
    Deleted: 'red',
    Other: '#d1d1d1'
}