import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { File } from '../../modules/main/pages/timeline/types/file';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnChanges {
  private _files: Array<File> = new Array<File>();

  @Input() set files(value: Array<File> | null) {
    this._files = value || []; 
  }

  get files(): Array<File> {
    return this._files;
  }

  @Output() filesChanged = new EventEmitter<Array<File>>(); 

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['files'] && !changes['files'].isFirstChange()) {
      this._files = changes['files'].currentValue || [];
    }
  }

  handleFileChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length) {
      const file = inputElement.files[0];
      if (file.size > 20 * 1024 * 1024) {
        alert('File size exceeds the limit of 20MB');
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const fileData = String(reader.result);
        const mimeStartIndex = fileData.indexOf('data:') + 5;
        const mimeEndIndex = fileData.indexOf(';base64');
        const mime = fileData.substring(mimeStartIndex, mimeEndIndex);
        const dataStartIndex = fileData.indexOf(';base64') + 8;
        const fileContent = fileData.substring(dataStartIndex);

        const newFile: File = {
          fileId: 0,
          fileLink: '',
          fileName: file.name,
          fileContent: fileContent,
          fileMime: mime,
          fileLabel: '',
        };

        this._files.push(newFile);
        this.filesChanged.emit(this._files);
      };
      reader.readAsDataURL(file);
    }
  }
  
  previewFile(file: File) {
    window.open(file.fileLink, '_blank');
  }
  
  downloadFile(file: File) {
    // Create a Blob object from the file content
    const byteString = window.atob(file.fileContent); // Decode base64 content
    const byteArray = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([byteArray], { type: file.fileMime });

    // Create a link element to download the file
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = file.fileName; // Set the file name
    a.click(); // Trigger the download
    window.URL.revokeObjectURL(a.href); // Clean up URL object
  }

  deleteFile(index: number): void {
    this._files.splice(index, 1);
    this.filesChanged.emit(this._files);
  }
}
