import { Component, EventEmitter, HostListener, OnInit, Output, } from '@angular/core';
import { SharedTimeService } from '../../../../../services/sharedServices/shared-time.service';
import { SettingsComponent } from '../../../../../interfaces/settings-component';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { DATE_FORMATS } from '../types/date-format';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { StorageService, StorageTempate } from '../../../../../services/storage/storage.service';


const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-time-line-header',
  templateUrl: './time-line-header.component.html',
  styleUrls: ['./time-line-header.component.css'],
  providers: [
    provideMomentDateAdapter(DATE_FORMATS),
  ],
})
export class TimeLineHeaderComponent implements OnInit {
  @Output() settingsConfirmed = new EventEmitter<any>();

  tempSettingsData: SettingsComponent = {
    showLoadId: true,
    showPricePerLoad: true,
    showMiles: true,
    showRatePerMiles: true,
    hideTotalBy: false,
    hideUnassignedLoads: false,
    showTruck: false,
    showTrailer: false,
  };

  settingsData!: SettingsComponent;

  disabledBtns: any;
  timelineSize: any;
  openSettings: boolean = false;

  start = moment();
  end = moment();

  constructor(
    private sharedTimeService: SharedTimeService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.saveSettings();
    this.getStorage();
  }

  setMonday(arg0: number | string) {
    this.openSettings = false;
    this.timelineSize = arg0.toString();
    this.sharedTimeService.setTimeForTimeline(arg0);
  }

  shiftTimeline(arg0: number) {
    this.openSettings = false;
    this.sharedTimeService.setShiftTime(arg0);
  }

  setMonth() {
    this.openSettings = false;
    this.timelineSize = 'month';
    this.sharedTimeService.setTimeForMonth('month');
  }

  openSettingModal() {
    this.openSettings = true;
  }

  onStartDateChange(event: any) {
    this.openSettings = false;
    this.start = event.value;
    this.storageService.updateStorageField('start', this.start)

  }

  onEndDateChange(event: any) {
    this.openSettings = false;
    this.end = event.value;
    this.storageService.updateStorageField('end', this.end)
    this.sharedTimeService.setStartAndEndTime(
      this.start,
      this.end
    );
  }

  closeSettingModal() {
    this.openSettings = false;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    const clickedInside = (event.target as HTMLElement).closest('.timeline-navigate-group');
    if (!clickedInside) {
      this.openSettings = false;
    }
  }

  getStorage() {
    let storage = this.storageService.getStorage();
    if (!storage) return;
    if (storage?.start !== '' && storage?.end !== '') {
      this.start = storage?.start
      this.end = storage?.end
      this.sharedTimeService.setStartAndEndTime(
        storage?.start,
        storage?.end
      );
      this.sharedTimeService.startDateTimeString.subscribe(start => {
        this.start = start;
      });
      this.sharedTimeService.endtDateTimeString.subscribe(end => {
        this.end = end;
      });

    } else {
      this.sharedTimeService.setTimeForTimeline('Current week');
      this.sharedTimeService.setStartAndEndTime(
        storage?.start,
        storage?.end
      );
      this.sharedTimeService.startDateTimeString.subscribe(start => {
        this.start = start;
      });
      this.sharedTimeService.endtDateTimeString.subscribe(end => {
        this.end = end;
      });
    }

    this.tempSettingsData = storage?.timeLineSettings
    this.settingsConfirmed.emit(this.tempSettingsData);
  }

  toggleSettings(value: string) {
    switch (value) {
      case 'loadId':
        this.tempSettingsData.showLoadId = !this.tempSettingsData.showLoadId;
        break;
      case 'pricePerLoad':
        this.tempSettingsData.showPricePerLoad = !this.tempSettingsData.showPricePerLoad;
        break;
      case 'miles':
        this.tempSettingsData.showMiles = !this.tempSettingsData.showMiles;
        break;
      case 'ratePerMiles':
        this.tempSettingsData.showRatePerMiles = !this.tempSettingsData.showRatePerMiles;
        break;
      case 'hideTotalBy':
        this.tempSettingsData.hideTotalBy = !this.tempSettingsData.hideTotalBy;
        break;
      case 'hideUnassignedLoads':
        this.tempSettingsData.hideUnassignedLoads = !this.tempSettingsData.hideUnassignedLoads;
        break;
      case 'showTruck':
        this.tempSettingsData.showTruck = !this.tempSettingsData.showTruck;
        break;
      case 'showTrailer':
        this.tempSettingsData.showTrailer = !this.tempSettingsData.showTrailer;
        break;
      default:
        console.warn(`Unknown setting: ${value}`);
    }

    this.storageService.updateStorageField('timeLineSettings', this.tempSettingsData);
  }

  saveSettings(): void {
    let storage = this.storageService.getStorage()
    if (!storage) {
      const newStorage: StorageTempate = {
        start: this.start,
        end: this.end,
        selectedSortingByDriver: -1,
        selectedSortingByDispather: -1,
        companySort: [],
        timeLineSettings: {
          showLoadId: true,
          showPricePerLoad: true,
          showMiles: true,
          showRatePerMiles: true,
          hideTotalBy: false,
          hideUnassignedLoads: false,
          showTruck: false,
          showTrailer: false,
        },

      };

      this.storageService.saveStorage(newStorage);
    }
  }
}
