import { Pipe, PipeTransform } from '@angular/core';
import { Resource } from '../../modules/main/pages/timeline/types/resource';

@Pipe({
  name: 'filterResourcesBySearchKey'
})
export class FilterResourcesBySearchKeyPipe implements PipeTransform {

  transform(resources: Resource[], key: number): Resource[] {
    if (!resources) return []

    if (!resources.length || !key) {
      return resources
    }

    return resources.filter(el => {
      const filteredLoads = el.allLoads.filter(item => item.loadId === key)
      return filteredLoads.length ? true : false
    })
  }

}
