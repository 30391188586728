import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetDriverNameByIdService {

  constructor() { }

  getName(drivers: any, driverId: number) {
    let driverName = '';
    drivers.forEach((value: any) => {
      if (value.id === driverId) {
        driverName = value.driverName;
      }
    });
    return driverName
  };

  getId(drivers: any, driverName: string) {
    let driverId = 0;
    drivers.forEach((value: any) => {
      if (value.driverFullName === driverName) {
        driverId = value.id;
      }
    });
    console.log(driverId);

    return driverId
  };
}
