<div class="delivery-main">
  <div style="display: flex; justify-content: space-between;">
    <div style="display: flex; align-items: center; gap: 16px;">
      <div class="container-icon">
        <mat-icon><img src="../../../../../../../../../../../assets/svg/directions-car-img.svg" alt=""></mat-icon>
      </div>
      <div>
        <strong class="view-delivery-strong">{{'Delivery Information '}}</strong>
        <p>Lorem Ipsum is simply dummy text of the printing</p>
      </div>
    </div>
  </div>
  <form [formGroup]="form">
    <div style="display: flex; justify-content: space-between; margin: 12px 0;">
      <div style="display: flex;">
        <div style="margin: 5px" *ngFor="let item of delivery.controls; let i = index" cdkDropList
          cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event,'delivery')">
          <button class="delivery-btn" cdkDrag (click)="changeSelectedTab(i)"
            [ngClass]="{'active-btn-delivery': dIndex === i}">{{'Delivery'}}{{i + 1}}</button>
        </div>
        <button class="pick-btn" (click)="addDelivery()"><img
            src="../../../../../../../../assets/svg/add-circle-outline-img.svg" alt="">{{'Add Delivery'}}</button>
      </div>
      <div style="display: flex;">
        <mat-icon class="remove-icon" *ngIf="delivery.controls.length > 1" (click)="removeDelivery(dIndex)"><img
            src="../../../../../../../assets/svg/remove-img.svg"></mat-icon>
      </div>
    </div>
    <div style="display: flex; justify-content: space-between; align-items: center;"></div>
    <div formArrayName="delivery">
      <div *ngFor="let delivery of delivery?.controls; let i = index" [formGroupName]="i">
        <div *ngIf="dIndex === i">
          <div class="input-container">
            <div class="block-inputs">
              <label>Shipper</label>
              <input formControlName="businessName" type="text" placeholder="Business Name" />
            </div>
            <div class="block-inputs">
              <label>Phone Number</label>
              <ngx-intl-tel-input style="width: 100%" [preferredCountries]="[CountryISO.UnitedStates]"
                [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                [searchCountryField]="[
                  SearchCountryField.Iso2,
                  SearchCountryField.Name
                ]" [selectFirstCountry]="true" [selectedCountryISO]="CountryISO.UnitedStates" [separateDialCode]="true"
                [maxLength]="15" [phoneValidation]="true" name="phone" formControlName="phone"></ngx-intl-tel-input>
            </div>
          </div>
          <div class="input-container">
            <div style="width: 100%" class="block-input-icon">
              <label>Location</label>
              <mat-icon><img src="/../../../assets/svg/location-on-img.svg" alt="" /></mat-icon>
              <input formControlName="fullAddress" [id]="'google-search' + inputDelivery + i" required
                placeholder="Ex: 7128 Tavita St, Las Vegas, NV 89113, USA*" type="text" />
            </div>
          </div>
          <div class="input-container">
            <div class="block-input-icon">
              <label>Scheduled</label>
              <mat-datepicker-toggle class="date-picker-icon" matPrefix [for]="picker"><mat-icon
                  matDatepickerToggleIcon>date_range</mat-icon></mat-datepicker-toggle>
              <input class="date-picker-input" style="margin: 0 0 3px 5px" formControlName="dateScheduledFrom"
                [matDatepicker]="picker" placeholder="Delivery Date" />
              <mat-datepicker #picker></mat-datepicker>
            </div>
            <div class="block-input-icon">
              <label>From</label>
              <div class="centered">
                <img (click)="openTimePicker('deliveryTime')"
                  src="../../../../../../../../assets/svg/access-time-img.svg" alt="icon" />
                <input class="custom-time-input" formControlName="deliveryTime" id="deliveryTime" type="time"
                  placeholder="Delivery Time">
              </div>
            </div>
            <div class="block-input-icon">
              <label>To</label>
              <div class="centered">
                <img (click)="openTimePicker('deliverydateScheduledTo')"
                  src="../../../../../../../../assets/svg/access-time-img.svg" alt="">
                <input class="custom-time-input" formControlName="dateScheduledTo" id="deliverydateScheduledTo"
                  type="time" placeholder="To">
              </div>
            </div>
          </div>
          <div class="input-container">
            <div class="block-input-icon">
              <label>Full Name</label>
              <mat-icon matPreffix><img src="/../../../assets/svg/person-img.svg" /></mat-icon>
              <input formControlName="fullName" type="text" placeholder="Full Name" />
            </div>
            <div class="block-input-icon">
              <label>Email</label>
              <mat-icon matPrefix><img src="/../assets/svg/email-img.svg" /></mat-icon>
              <input style="margin: 3px 0 3px 5px" formControlName="email" placeholder="Email" type="text" />
            </div>
          </div>
          <div class="input-container">
            <mat-form-field appearance="outline" class="custom-multi-input-cont full-width-input">
              <mat-label>Notes</mat-label>
              <input matInput formControlName="notes" type="text" maxlength="85" placeholder="Add Notes">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="footer-btn">
    <button class="cancel-btn" (click)="cancel()">Cancel</button>
    <button class="save-btn" (click)="confirm()">Confirm</button>
  </div>
</div>