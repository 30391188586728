import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, Observable, startWith } from 'rxjs';
import { Broker } from '../../../../../../interfaces/loads/broker';
import {
  CountryISO,
  SearchCountryField,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input-gg';
import { EditLoadComponent } from '../../modal/edit/view-edit-load/load/edit-load/edit-load.component';
import { GeometryService } from '../../../../../../services/geometry/geometry.service';

@Component({
  selector: 'app-edit-broker-info',
  templateUrl: './edit-broker-info.component.html',
  styleUrls: ['./edit-broker-info.component.css']
})
export class EditBrokerInfoComponent implements OnInit {

  form!: FormGroup;
  filteredBroker: Observable<Broker[]> | undefined;
  brokers: any;

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: EditLoadComponent,
    public dialogRef: MatDialogRef<EditBrokerInfoComponent>,
    public geometryService: GeometryService
  ) {

  }

  ngOnInit() {
    this.brokers = this.data.brokers;
    this.initForm();
    this.filteredBroker = this.form.get('company')?.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.businessName;
        return name ? this._filterBroker(name as string) : this.brokers.slice();
      })
    );

    setTimeout(() => {
      this.geometryService.findAddress(
        'google-search' + 'eb',
        this.form
      );
    });

  }

  initForm() {
    this.form = this.fb.group({
      phone: new FormControl(''),
      company: new FormControl(''),
      brokerId: new FormControl('0'),
      fullAddress: new FormControl(''),
      street: new FormControl(''),
      city: new FormControl(''),
      stateCode: new FormControl(''),
      zipCode: new FormControl(''),
      countryCode: new FormControl(''),
      name: new FormControl(''),
      miles: new FormControl(''),
      longitude: new FormControl(''),
      latitude: new FormControl(),
      email: new FormControl(''),
    });
  }

  private _filterBroker(name: string): Broker[] {
    const filterValue = name.toLowerCase();
    return this.brokers.filter((broker: any) =>
      broker.businessName?.toLowerCase().includes(filterValue)
    );
  }

  async addInformationAboutBrokerByName(broker: any) {
    for (let i = 0; i < this.brokers.length; i++) {
      if (this.brokers[i].id === broker.id) {
        this.form.get('company')?.setValue(this.brokers[i].businessName);
        this.form.get('brokerId')?.setValue(this.brokers[i].id);
        this.form.get('email')?.setValue(this.brokers[i].email);
        this.form.get('name')?.setValue(this.brokers[i].fullName);
        this.form.get('phone')?.setValue(this.brokers[i].phone);
        this.form
          .get('fullAddress')
          ?.setValue(this.makeFullAddress(this.brokers[i]));
        break;
      } else this.clearBroker();
    }
  }

  brokerInput(event: any) {
    const val = event.target.value;

    if (!Array.isArray(this.brokers)) {
      return;
    }

    let obj = this.brokers.filter(
      (f) => f.businessName && f.businessName.includes(val)
    );

    if (obj.length === 0) {
      this.form.get('brokerId')?.setValue(0);
      this.form.get('email')?.setValue(null);
      this.form.get('name')?.setValue(null);
      this.form.get('phone')?.setValue(null);
      this.form.get('fullAddress')?.setValue(null);
    }
  }

  clearBroker() {
    this.form.get('company')?.setValue(null);
    this.form.get('brokerId')?.setValue(null);
    this.form.get('email')?.setValue(null);
    this.form.get('name')?.setValue(null);
    this.form.get('phone')?.setValue(null);
    this.form.get('fullAddress')?.setValue(null);
  }

  makeFullAddress(broker: Broker) {
    let fullAddress = broker.street
      ? broker.street
      : '' + ',' + broker.city
        ? broker.city
        : '' + ',' + broker.stateCode
          ? broker.stateCode
          : '' + ',' + broker.zipCode
            ? broker.zipCode
            : '' + ',' + broker.countryCode
              ? broker.countryCode
              : '';
    return fullAddress;
  }



  cancel() {
    this.dialogRef.close()
  }

  confirm() {
    let updatedBroker = this.form.value
    this.dialogRef.close(updatedBroker)
  }

}
