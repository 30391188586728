import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private dataArraySource = new BehaviorSubject<any[]>([]);
  public dataArray$ = this.dataArraySource.asObservable();

  private filteredArraySource = new BehaviorSubject<any[]>([]);
  public fiteredLoads = this.filteredArraySource.asObservable();


  private dataArrayDriver = new BehaviorSubject<any[]>([]);
  public dataArray = this.dataArrayDriver.asObservable();

  private dataArrayCarrier = new BehaviorSubject<any[]>([]);
  public dataArrayCarrier$ = this.dataArrayCarrier.asObservable();

  private dataArrayDispatcher = new BehaviorSubject<any[]>([]);
  public dataArrayDispatcher$ = this.dataArrayDispatcher.asObservable();

  private filteredDrivers = new BehaviorSubject<any[]>([]);
  public filteredDriver = this.filteredDrivers.asObservable();

  private partialLoad = new BehaviorSubject<any>('');
  public partialLoad$ = this.partialLoad.asObservable();

  setLoad(newData: any[]) {
    this.dataArraySource.next([...newData]);
  }

  setDriver(newData: any[]) {
    this.dataArrayDriver.next([...newData])
  }

  setCarrier(carriers: any[]) {
    this.dataArrayCarrier.next([...carriers])
  }

  setDispatcher(disp: any[]) {
    this.dataArrayDispatcher.next([...disp])
  }

  setFilteredLoad(filteredData: any[]) {
    if (filteredData) {
      this.filteredArraySource.next([...filteredData]);
    }
  }

  setFilterDriver(filterData: any[]) {
    this.filteredDrivers.next([...filterData])
  }

  setPartialLoad(load: any[]) {
    this.partialLoad.next(load)
  }

  getDriver() {
    return this.dataArrayDriver.value
  }

  getLoad() {
    return this.dataArraySource.value;
  }

}
