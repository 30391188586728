

<div class="edituser-popup-wrapper">
    <div class="edituser-popup-header">
        <div class="popup-header-container">
            <div class="header-icon">
                <img src="assets/svg/flag-icon.svg" alt="users-plus">
            </div>
            <div class="popup-header-title">
                <div class="header-title"> {{data.tempCreateDriver || data.createUser ? 'Create' : 'Edit' }} {{data.roles.join()}}</div>
                <div class="header-descr">Lorem Ipsum is simply dummy text of the printing.</div>
            </div>
            <div *ngIf="!data.tempCreateDriver && !data.createUser" class="right-btn" (click)="deactivateClick()">
                <span>Deactivate</span>
            </div>
        </div>
    </div>
    
    @switch (this.data?.roles.join()) {
        @case ('Carrier') {
            <app-edit-carrier-form [row]="data" (formValid)="handleFormValidity($event)">
                <ng-template #attachmentTemplate let-atach>
                    <app-file-upload [files]="data.files" (filesChanged)="handleFileChange($event)"></app-file-upload>
                  </ng-template>                  
            </app-edit-carrier-form>
        }
        @case ('Driver') {
            <app-edit-driver-form [row]="data" (formValid)="handleFormValidity($event)" (addCoDriver)="addCoDriverClick($event)" (editCodriver)="editCodriverClick($event)">
                <ng-template #attachmentTemplate let-atach>
                    <app-file-upload [files]="data.files" (filesChanged)="handleFileChange($event)"></app-file-upload>
                  </ng-template>                  
            </app-edit-driver-form>
        }
        @default {
            <app-edit-user-form [row]="data" (formValid)="handleFormValidity($event)" [selectSuperVisor]="this.data.roles.join() === 'Dispatcher'">
                <ng-template #attachmentTemplate let-atach >
                    <app-file-upload [files]="data.files" (filesChanged)="handleFileChange($event)"></app-file-upload>
                </ng-template>                  
            </app-edit-user-form>
        }
    }

    <div class="popup-footer-btns">
        <div class="actions-btns">
            <button class="global-btn btn-cancel" (click)="closeBtnClick()">
                <span class="btn-text">Cancel</span>
            </button>
            <button appDebounceClick (debounceClick)="okBtnClick()" [disabled]="!formControl.isValid" [ngClass]="{ 'disabled-btn': !formControl.isValid }" class="global-btn btn-confirm">
                <span class="btn-text">Confirm</span>
            </button>
        </div>
    </div>
  </div>