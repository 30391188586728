<div class="target-popup-wrapper" [style.width]="data.totalbyData.targets.length > 0 ? '365px': '320px'">
    <div class="popup-container">
        <div class="popup-header-title">
            <div class="header-title">{{data.resource.driverFullName}}</div>
            <div class="header-descr">{{data.resource.carrierName}}</div>
        </div>

        <div class="popup-content-wrapper">
            <div class="label-value">
                <div class="label">Targets:</div>
                <div class="value">
                    @for (target of data.totalbyData.targets; track $index) {
                        <div class="main-badge" [ngClass]="getMainBadgeClass(target.targetType)">
                            <span class="target-value"> {{target.targetValue + ' ' + target.targetType}}</span>
                            <span class="target-interval-badge" [ngClass]="getTargetBadgeClass(target.targetIntervalType)">{{target.targetIntervalType}}</span>
                        </div>
                    }
                    @if(!isTargetSectionVisible && data.totalbyData.targets.length < 2) {
                        <span class="add-target" (click)="isTargetSectionVisible = true"> {{'+ Add Target'}}</span>
                    }
                    @if(data.totalbyData.targets.length !== 0) {
                        <button class="global-btn btn-delete" (click)="removeItem(i)">
                            <svg-icon src="../../../../../../../assets/svg/trash.svg" alt="plus"> </svg-icon>
                        </button>
                    }
                </div>
            </div>
            <div class="label-value">
                <div class="label">Working days:</div>
                <div class="value">{{data.totalbyData.workingDays}}</div>
            </div>
            <div class="label-value">
                <div class="label">Off days:</div>
                <div class="value">{{data.totalbyData.offDays}}</div>
            </div>
            <div class="label-value">
                <div class="label">Total DH:</div>
                <div class="value">{{data.totalbyData.totalDeadhead}}</div>
            </div>
            <div class="label-value">
                <div class="label">Total loaded:</div>
                <div class="value">{{data.totalbyData.totalMiles}}</div>
            </div>
            <div class="label-value">
                <div class="label">Gross:</div>
                <div class="value">{{data.totalbyData.gross}}</div>
            </div>
        </div>
    </div>
    @if(isTargetSectionVisible) {
        <div class="add-target-section">
            <form [formGroup]="targetForm">
                <div class="row-2">
                    <mat-form-field class="global-select-input">
                        @if(isTargetTypeLabelVisible()){
                            <mat-label>Target Type</mat-label>
                        }
                        <mat-select formControlName="targetType" >
                        @for (targetType of targetTypeArr; track $index) {
                            <mat-option style="font-size: 12px;" [value]="targetType.code">{{targetType.name}}</mat-option>
                        }
                        </mat-select>
                    </mat-form-field>

                    <div class="block-input-icon-with-err">
                        <input type="number" (input)="targetInput($event)" class="form-control" id="targetValue" formControlName="targetValue" placeholder="Target">
                        @if(targetForm.get("targetType")?.value === 'miles') {            
                            <span class="measure-unit">mi</span>
                        } @else {
                            <span class="measure-unit">$</span>
                        }
                    </div>
                </div>
                <div class="row-1">
                    <mat-form-field class="global-select-input">
                        @if(isTargetIntervalLabelVisible()){
                            <mat-label>Target Interval</mat-label>
                        }
                        <mat-select formControlName="targetIntervalType" >
                        @for (targetInterval of targetIntervalTypeArr; track $index) {
                            <mat-option style="font-size: 12px;" [value]="targetInterval.code">{{targetInterval.name}}</mat-option>
                        }
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </div>
    }
    
    <div class="popup-footer-btns">
        <div class="actions-btns">
            <button class="global-btn btn-cancel" (click)="closeBtnClick()">
                <span class="btn-text">Close</span>
            </button>
            @if(isTargetSectionVisible) {
                <button class="global-btn btn-confirm" [ngClass]="{ 'disabled-btn': !targetForm.valid }" [disabled]="!targetForm.valid" (click)="applyBtnClick()">
                    <span class="btn-text">Apply</span>
                </button>
            } @else {
                <button class="global-btn btn-confirm" (click)="okBtnClick()">
                    <span class="btn-text">Report</span>
                </button>
            }
        </div>
    </div>
</div>