import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from '../../../../../../services/dialog/dialog.service';

@Component({
  selector: 'app-driver-note-popup',
  templateUrl: './driver-note-popup.component.html',
  styleUrl: './driver-note-popup.component.scss'
})
export class DriverNotePopupComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DriverNotePopupComponent>,
  ) {

  }

  public textAreaVal: string = '';

  ngOnInit(): void {
    this.textAreaVal = this.data.note;
  }

  onValueChange(event: Event): void {
    const value = (event.target as any).value;
    this.textAreaVal = value;
  }
  
  closeBtnClick() {
    this.dialogService.closeDialog(this.dialogRef, undefined);
  }

  okBtnClick() {
    this.dialogService.closeDialog(this.dialogRef, {
      data: this.textAreaVal
    });
  }
}
